import { takeLatest, put, call } from "redux-saga/effects";

import {
  CLEAN_USER,
  LOGIN_PROFILE_USER,
  LOGIN_USER,
  RESET_USER,
} from "./actionTypes";

import {
  cleanStateActionFail,
  cleanStateActionSuccess,
  loginUserFail,
  loginUserProfileFail,
  loginUserProfileSuccess,
  loginUserSuccess,
  resetUserFail,
  resetUserSuccess,
} from "./actions";

import { AuthenticateReponse } from "../../core/auth/Type";

import {
  loginWithUsernameAndPassword,
  getProfileUser,
  resetUser,
  getToken,
} from "../../core/auth/AuthService";
import { ApiResponse } from "../../shared/types";
import {
  apiGet,
  genericPostRequest,
  genericRequest,
} from "../../shared/ApiService";
import { Endpoints } from "../../config/endpoints";
import { API_URL_BACKOFFICE } from "../../config/general-config";

function* onLoginUser(payload: any) {
  try {
    const { username, password } = payload;
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(loginWithUsernameAndPassword(username, password));
    });

    yield put(loginUserSuccess(response));
  } catch (error: any) {
    yield put(loginUserFail(error.message));
  }
}

function* onLoginProfileUser(payload: any) {
  try {
    const { username } = payload;
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(getProfileUser(username));
    });
    yield put(loginUserProfileSuccess(response));
  } catch (error: any) {
    yield put(loginUserProfileFail(error.message));
  }
}

function* onResetUser(payload: any) {
  try {
    const { username } = payload;
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(resetUser(username));
    });
    yield put(resetUserSuccess(response));
  } catch (error: any) {
    yield put(resetUserFail(error.message));
  }
}

function* onCleanUserState() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanStateActionSuccess());
  } catch (error: any) {
    yield put(cleanStateActionFail(error.response));
  }
}

function* UserSaga() {
  yield takeLatest(LOGIN_USER, onLoginUser);
  yield takeLatest(LOGIN_PROFILE_USER, onLoginProfileUser);
  yield takeLatest(RESET_USER, onResetUser);
  yield takeLatest(CLEAN_USER, onCleanUserState);
}

export default UserSaga;
