import * as types from "./actionTypes";

const initialState = {
  availableDevices: undefined,
  silobagList: undefined,
  establishments: undefined,
  harvests: undefined,
  lots: undefined,
  products: undefined,
  species: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const SiloBagReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_ALL_AVAILABLE_DEVICE_ACTION:
    case types.GET_ESTABLISHMENT:
    case types.GET_HARVEST:
    case types.GET_LOT:
    case types.GET_PRODUCTS:
    case types.GET_SILOBAG:
    case types.GET_SPECIES:
      state = { ...state, loading: true };
      break;

    case types.GET_ESTABLISHMENT_SUCCESS:
      state = { ...state, establishments: actionPayload, loading: false };
      break;

    case types.GET_HARVEST_SUCCESS:
      state = { ...state, harvests: actionPayload, loading: false };
      break;

    case types.GET_LOT_SUCCESS:
      state = { ...state, lots: actionPayload, loading: false };
      break;

    case types.GET_PRODUCTS_SUCCESS:
      state = { ...state, products: actionPayload, loading: false };
      break;

    case types.GET_SPECIES_SUCCESS:
      state = { ...state, species: actionPayload, loading: false };
      break;

    case types.GET_SILOBAG_SUCCESS:
      state = { ...state, silobagList: actionPayload, loading: false };
      break;

    case types.GET_ALL_AVAILABLE_DEVICE_SUCCESS:
      state = { ...state, availableDevices: actionPayload, loading: false };
      break;

    case types.GET_ALL_AVAILABLE_DEVICE_FAIL:
    case types.GET_ESTABLISHMENT_FAIL:
    case types.GET_HARVEST_FAIL:
    case types.GET_LOT_FAIL:
    case types.GET_PRODUCTS_FAIL:
    case types.GET_SPECIES_FAIL:
    case types.GET_SILOBAG_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default SiloBagReducer;
