export enum Paths {
  FORGOTPASS = "/forgotpassword",
  LOGIN = "/login",
  LOGOUT = "/logout",
  ESTABLISHMENTS_MAPS = "/establishments",
  NOTFOUND = "/notfound",
  RESET = "/reset",
  ROOT = "/",
  SILO = "/silo",
  VIEW_SILO = "/ver_silo",
  NEW_SILO = "/nuevo_silo",
  ADMINISTRATOR = "/admin",
}
