import * as types from "./actionTypes";

export const setOrganizationAction = (org: any) => {
  return {
    type: types.SET_ORGANIZATION,
    org,
  };
};

export const setOrganizationFail = (error: any) => {
  return {
    type: types.SET_ORGANIZATION_FAIL,
    payload: error,
  };
};

export const setOrganizationSuccess = (org: any) => {
  return {
    type: types.SET_ORGANIZATION_SUCCESS,
    payload: org,
  };
};
