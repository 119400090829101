import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row, Stack, Tooltip } from "react-bootstrap";
import { CardComponent } from "../../components/card";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import BackArrowIcon from "../../assets/icons/svg/SiloBagPage/backBlueArrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  SiloBagDeviceComponent,
  SiloBagMetricComponent,
  SiloBagViewComponent,
} from "../../components/silobag";
import MainFooterComponent from "../../components/base/footer/MainFooterComponent";
import {
  stateSiloBagColorBackground,
  stateSiloBagColorText,
  stateSiloBagName,
} from "../../shared/helper";

const ViewSiloBagPage = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [showToast, setShowToast] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);

  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { editionMode, from, record, viewMode } = state;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleSubmit = () => {
    setSubmitTrigger(true);
  };

  return (
    <AuthenticatedRoute
      withHeader
      withPadding={false}
      showToast={showToast}
      setShowToast={setShowToast}
      errorType={errorType}
      setErrorType={setErrorType}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      path={Paths.VIEW_SILO}
    >
      <section className="screen-container">
        <div className="screen-container__layout">
          <div className="screen-container__layout-header">
            <div className="mb-4 mb-xl-0">
              <Row className="justify-content-between d-flex">
                <Col className="d-flex">
                  <Link
                    to={from ? from : Paths.ROOT}
                    className={"link-decorator-none"}
                  >
                    <Image className={"align-middle"} src={BackArrowIcon} />
                    <span
                      className={"silo-bag-back fs-10 light-blue fw-bold-500"}
                    >
                      Volver
                    </span>
                  </Link>
                </Col>
              </Row>
              <span className={"silo-bag-title fw-bold-500"}>
                {record && record.nombre}{" "}
                <span className=" silo-bag-state-subtitle light-gray fw-bold-400">
                  estado carga{" "}
                  <span className="silo-bag-state-subtitle-form-view light-gray fw-bold-400">
                    <div
                      className={`h-100 badge silobag-badge rounded-pill .w-500 ${
                        stateSiloBagColorText &&
                        stateSiloBagColorText[record.tipoEstadoDatosId]
                      } ${
                        stateSiloBagColorBackground &&
                        stateSiloBagColorBackground[record.tipoEstadoDatosId]
                      }`}
                    >
                      {stateSiloBagName &&
                        stateSiloBagName[record.tipoEstadoDatosId]}
                    </div>
                  </span>
                </span>
              </span>
            </div>

            <Col xs={width <= 768 ? 12 : "auto"}>
              <Stack
                direction={width <= 768 ? "vertical" : "horizontal"}
                className={width <= 768 ? "justify-content-between" : ""}
              >
                <div
                  className="me-2"
                  style={width <= 768 ? { width: "65%" } : {}}
                >
                  <Button
                    className="dashboardButtonDefaultInvert text-nowrap toooltip"
                    style={width <= 768 ? { width: "100%" } : { width: 189 }}
                    onClick={() => {}}
                  >
                    Generar reporte
                  </Button>
                </div>
                <div
                  className="me-2"
                  style={width <= 768 ? { width: "65%" } : {}}
                >
                  <Button
                    className="dashboardButtonDefaultInvert text-nowrap toooltip"
                    style={width <= 768 ? { width: "100%" } : { width: 189 }}
                    onClick={() => {}}
                  >
                    Emitir informe
                  </Button>
                </div>
                <div
                  className="me-2"
                  style={width <= 768 ? { width: "65%" } : {}}
                >
                  <Button
                    className="dashboardButtonInvert text-nowrap toooltip"
                    style={width <= 768 ? { width: "100%" } : { width: 189 }}
                    onClick={() => {}}
                  >
                    Extraer silobolsa
                  </Button>
                </div>
              </Stack>
            </Col>
          </div>

          <Col md={12}>
            <SiloBagViewComponent record={record} />
          </Col>

          <Col md={12}>
            <SiloBagDeviceComponent
              devices={record && record.silos_dispositivos}
            />
          </Col>

          <Col md={12}>
            <SiloBagMetricComponent devices={record} />
          </Col>
        </div>
        <MainFooterComponent />
      </section>
    </AuthenticatedRoute>
  );
};

export default ViewSiloBagPage;
