import { parseISO } from "date-fns";
import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { getToken } from "../../core/auth/AuthService";
import { genericPostRequest } from "../../shared/ApiService";
import { Alert, ApiResponse } from "../../shared/types";
import { getAlertsDataFail, getAlertsDataSuccess } from "./actions";
import { GET_ALERTS_DATA } from "./actionTypes";


function* onGetAlertsData(action: any) {
  try {
    const { user_id, org_id } = action.payload;
    const bodyContent = {
      user_id,
      org_id,
      type: "es",
    };
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericPostRequest(
          "v1/dashboard",
          Endpoints.ALERTS,
          bodyContent,
          7,
          getToken()
        )
      );
    });

    const alerts = response.data.alerts_data.map( (a: any) => {
      a.FECHA = parseISO(a.FECHA)
      return a as Alert;
    })

    yield put(getAlertsDataSuccess(alerts));
  } catch (error: any) {
    yield put(getAlertsDataFail(error.response));
  }
}

function* AlertsSaga() {
  yield takeLatest(GET_ALERTS_DATA, onGetAlertsData);
}

export default AlertsSaga;
