import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Image, Row, Tab, Tabs } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import { CardComponent } from "../../card";

import DownArrowIcon from "../../../assets/icons/svg/SiloBagPage/metric/downArrow.svg";
import ForwardArrowIcon from "../../../assets/icons/svg/SiloBagPage/metric/forwardArrow.svg";
import { CustomSelect } from "../../form/CustomSelect";
import { SiloBagChartComponent } from "../siloBagCharts";

function CustomToggle({ open, setOpen, children, eventKey }: any) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setOpen(!open);
  });

  return (
    <Row className="d-flex align-center">
      <Col xs={"auto"}>
        <span className="fw-bold-500 fs-4">{"Métricas"}</span>
        <span
          style={{ marginLeft: "2vh", cursor: "pointer" }}
          onClick={decoratedOnClick}
        >
          <span className="accordion-title fw-bold-400 fs-5">
            {open ? "Cerrar" : "Abrir"}{" "}
            <Image src={open ? ForwardArrowIcon : DownArrowIcon} />
          </span>
        </span>
      </Col>
    </Row>
  );
}

const SiloBagMetricComponent = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [periodo, setPeriodo] = useState<any>();
  const { record } = props;

  const onChange = (e: any) => {
    setPeriodo(e.value);
  };

  return (
    <CardComponent dashboard minHeight={"auto"} marginTop={""}>
      <Row className="justify-content-between align-center">
        <Col>
          <Accordion>
            <CustomToggle open={open} setOpen={setOpen} eventKey="0" />
            <Accordion.Collapse eventKey="0">
              <>
                <Row className="d-flex justify-content-center align-center ">
                  <Col xs={"auto"}>
                    <CustomSelect
                      id="periodo"
                      name="periodo"
                      placeholder="Periodo"
                      onChange={(e) =>
                        onChange({ name: e.name, value: e.value.target.value })
                      }
                      style={{ width: 167 }}
                      defaultValue={periodo}
                      values={[
                        { nombre: "1 día" },
                        { nombre: "3 día" },
                        { nombre: "7 día" },
                        { nombre: "15 día" },
                        { nombre: "30 día" },
                      ]}
                    />
                  </Col>
                  <Col xs={"auto"}>
                    <div className="mt-4 fw-bold-500 fs-5">
                      Usted está viendo{" "}
                      <span className={"fw-bold-600"}>366</span> datos
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex justify-content-center align-center ">
                  <Col xs={12}>
                    <Tabs
                      defaultActiveKey="profile"
                      id="justify-tab-example"
                      className="mb-3 mt-4"
                    >
                      <Tab
                        className={"silobag-tab-metric"}
                        eventKey="home"
                        title="Dióxido de Carbono"
                      >
                        <Row className="d-flex justify-content-center align-center ">
                          <Col xs={12}>
                            <SiloBagChartComponent
                              title={"Dióxido de Carbono"}
                            />
                          </Col>
                        </Row>
                      </Tab>
                      <Tab
                        className={"silobag-tab-metric"}
                        eventKey="profile"
                        title="Humedad"
                      >
                        <Row className="d-flex justify-content-center align-center ">
                          <Col xs={12}>
                            <SiloBagChartComponent
                              title={"Humedad de Equilibrio"}
                            />
                          </Col>
                        </Row>
                      </Tab>
                      <Tab
                        className={"silobag-tab-metric"}
                        eventKey="longer-tab"
                        title="Batería"
                      >
                        <Row className="d-flex justify-content-center align-center ">
                          <Col xs={12}>
                            <SiloBagChartComponent title={"Batería"} />
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </>
            </Accordion.Collapse>
          </Accordion>
        </Col>
      </Row>
    </CardComponent>
  );
};

export default SiloBagMetricComponent;
