import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  MonitoredTonsCrop,
  MonitoredTonsEstablishment,
} from "../../../shared/types";
import AlertIcon from "../../../assets/icons/svg/DashboardPage/ico-alarm-border.svg";
import SilobolsasIcon from "../../../assets/icons/svg/DashboardPage/ico-silo.svg";
import TonsIcon from "../../../assets/icons/svg/DashboardPage/ico-toneladas.svg";
import { MainCardElement } from "../mainCardElement";
import { MainCardTonsDetail } from "../mainCardTonsDetail";

interface MainCardComponentProps {
  mobile: boolean;
  silobags: number;
  monitoredTons: number;
  alerts: number;
  monitoredTonsCropData: MonitoredTonsCrop[];
  monitoredTonsEstablishmentData: MonitoredTonsEstablishment[];
}

const MainCardComponent = (props: MainCardComponentProps) => {
  const {
    silobags,
    monitoredTons,
    alerts,
    monitoredTonsCropData,
    monitoredTonsEstablishmentData,
    mobile,
  } = props;
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setShowDetail(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cardRef]);

  return (
    <div className="main-card-wrapper mt-4 mb-4 mb-md-0">
      <Card
        className="main-card"
        style={{ position: "absolute", width: "100%" }}
        ref={cardRef}
      >
        <Card.Body>
          <Row className="main-card-body justify-content-between ">
            <Col xs={4} md={4}>
              <MainCardElement
                mobile={mobile}
                title="Silobolsas activos"
                icon={SilobolsasIcon}
                count={silobags}
              />
            </Col>
            <Col xs={4} md={4}>
              <MainCardElement
                mobile={mobile}
                title="Toneladas monitoreadas"
                icon={TonsIcon}
                count={monitoredTons}
                style={{ cursor: "pointer" }}
                detail={true}
                onClick={() => setShowDetail(!showDetail)}
              />
            </Col>
            <Col xs={4} md={4}>
              <MainCardElement
                mobile={mobile}
                title="Alertas"
                icon={AlertIcon}
                count={alerts}
              />
            </Col>
          </Row>
          <Row>
            {showDetail && (
              <MainCardTonsDetail
                crops={monitoredTonsCropData}
                establishments={monitoredTonsEstablishmentData}
              />
            )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MainCardComponent;
