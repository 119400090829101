import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  alphanumericRegex,
  customDNI,
  dniRegex,
  emailRegex,
  numericRegex,
} from "../../../shared/regexPatterns";
import { ModalFormProps, ReduxType } from "../../../shared/types";
import {
  getAdminDataAction,
  getAllowedEstablishmentAction,
  getRolesListAction,
} from "../../../store/administrator/actions";
import { CustomSelect } from "../../form/CustomSelect";
import { CustomTextField } from "../../form/CustomTextField";
import {
  defaultColumnsForUserPermissions,
  viewColumnsForUserEstablishment,
  viewColumnsForUserPermissions,
} from "../constants/Constants";
import { CustomTable } from "../CustomTable";

export interface UserModalProps extends ModalFormProps {
  triggerUpdate: any;
  setTriggerUpdate: any;
  handlePermissionDelete: (id: number, action: any) => void;
  handleDisabledEstablishment: (
    userId: number,
    action: any,
    record: any
  ) => void;
}

export const UserModalForm = (props: UserModalProps) => {
  const {
    createMode,
    editionMode,
    onValueChange,
    valuesToEditOrView,
    onSubmit,
    formValues,
    triggerUpdate,
    setTriggerUpdate,
    handlePermissionDelete,
    handleDisabledEstablishment,
  } = props;

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { administratorData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (!administratorData.rolesList) {
      getAllRoleData();
    }
  }, []);

  useEffect(() => {
    getAllowedEstablishment();
  }, []);

  useEffect(() => {
    if (triggerUpdate) {
      getAllRoleData();
      getAllowedEstablishment();
      setTriggerUpdate(false);
    }
  }, [triggerUpdate]);

  const getAllowedEstablishment = async () => {
    await dispatch(getAllowedEstablishmentAction(valuesToEditOrView.id));
  };

  const getAllRoleData = async () => {
    await dispatch(getRolesListAction());
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit();
    }
    setValidated(true);
  };

  const generateColumnsForEstablishment = () => {
    let col: any = [
      ...viewColumnsForUserEstablishment,
      {
        id: "estado",
        label: "Estado",
        render: (value: any, record: any) => {
          const state =
            record.usuario_permiso_negados &&
            record.usuario_permiso_negados[0] &&
            record.usuario_permiso_negados[0].establecimientoId;
          const background = state ? "background-red" : "background-green";
          const color = state ? "light-red" : "light-green";
          const text = state ? "Denegado" : "Habilitado";
          return (
            <>
              <div className="h-100 align-center">
                <div
                  className={`h-100 badge silobag-badge rounded-pill w-500 ${color} ${background}`}
                >
                  {text}
                </div>
              </div>
            </>
          );
        },
      },
    ];
    if (editionMode) {
      col = [...col, { id: "acciones", label: "Habilitado" }];
    }
    return col;
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="modal-form"
      id="modal-form"
    >
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="nombreApellido">
          <CustomTextField
            id="fullname"
            name="fullname"
            defaultValue={valuesToEditOrView.fullname}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            required
            placeholder="Nombre y apellido"
            pattern={alphanumericRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un nombre y apellido correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="email">
          <CustomTextField
            id="email"
            name="email"
            defaultValue={valuesToEditOrView.email}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            required
            placeholder="Email"
            pattern={emailRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un email correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="6" controlId="dni">
          <CustomTextField
            id="dni"
            name="dni"
            defaultValue={valuesToEditOrView.dni}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            placeholder="DNI"
            pattern={dniRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un DNI correcto.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm="6" controlId="telefono">
          <CustomTextField
            id="phone"
            name="phone"
            defaultValue={valuesToEditOrView.phone}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            placeholder="Teléfono"
            pattern={numericRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un número de Teléfono correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="rolId">
          <CustomSelect
            id="rolId"
            name="rolId"
            required
            placeholder="Rol"
            onChange={(e) => {
              if (e.value && typeof e.value === "number") {
                onValueChange({ ...e });
              } else {
                const finalValue =
                  typeof e.value.target.value === "string"
                    ? parseInt(e.value.target.value)
                    : e.value.target.value;
                onValueChange({ name: e.name, value: finalValue });
              }
            }}
            defaultValue={valuesToEditOrView.rolId || ""}
            disabled={!editionMode}
            values={administratorData.rolesList}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un Rol.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="activo">
          <Form.Check
            defaultChecked={valuesToEditOrView.active}
            disabled={!editionMode}
            type="checkbox"
            label="Activo"
            onChange={(e) =>
              onValueChange({ name: "active", value: e.target.checked })
            }
          />
        </Form.Group>
      </Row>
      {!createMode && (
        <>
          <Row className="mb-2">
            <Col sm="12">
              <CustomTable
                withoutScroll
                columns={
                  editionMode
                    ? defaultColumnsForUserPermissions
                    : viewColumnsForUserPermissions
                }
                values={
                  formValues &&
                  formValues.usuario_permisos &&
                  formValues.usuario_permisos.length > 0
                    ? !editionMode
                      ? formValues.usuario_permisos.filter((val: any) =>
                          !!val.deshabilitado ? false : true
                        )
                      : formValues.usuario_permisos
                    : []
                }
                actions={{
                  edit: false,
                  view: false,
                  delete: false,
                  ...(editionMode ? { toggle: { name: "deshabilitado" } } : {}),
                }}
                onActionClick={(action, record) =>
                  handlePermissionDelete(record.id, action)
                }
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm="12">
              <CustomTable
                withoutScroll
                columns={generateColumnsForEstablishment()}
                values={
                  administratorData &&
                  administratorData.allowedEstablishments &&
                  administratorData.allowedEstablishments.length > 0
                    ? administratorData.allowedEstablishments
                    : []
                }
                actions={{
                  edit: false,
                  view: false,
                  delete: false,
                  ...(editionMode
                    ? { toggle: { name: "usuario_permiso_negados" } }
                    : {}),
                }}
                onActionClick={(action: any, record: any) =>
                  handleDisabledEstablishment(
                    valuesToEditOrView.id,
                    action,
                    record
                  )
                }
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
