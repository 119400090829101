import React from "react";

interface PolygonProps extends google.maps.PolygonOptions {
  polygonValues: any;
}

const Polygon: React.FC<PolygonProps> = ({ polygonValues, ...options }) => {
  const [polygon, setPolygon] = React.useState<google.maps.Polygon>();

  React.useEffect(() => {
    if (!polygon && polygonValues) {
      setPolygon(generatePerimeter(polygonValues));
    }
    // remove marker from map on unmount
    return () => {
      if (polygon) {
        polygon.setMap(null);
      }
    };
  }, [polygon, polygonValues]);

  React.useEffect(() => {
    if (polygon) {
      polygon.setOptions(options);
    }
  }, [polygon, options]);

  React.useEffect(() => {
    if (options.map && polygon) {
      const map = options.map as google.maps.Map;
      ["click", "mouseover", "mouseout"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      map.setZoom(45);
      map.setCenter(getCenterFromPolygon());
    }
  }, [polygon]);

  const getCenterFromPolygon = () => {
    let bounds = new google.maps.LatLngBounds();

    let polygon;
    if (polygonValues && polygonValues.length > 0) {
      polygon = polygonValues[0];
      const coords = polygon.LOTE_PERIMETRO.split(";");
      if (coords && coords.length > 0) {
        polygon = coords
          .map((c: any) => {
            const aux = c.split(",");
            if (aux && aux.length == 2) {
              return new google.maps.LatLng({
                lat: parseFloat(aux[0]),
                lng: parseFloat(aux[1]),
              });
            }
          })
          .filter((e: any) => e);
      }
    }

    for (let i = 0; i < polygon.length; i++) {
      bounds.extend(new google.maps.LatLng(polygon[i]));
    }
    return bounds.getCenter();
  };

  const generatePerimeter = (polygonValues: any) => {
    let polygon;

    if (polygonValues && polygonValues.length > 0) {
      polygon = polygonValues[0];
      const coords = polygon.LOTE_PERIMETRO.split(";");
      if (coords && coords.length > 0) {
        polygon = coords
          .map((c: any) => {
            const aux = c.split(",");
            if (aux && aux.length == 2) {
              return { lat: parseFloat(aux[0]), lng: parseFloat(aux[1]) };
            }
          })
          .filter((e: any) => e);
      }
    }

    if (polygon && polygon.length > 0) {
      return new google.maps.Polygon({
        paths: [...polygon],
        strokeColor: "#6BAA00",
        strokeWeight: 2,
        fillColor: "rgba(107, 170, 0, 0.1)",
        fillOpacity: 1,
      });
    }

    return new google.maps.Polygon();
  };

  return null;
};

export default Polygon;
