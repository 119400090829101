import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { ReactElement, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Map from "../../components/map/Map";
import Marker from "../../components/map/MapMarker";
import { GOOGLE_MAPS_KEY } from "../../config/general-config";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import {
  Establishment,
  EstablishmentPerimeter,
  ReduxType,
} from "../../shared/types";
import {
  getEstablishmentsMapDataAction,
  getEstablishmentFromSiloByLoteIdAction,
  getLanzasByLoteIdAction,
  clearEstablishmentDataAction,
} from "../../store/establishmentsMap/actions";
import { getPointerIcon } from "../../components/map/mapDashboard/helper";

import { isEqual } from "lodash";
import InfoWindowTooltip from "../../components/map/infoWindowTooltip/InfoWindowTooltip";
import { CloseButton } from "../../components/map/mapControls";
import { MapDashboard } from "../../components/map/mapDashboard";
import SiloAlerts from "../../components/map/siloAlerts/SiloAlerts";
import Cluster from "../../components/map/Cluster";
import { useLocation } from "react-router-dom";
import { alertColor } from "../../shared/helper";
import Polygon from "../../components/map/Polygon";

const EstablishmentTitle = (props: any) => {
  const { establishment, lote } = props;
  return (
    <div className="establishment-title-container">
      <span>
        {establishment} - {lote}
      </span>
    </div>
  );
};

const defaultCenter = {
  lat: -34.39637314255177,
  lng: -58.59160350800118,
};

const ScreenLoading = (props: any) => {
  const { value } = props;
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        background: value ? "rgba(0, 0,0,0.67)" : "unset",
        zIndex: 3,
      }}
    >
      <Spinner
        animation="border"
        role="status"
        variant={value ? "light" : undefined}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) {
    return (
      <Spinner className="centered-spinner" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <></>;
};

const filterEstablishmentsMarkers = (establishments: Establishment[]) => {
  return establishments.filter(
    (establishment) =>
      establishment.PRIMER_SILO_LATITUD &&
      establishment.PRIMER_SILO_LONGITUD &&
      !isNaN(parseFloat(establishment.PRIMER_SILO_LATITUD)) &&
      !isNaN(parseFloat(establishment.PRIMER_SILO_LONGITUD))
  );
};

//Find perimeter
const filterPerimeter = (establishment: any[]) => {
  return establishment.filter((e) => e.LOTE_PERIMETRO);
};

const EstablishmentsMapPage = () => {
  const dispatch = useDispatch();
  const [selectedEstablishment, setSelectedEstablishment] = useState(-1);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isAlertsOpen, setIsAlertsOpen] = useState(false);
  const { orgData, establishmentsMapData, alertsData } = useSelector(
    (state: ReduxType) => {
      return state;
    }
  );

  const location = useLocation();

  React.useEffect(() => {
    if (orgData && orgData.currentOrganization) {
      onOrgChange();
      doMapRequest();
    }
  }, [location, orgData.currentOrganization]);

  const onOrgChange = async () => {
    setIsAlertsOpen(false);
    setIsDashboardOpen(false);
    setSelectedEstablishment(-1);
    await dispatch(clearEstablishmentDataAction());
  };

  const doMapRequest = async () => {
    await dispatch(
      getEstablishmentsMapDataAction({
        user_id: orgData.currentOrganization.user_id,
        org_id: orgData.currentOrganization.id,
        lote_id: "0",
      })
    );
  };

  const establishments = establishmentsMapData?.establishments;
  const currentEstablishments = establishmentsMapData?.establishment;
  const lanzas = establishmentsMapData?.lanzas;
  const mapInfo = establishmentsMapData?.infoWindow;
  const markers = establishments
    ? filterEstablishmentsMarkers(establishments)
    : [];

  const perimeterPolygon: any = currentEstablishments
    ? filterPerimeter(currentEstablishments)
    : [];

  let center;
  if (markers[0]) {
    center = {
      lat: parseFloat(markers[0].PRIMER_SILO_LATITUD),
      lng: parseFloat(markers[0].PRIMER_SILO_LONGITUD),
    };
  } else {
    center = defaultCenter;
  }

  const doEstablishmentRequest = async (campo_id: any) => {
    if (isDashboardOpen) return;
    await dispatch(
      getEstablishmentFromSiloByLoteIdAction({
        user_id: orgData.currentOrganization.user_id,
        org_id: campo_id,
        lote_id: "0",
      })
    );
  };

  const doSelectedEstablishmentLanzasRequest = async (lote_id: any) => {
    await dispatch(
      getLanzasByLoteIdAction({
        user_id: orgData.currentOrganization.user_id,
        lote_id: lote_id,
        org_id: "0",
      })
    );
  };

  useEffect(() => {
    if (!establishmentsMapData.loading && isDashboardOpen)
      doSelectedEstablishmentLanzasRequest(
        currentEstablishments.map((value) => value.ELEMENTO_ID).toString()
      );
  }, [isDashboardOpen]);

  useEffect(() => {
    if (
      !establishmentsMapData.loading &&
      currentEstablishments &&
      currentEstablishments.length > 0
    ) {
      setIsDashboardOpen(true);
      setSelectedEstablishment(0);
    } else if (isDashboardOpen) {
      setIsDashboardOpen(false);
    }
  }, [currentEstablishments]);

  const onClose = () => {
    if (isAlertsOpen) {
      setIsAlertsOpen(false);
    }
    setIsDashboardOpen(false);
    setSelectedEstablishment(0);
  };

  useEffect(() => {
    isAlertsOpen && setIsAlertsOpen(false);
  }, [selectedEstablishment]);

  const alertsCounter = (index: any) =>
    currentEstablishments &&
    currentEstablishments.length > 0 &&
    currentEstablishments[index] &&
    alertsData.alerts
      ? alertsData.alerts.filter(
          (value) => value.ID_SILO === currentEstablishments[index].ELEMENTO_ID
        ).length
      : 0;

  const convertSvgToDataImage = (
    elemento_producto: any,
    alerta_color: string
  ) => {
    const icon = getPointerIcon(elemento_producto);
    const svg = icon.replace(
      "{{ color }}",
      alertColor(alerta_color) || "#6BAA00"
    );
    return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg);
  };

  return (
    <AuthenticatedRoute
      withHeader
      withPadding={false}
      path={Paths.ESTABLISHMENTS_MAPS}
    >
      <div className="map-wrapper">
        <Wrapper apiKey={GOOGLE_MAPS_KEY} render={render} language="es">
          {establishmentsMapData.loading && (
            <ScreenLoading value={window.google} />
          )}
          {window.google && (
            <>
              {isAlertsOpen && !establishmentsMapData.loading && (
                <SiloAlerts
                  isAlertsOpen={isAlertsOpen}
                  setIsAlertsOpen={setIsAlertsOpen}
                  establishment={currentEstablishments[selectedEstablishment]}
                  alertsData={alertsData.alerts}
                />
              )}
              {isDashboardOpen && !establishmentsMapData.loading && (
                <>
                  <CloseButton onClick={onClose} />
                  <EstablishmentTitle
                    lote={
                      currentEstablishments[selectedEstablishment].LOTE_NOMBRE
                    }
                    establishment={
                      currentEstablishments[selectedEstablishment].CAMPO_NOMBRE
                    }
                  />
                </>
              )}
              <Map
                onClick={() => {
                  if (isAlertsOpen) {
                    setIsAlertsOpen(false);
                  }
                }}
                streetViewControl={false}
                mapTypeControl={false}
                center={center}
                disableDefaultUI
                isDashboardOpen={isDashboardOpen}
                isAlertsOpen={isAlertsOpen}
                zoom={6}
                style={{ flexGrow: "1", height: "100%" }}
                mapTypeId={google.maps.MapTypeId.HYBRID}
              >
                {isDashboardOpen && perimeterPolygon && (
                  <Polygon polygonValues={perimeterPolygon} />
                )}

                {isDashboardOpen &&
                  currentEstablishments.length > 0 &&
                  currentEstablishments.map((marker, i) => {
                    return (
                      <Marker
                        markerValues={marker}
                        tooltip={
                          mapInfo.find(
                            (value) => value.SILO_ID === marker.ELEMENTO_ID
                          ) &&
                          ReactDOMServer.renderToString(
                            <InfoWindowTooltip
                              marker={marker}
                              alertsCounter={alertsCounter(i)}
                              lanzas={lanzas.filter(
                                (value) => marker.ELEMENTO_ID === value.ID_SILO
                              )}
                              mapInfo={mapInfo.find(
                                (value) => value.SILO_ID === marker.ELEMENTO_ID
                              )}
                              key={`${marker.ELEMENTO_ID}-${marker.ORGANIZACION_ID}-${i}-tooltip`}
                            />
                          )
                        }
                        onClick={async () => setSelectedEstablishment(i)}
                        key={`${marker.ELEMENTO_ID}-${marker.ORGANIZACION_ID}-${i}`}
                        position={{
                          lat: parseFloat(marker.ELEMENTO_LATITUD),
                          lng: parseFloat(marker.ELEMENTO_LONGITUD),
                        }}
                        zIndex={
                          isEqual(
                            currentEstablishments[selectedEstablishment],
                            marker
                          )
                            ? 999
                            : i
                        }
                        icon={{
                          url: convertSvgToDataImage(
                            marker.ELEMENTO_PRODUCTO,
                            marker.ALERTA_COLOR
                          ),
                          scaledSize: isEqual(
                            currentEstablishments[selectedEstablishment],
                            marker
                          )
                            ? new google.maps.Size(73, 86)
                            : new google.maps.Size(53, 66),
                        }}
                      />
                    );
                  })}

                <Cluster
                  markers={markers}
                  onClick={doEstablishmentRequest}
                  isVisible={!isDashboardOpen}
                />

                {!establishmentsMapData.loading && isDashboardOpen && (
                  <MapDashboard
                    isDashboardOpen={isDashboardOpen}
                    marker={currentEstablishments}
                    setIsAlertsOpen={setIsAlertsOpen}
                    lanzas={lanzas.filter(
                      (value) =>
                        currentEstablishments[selectedEstablishment]
                          .ELEMENTO_ID === value.ID_SILO
                    )}
                    isAlertsOpen={isAlertsOpen}
                    alertsCounter={alertsCounter(selectedEstablishment)}
                    setSelectedEstablishment={setSelectedEstablishment}
                    markersLength={currentEstablishments.length}
                    selectedEstablishment={selectedEstablishment}
                    key="map-dashboard-key"
                  />
                )}
              </Map>
            </>
          )}
        </Wrapper>
      </div>
    </AuthenticatedRoute>
  );
};

export default EstablishmentsMapPage;
