import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const ToastNotification = (props: any) => {
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="bg-dark toast-container-div"
    >
      <ToastContainer position="top-end" className="p-3">
        {props.toastMessages &&
          props.toastMessages.length > 0 &&
          props.toastMessages.map((value: any) => (
            <Toast
              key={value.id}
              onClose={() => props.onClose && props.onClose(value.id)}
              autohide
              bg={value.type && value.type.toLowerCase()}
              delay={3000}
            >
              <Toast.Header>
                <strong className="me-auto">{value.title}</strong>
                {/* <small className="text-muted"></small> */}
              </Toast.Header>
              <Toast.Body>{value.message}</Toast.Body>
            </Toast>
          ))}
      </ToastContainer>
    </div>
  );
};
export default ToastNotification;
