import React, { useEffect, useState } from "react";

interface InputSwitchProps {
  initial?: any;
  onChange?: (value: boolean) => void;
}

export const InputSwitchComponent = (props: any) => {
  const [isToggled, setIsToggled] = useState(false);
  
  useEffect(() => {
    if (props.initial) {
      setIsToggled(props.initial);
    }
  }, []);

  const onChange = () => {
    setIsToggled(!isToggled);
    props.onChange && props.onChange(!isToggled);
  };

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isToggled} onChange={onChange} />
      <span className="switch" />
    </label>
  );
};
