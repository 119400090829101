import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  alphanumericRegex,
  alphanumericWithSpecialCharsRegex,
  cuitRegex,
  emailRegex,
  numericRegex,
} from "../../../shared/regexPatterns";
import { ModalFormProps, ReduxType } from "../../../shared/types";
import {
  getCitiesListDataAction,
  getLocalitiesListDataAction,
} from "../../../store/administrator/actions";
import { CustomSelect } from "../../form/CustomSelect";
import { CustomTextField } from "../../form/CustomTextField";

export const CompanyModalForm = (props: ModalFormProps) => {
  const {
    createMode,
    editionMode,
    onValueChange,
    valuesToEditOrView,
    onSubmit,
    formValues,
  } = props;

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const { administratorData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    getAllNewOrganizationData();
  }, []);

  const getAllNewOrganizationData = async () => {
    await dispatch(getCitiesListDataAction(null, false));
    await dispatch(getLocalitiesListDataAction());
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit();
    }
    setValidated(true);
  };

  const getOnChange = async (name: string, id: any) => {
    switch (name) {
      case "municipality_id":
        onValueChange({ name: "municipality_id", value: id });
        break;
      case "locality_id":
        onValueChange({ name: "locality_id", value: id });
        break;
      default:
        break;
    }
  };

  const onDomicilioChange = ({ value, name }: { value: any; name: string }) => {
    if (typeof value === "number") {
      getOnChange(name, value);
    } else {
      const finalValue =
        typeof value.target.value === "string"
          ? parseInt(value.target.value)
          : value.target.value;
      getOnChange(name, finalValue);
    }
  };

  return (
    <Form
      noValidate
      className="modal-form"
      validated={validated}
      onSubmit={handleSubmit}
      id="modal-form"
    >
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="tax_id">
          <CustomTextField
            id="tax_id"
            name="tax_id"
            defaultValue={valuesToEditOrView.tax_id}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            required
            placeholder="CUIT sin guiones ni espacios"
            pattern={cuitRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un cuit correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="name">
          <CustomTextField
            id="name"
            name="name"
            defaultValue={valuesToEditOrView.name}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            required
            placeholder="Nombre"
            pattern={alphanumericWithSpecialCharsRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un nombre correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="legal_name">
          <CustomTextField
            id="legal_name"
            name="legal_name"
            defaultValue={valuesToEditOrView.legal_name}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            required
            placeholder="Nombre Legal"
            pattern={alphanumericWithSpecialCharsRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un nombre Legal correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="6" controlId="phone">
          <CustomTextField
            id="phone"
            name="phone"
            defaultValue={valuesToEditOrView.phone}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            placeholder="Teléfono"
            pattern={numericRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un numero correcto.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm="6" controlId="email">
          <CustomTextField
            id="email"
            name="email"
            defaultValue={valuesToEditOrView.email}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            required
            placeholder="Email"
            pattern={emailRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un email correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="8" controlId="street_name">
          <CustomTextField
            id="street_name"
            name="street_name"
            defaultValue={valuesToEditOrView.street_name}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            required
            placeholder="Calle"
            pattern={alphanumericRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese una calle correcta.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm="4" controlId="street_number">
          <CustomTextField
            id="street_number"
            name="street_number"
            defaultValue={valuesToEditOrView.street_number}
            onChange={(e) =>
              onValueChange({ value: parseInt(e.value), name: e.name })
            }
            disabled={!editionMode}
            type="text"
            required
            placeholder="Número"
            pattern={alphanumericRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un numero correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="4" controlId="floor">
          <CustomTextField
            id="floor"
            name="floor"
            defaultValue={valuesToEditOrView.floor}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            placeholder="Piso"
            pattern={numericRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un piso correcto.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm="4" controlId="aparment">
          <CustomTextField
            id="aparment"
            name="aparment"
            defaultValue={valuesToEditOrView.aparment}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            placeholder="Departamento"
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un piso correcto.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm="4" controlId="zip_code">
          <CustomTextField
            id="zip_code"
            name="zip_code"
            defaultValue={valuesToEditOrView.zip_code}
            onChange={(e) =>
              onValueChange({ value: parseInt(e.value), name: e.name })
            }
            disabled={!editionMode}
            type="text"
            placeholder="Código postal"
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un Código postal correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-2">
        <Form.Group as={Col} sm={6} controlId="municipality_id">
          <CustomSelect
            id="municipality_id"
            name="municipality_id"
            required
            placeholder="Ciudad"
            onChange={onDomicilioChange}
            defaultValue={valuesToEditOrView.municipality_id || ""}
            disabled={!editionMode}
            values={administratorData.cities}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese una ciudad.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm={6} controlId="locality_id">
          <CustomSelect
            id="locality_id"
            name="locality_id"
            required
            placeholder="Localidad"
            onChange={onDomicilioChange}
            defaultValue={valuesToEditOrView.locality_id || ""}
            disabled={!editionMode}
            values={administratorData.localities.filter(
              (l) => l.municipality_id === formValues.municipality_id
            )}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese una localidad.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </Form>
  );
};
