import React, { useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import IOFLogo from "../../../assets/img/LoginPage/IOFLogo-color.svg";
import Location from "../../../assets/icons/svg/Footer/location.svg";
import Mail from "../../../assets/icons/svg/Footer/mail.svg";

const MainFooterComponent = (props: any) => {
  const links = [
    {
      name: "Política de privacidad",
      link: "https://app.iofcompany.com/privacy.html",
    },
    {
      name: "Términos y condiciones",
      link: "https://app.iofcompany.com/legal.html",
    },
    {
      name: "Contacto",
      link: "mailto:info@iofcompany.com",
    },
  ];
  return (
    <>
      <Row className="main-footer">
        <Col className="d-flex">
          <img src={IOFLogo} style={{ margin: "auto" }} />
        </Col>
        <Col className="d-flex">
          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 7,
            }}
          >
            <div className="content align-center">
              <img src={Location} />
              <p className="w-500">
                Migueletes 1231 Piso 2 do A CP 1426 - CABA - BS AS
              </p>
            </div>
            <div className="content align-center">
              <img src={Mail} />
              <p className="w-500">info@iofcompany.com</p>
            </div>
          </div>
        </Col>
        <Col xs="12" className="d-flex">
          <p style={{ margin: "auto" }}>2022 © IoF Company</p>
        </Col>
      </Row>
    </>
  );
};

export default MainFooterComponent;
