import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { InputTextProps } from "../../../shared/types";

const InputTextComponent = (props: InputTextProps) => {
  const { errorMessage, formName, placeholder, required, value, onChange } =
    props;

  return (
    <>
      <InputGroup hasValidation>
        <Form.Control
          required={required}
          type="text"
          placeholder={`${placeholder} ${required ? `*` : ``}`}
          defaultValue={value}
          onChange={(e) => onChange(formName, e.target.value)}
          className={"silo-bag-form-input"}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </>
  );
};

export default InputTextComponent;
