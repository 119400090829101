import React from "react";
import { CurrentEstablishment, Establishment } from "../../shared/types";

interface MarkerProps extends google.maps.MarkerOptions {
  markerValues: Establishment | CurrentEstablishment;
  tooltip?: string | Element | Text | null;
  onClick?: (...args: any) => Promise<void>;
}

const Marker: React.FC<MarkerProps> = ({
  markerValues,
  tooltip,
  onClick,
  ...options
}) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    if (!infoWindow && tooltip) {
      setInfoWindow(new google.maps.InfoWindow());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, infoWindow]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
    if (infoWindow) {
      infoWindow.setContent(tooltip);
    }
  }, [marker, infoWindow, options]);

  React.useEffect(() => {
    if (options.map && marker) {
      const map = options.map as google.maps.Map;
      ["click", "mouseover", "mouseout"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        marker.addListener("click", async () => {
          const currentZoom = map.getZoom();
          await onClick(markerValues.LOTE_ID);
          map.panTo(marker.getPosition() as google.maps.LatLng);
          if (currentZoom && currentZoom < 14) map.setZoom(14);
        });
      }
      if (infoWindow) {
        marker.addListener("mouseover", () => {
          infoWindow.open({ anchor: marker, map, shouldFocus: false });
        });
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });
      }
    }
  }, [marker, onClick]);

  return null;
};

export default Marker;
