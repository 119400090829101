import React, { useEffect, useState } from "react";
import { Modal, Toast, ToastContainer } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Endpoints } from "../../config/endpoints";
import {
  API_URL_BACKOFFICE,
  API_WOZALABS_URL,
} from "../../config/general-config";
import {
  apiDelete,
  apiPatch,
  apiPost,
  apiPut,
  genericRequest,
} from "../../shared/ApiService";
import { cloneWithoutReference, findIndex } from "../../shared/helper";
import { ReduxType } from "../../shared/types";
import { CompanyModalForm } from "../administrator/modalForms/companyModalForm";
import { EstablishmentModalForm } from "../administrator/modalForms/establishmentModalForm";
import { UserModalForm } from "../administrator/modalForms/userModalForm";
import { ToastNotification } from "../toast";

export interface ModalProps {
  title: string;
  show: boolean;
  editionMode: boolean;
  createMode: boolean;
  onEditClick: (values?: any) => void;
  valuesToEditOrView?: any;
  onClose?: () => void;
}

const validFormValues = (title: string, values: any) => {
  const { municipality_id, locality_id, role_id } = values;
  switch (title) {
    case "Establecimientos":
      if (!municipality_id || !locality_id) return true;
      return municipality_id === "" || locality_id === "";
    case "Compañías":
      if (!municipality_id || !locality_id) return true;
      return municipality_id === "" || locality_id === "";
    case "Usuarios":
      if (!role_id) return true;
      return role_id === "";
    default:
      return values;
  }
};

const formatBodyValues = (
  title: string,
  values: any,
  organization_id: any,
  localities?: any
) => {
  const { id, ...rest } = values;
  const {
    name,
    legal_name,
    tax_id,
    phone,
    email,
    street_name,
    street_number,
    floor,
    aparment,
    zip_code,
    municipality_id,
    locality_id,
    role_name,
    role_id,
    fullname,
    active,
    lat,
    lon,
    company_id,
  } = values;
  switch (title) {
    case "Lotes":
      return { ...rest, organization_id };
    case "Establecimientos":
      let country_l2_id = null;
      const index = findIndex(localities, "id", locality_id);
      if (index !== -1) {
        country_l2_id = localities[index].country_l2_id;
      }
      return {
        company_id,
        country_l2_id,
        lat,
        lon,
        name,
      };
    case "Compañías":
      return {
        name,
        legal_name,
        tax_id,
        phone,
        email,
        address: {
          street_name,
          street_number,
          floor,
          aparment,
          zip_code,
          municipality_id,
          locality_id,
        },
        organization_id,
      };
    case "Usuarios":
      return {
        role_name,
        phone,
        role_id,
        fullname,
        email,
        tax_id,
        active,
        organization_id,
        username: email,
      };
    default:
      return values;
  }
};

export const CustomModal = (props: ModalProps) => {
  const [formValues, setFormValues] = useState(props.valuesToEditOrView);
  const [toastMessages, setToastMessages] = useState<any[]>([]);
  const [toDelete, setToDelete] = useState<any[]>([]);
  const [toSubmit, setToSubmit] = useState<any[]>([]);
  const [toUpdate, setToUpdate] = useState<any[]>([]);
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>();

  const {
    title,
    onClose,
    show,
    createMode,
    editionMode,
    valuesToEditOrView,
    onEditClick,
  } = props;

  const { administratorData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (Object.keys(valuesToEditOrView).length > 0) {
      setFormValues(valuesToEditOrView);
    }
  }, [editionMode, createMode, show]);

  const getTitle = () => {
    switch (title) {
      case "Establecimientos":
        if (createMode) return "Nuevo Establecimiento";
        if (editionMode) return "Editar Establecimiento";
        return "Establecimiento";
      case "Compañías":
        if (createMode) return "Nueva Compañía";
        if (editionMode) return "Editar Compañía";
        return "Compañía";
      case "Usuarios":
        if (createMode) return "Nuevo Usuario";
        if (editionMode) return "Editar Usuario";
        return "Usuario";
      default:
        return title;
    }
  };

  const handleModalSubmit = async () => {
    try {
      const localValues = { ...formValues };
      if (validFormValues(title, localValues))
        return setToastMessages([
          ...toastMessages,
          {
            id: Date.now(),
            title: "ERROR",
            message:
              "Error al validar los datos. Verifique los datos nuevamente.",
            type: "Danger",
          },
        ]);
      let request;
      if (createMode) {
        request = await apiPost({
          body: formatBodyValues(
            title,
            localValues,
            1,
            administratorData.localities
          ),
          url: getModalRequest(),
        });
      } else {
        request = await apiPatch({
          body: formatBodyValues(
            title,
            localValues,
            1,
            administratorData.localities
          ),
          url: getModalRequest(localValues.id),
        });
      }
      if (request) {
        setToastMessages([
          ...toastMessages,
          {
            id: Date.now(),
            title: "Exito",
            message: "Guardado exitoso.",
            type: "Success",
          },
        ]);
        title === "Establecimientos"
          ? await handleLotsSubmit(
              createMode
                ? request && request[0] && request[0].id
                : localValues.id
            )
          : onClose && onClose();
      }
    } catch (error: any) {
      setToastMessages([
        ...toastMessages,
        {
          id: Date.now(),
          title: "ERROR",
          message: error.message,
          type: "Danger",
        },
      ]);
    }
  };

  const handlePermissionDelete = async (id: number, action: any) => {
    try {
      if (!id) return;

      const fv = cloneWithoutReference(formValues.usuario_permisos);
      const index = findIndex(fv, "id", id);
      if (index !== -1) {
        fv[index].deshabilitado = action ? 0 : 1;
        setFormValues({ ...formValues, usuario_permisos: fv });
      }
      const request = await apiPut({
        body: {
          deshabilitado: action ? 0 : 1,
        },
        url: `${API_URL_BACKOFFICE}/${Endpoints.PUT_PERMISSIONS}/${id}`,
      });
      request &&
        setToastMessages([
          ...toastMessages,
          {
            id: Date.now(),
            title: "Exito",
            message: "Permiso deshabilitado exitosamente.",
            type: "Success",
          },
        ]);
    } catch (error: any) {
      setToastMessages([
        ...toastMessages,
        {
          id: Date.now(),
          title: "ERROR",
          message: error.message,
          type: "Danger",
        },
      ]);
    }
  };

  const handleDisabledEstablishment = async (
    userId: number,
    action: any,
    record: any
  ) => {
    try {
      if (!record.id) return;

      let body: {
        establishments_to_disable: any;
        establishments_to_enable: any;
      } = {
        establishments_to_disable: [],
        establishments_to_enable: [],
      };

      if (action) {
        body.establishments_to_enable.push(record.id);
      } else {
        body.establishments_to_disable.push(record.id);
      }

      const response = await apiPut({
        body: body,
        url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.ALLOWED_ESTABLISHMENT.UPDATE}/${userId}`,
      });

      if (response) {
        setTriggerUpdate(true);
        setToastMessages([
          ...toastMessages,
          {
            id: Date.now(),
            title: "Exito",
            message: `Establecimiento ${
              action ? `habilitado` : `deshabilitado`
            } exitosamente.`,
            type: "Success",
          },
        ]);
      }
    } catch (error: any) {
      setToastMessages([
        ...toastMessages,
        {
          id: Date.now(),
          title: "ERROR",
          message: error.message,
          type: "Danger",
        },
      ]);
    }
  };

  const onValueChange = (e: any, replaceWholeValue?: boolean) => {
    if (replaceWholeValue) {
      setFormValues(e);
    } else {
      setFormValues({ ...formValues, [e.name]: e.value });
    }
  };

  const getModalRequest = (id?: string) => {
    let url = `${API_WOZALABS_URL}`;
    switch (title) {
      case "Establecimientos":
        url = id
          ? `${url}/${Endpoints.WOZALABS.COUNTRYSIDES.UPDATE}${id}`
          : `${url}/${Endpoints.WOZALABS.COUNTRYSIDES.CREATE}`;
        break;
      case "Compañías":
        url = id
          ? `${url}/${Endpoints.WOZALABS.COMPANIES.UPDATE}${id}`
          : `${url}/${Endpoints.WOZALABS.COMPANIES.CREATE}`;
        break;
      case "Usuarios":
        url = id
          ? `${url}/${Endpoints.WOZALABS.USERS.UPDATE}${id}`
          : `${url}/${Endpoints.WOZALABS.USERS.CREATE}`;
        break;
    }
    return url;
  };

  const handleLotsChange = (
    action: "DELETE" | "ADD" | "UPDATE",
    values: any,
    initialValues: any
  ) => {
    if (action === "UPDATE") {
      if (!createMode && initialValues) {
        const { id, value, name } = values;
        const index = toUpdate.findIndex((val) => val.id === values.id);
        const clone = [...toUpdate];
        if (index > -1) {
          clone[index] = { id, nombre: name, poligono: value };
          setToUpdate(clone);
          return;
        }
        setToUpdate([...toUpdate, { id, nombre: name, poligono: value }]);
      } else {
        const { id, value, name } = values;
        const modifySubmit = [...toSubmit].map((val) => {
          if (val.id === id) return { ...val, [name]: value };
          return val;
        });
        setToSubmit(modifySubmit);
      }
    } else if (action === "DELETE") {
      const filterSubmit = toSubmit.filter((val) => val.id !== values);
      if (!createMode && initialValues) {
        setToDelete([...toDelete, { loteId: values }]);
        const filterUpdate = toUpdate.filter((val) => val.id !== values);
        setToUpdate(filterUpdate);
      }
      setToSubmit(filterSubmit);
    } else {
      setToSubmit([...toSubmit, { ...values }]);
    }
  };

  const addLots = async (countryside_id: number) => {
    if (toSubmit.length === 0) return;
    const body = toSubmit.map((val) => ({
      name: val.name,
      geom: null,
      countryside_id: countryside_id,
    }));

    await apiPost({
      body,
      url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.LOTS.CREATE}`,
    });
  };

  const deleteLots = async (countryside_id: number) => {
    if (toDelete.length === 0) return;
    const body = [...toDelete.map((val) => ({ ...val.id }))];
    await apiDelete({
      url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.LOTS.DELETE}(${body})`,
    });
  };

  const updateLots = async (countryside_id: number) => {
    if (toUpdate.length === 0) return;
    toUpdate.map(async (val) => {
      let body = {
        ...val,
        countryside_id: countryside_id,
      };
      await apiPatch({
        body,
        url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.LOTS.UPDATE}${body.id}`,
      });
    });
  };

  const handleLotsSubmit = async (establishmentId: number) => {
    try {
      await addLots(establishmentId);
      await deleteLots(establishmentId);
      await updateLots(establishmentId);

      setToastMessages([
        ...toastMessages,
        {
          id: Date.now(),
          title: "Exito",
          message: "Lotes guardados exitosamente.",
          type: "Success",
        },
      ]);
      setTimeout(() => {
        onClose && onClose();
      }, 500);
    } catch (error: any) {
      setToastMessages([
        ...toastMessages,
        {
          id: Date.now(),
          title: "ERROR",
          message: error.message,
          type: "Danger",
        },
      ]);
    }
  };

  const getModal = () => {
    switch (title) {
      case "Establecimientos":
        return (
          <EstablishmentModalForm
            editionMode={editionMode}
            createMode={createMode}
            valuesToEditOrView={valuesToEditOrView}
            onValueChange={onValueChange}
            formValues={formValues}
            handleLotsChange={handleLotsChange}
            onSubmit={handleModalSubmit}
          />
        );
      case "Compañías":
        return (
          <CompanyModalForm
            editionMode={editionMode}
            createMode={createMode}
            valuesToEditOrView={valuesToEditOrView}
            onValueChange={onValueChange}
            formValues={formValues}
            onSubmit={handleModalSubmit}
          />
        );
      case "Usuarios":
        return (
          <UserModalForm
            editionMode={editionMode}
            createMode={createMode}
            valuesToEditOrView={valuesToEditOrView}
            onValueChange={onValueChange}
            formValues={formValues}
            onSubmit={handleModalSubmit}
            triggerUpdate={triggerUpdate}
            setTriggerUpdate={setTriggerUpdate}
            handlePermissionDelete={handlePermissionDelete}
            handleDisabledEstablishment={handleDisabledEstablishment}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <ToastNotification
        toastMessages={toastMessages}
        onClose={(id: any) =>
          setToastMessages([...toastMessages].filter((val) => val.id !== id))
        }
      />
      <Modal
        onClose={onClose}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ overflow: "auto", maxHeight: 750 }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {getTitle()}
          </Modal.Title>
          <div style={{ display: "inline-flex", gap: 10 }}>
            <button className="clean-button" onClick={onClose}>
              Cancelar
            </button>
            <button
              className="custom-button"
              onClick={
                createMode || editionMode
                  ? () => {}
                  : (e) => {
                      e.preventDefault();
                      onEditClick();
                    }
              }
              form="modal-form"
              type="submit"
            >
              {createMode || editionMode ? "Guardar" : "Editar"}
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>{getModal()}</Modal.Body>
      </Modal>
    </>
  );
};
