import React, { useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";

const FooterComponent = (props: any) => {
  const { width } = props;
  const [autoSize, setAutoSize] = useState<boolean>(false);

  useEffect(() => {
    setAutoSize(width <= 768);
  }, [width]);

  const links = [
    {
      name: "Política de privacidad",
      link: "https://app.iofcompany.com/privacy.html",
    },
    {
      name: "Términos y condiciones",
      link: "https://app.iofcompany.com/legal.html",
    },
    {
      name: "Contacto",
      link: "mailto:info@iofcompany.com",
    },
  ];
  return (
    <>
      <Row className="footer d-flex justify-content-between">
        <Col xs="auto" className={"footerLinks"}>
          <span>©2022</span>
        </Col>
        {autoSize ? (
          <>
            {links.map((l) => {
              return (
                <Col xs="auto" className={"footerLinks"}>
                  <Nav.Link className={"footerCustomLink"} href={l.link}>
                    {l.name}
                  </Nav.Link>
                </Col>
              );
            })}
          </>
        ) : (
          <Col xs="auto">
            <Row>
              {links.map((l) => {
                return (
                  <Col xs="auto">
                    <Nav.Link className={"footerCustomLink"} href={l.link}>
                      {l.name}
                    </Nav.Link>
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default FooterComponent;
