import * as types from "./actionTypes";

const initialState = {
  establishments: [],
  establishment: [],
  infoWindow: [],
  lanzas: [],
  loading: false,
  error: {
    message: "",
  },
};

const EstablishmentsMapReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.CLEAR_DATA:
      state = initialState;
      break;
    case types.GET_ESTABLISHMENTS_MAP_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;

    case types.GET_ESTABLISHMENTS_MAP_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        establishments: actionPayload.establishments_map_data,
      };
      break;

    case types.GET_ESTABLISHMENTS_MAP_DATA_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;
    case types.GET_ESTABLISHMENT_FROM_SILO_BY_LOTE_ID:
      state = { ...state, loading: true };
      break;
    case types.GET_ESTABLISHMENT_FROM_SILO_BY_LOTE_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        establishment: actionPayload.establishment_map_data,
        infoWindow: actionPayload.infoWindow_map_data,
      };
      break;
    case types.GET_ESTABLISHMENT_FROM_SILO_BY_LOTE_ID_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_LANZAS_BY_LOTE_ID:
      state = { ...state, loading: true };
      break;
    case types.GET_LANZAS_BY_LOTE_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        lanzas: actionPayload.lanzas_map_data,
      };
      break;
    case types.GET_LANZAS_BY_LOTE_ID_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
  }

  return state;
};

export default EstablishmentsMapReducer;
