import React from "react";
import { Establishment } from "../../shared/types";
import Point from "../../assets/icons/svg/EstablishmentsMapPage/point.svg";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

interface ClusterProps {
  markers: Establishment[];
  isVisible: boolean;
  map?: google.maps.Map;
  onClick?: (...args: any) => Promise<void>;
}

const Cluster: React.FC<ClusterProps> = ({
  markers,
  onClick,
  map,
  isVisible,
}) => {
  const [clusters, setClusters] = React.useState<MarkerClusterer>();
  const [markersForCluster, setMarkersForCluster] =
    React.useState<google.maps.Marker[]>();

  React.useEffect(() => {
    if (!clusters) {
      const newMarkers = markers.map((value, i) => {
        const marker = new google.maps.Marker({
          map,
          position: {
            lat: parseFloat(value.PRIMER_SILO_LATITUD),
            lng: parseFloat(value.PRIMER_SILO_LONGITUD),
          },
          visible: isVisible,
          icon: Point,
          zIndex: i,
          label: {
            className: "map-marker",
            color: "#33333",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "600",
            fontSize: "1.2rem",
            text: "" + value.CANTIDAD,
          },
        });

        marker.addListener("click", async () => {
          const currentZoom = map?.getZoom();
          onClick && (await onClick(value.CAMPO_ID));
          map?.panTo(marker.getPosition() as google.maps.LatLng);
          if (currentZoom && currentZoom < 12) map?.setZoom(12);
        });

        return marker;
      });
      if (newMarkers.length === 0) return;
      setMarkersForCluster(newMarkers);
      setClusters(
        new MarkerClusterer({
          markers: newMarkers,
          map,
          renderer: {
            render: ({ count, position, markers }) => {
              const finalCount =
                markers && markers.length > 0
                  ? [...markers]
                      .map((val) => {
                        return val.getLabel()?.text;
                      })
                      .reduce(
                        (partialSum: any, a: any) => partialSum + parseInt(a),
                        0
                      )
                  : 0;
              return new google.maps.Marker({
                label: {
                  text: String(finalCount),
                  className: "map-marker",
                  color: "#33333",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                },
                position,
                visible: isVisible,
                map,
                icon: Point,
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              });
            },
          },
        })
      );
    }

    return () => {
      if (clusters) {
        markersForCluster?.map((value) => value.setMap(null));
        clusters.clearMarkers();
        setClusters(undefined);
      }
    };
  }, [clusters, markers, isVisible]);

  React.useEffect(() => {
    if (map && markers && markersForCluster) {
      google.maps.event.clearListeners(map, "onClick");

      markersForCluster.forEach((value, i) => {
        value.setVisible(isVisible);
        if (onClick) {
          value.addListener("click", async () => {
            const currentZoom = map.getZoom();
            await onClick(markers[i].CAMPO_ID);
            map.panTo(value.getPosition() as google.maps.LatLng);
            if (currentZoom && currentZoom < 12) map.setZoom(12);
          });
        }
      });
    }
  }, [clusters, onClick, isVisible]);

  return null;
};

export default Cluster;
