import React, { useEffect, useState } from "react";
import { Badge, Image, ListGroup, Stack } from "react-bootstrap";
import ForwardArrow from "../../../assets/icons/svg/DashboardPage/chevron-right.svg";
import { alertColor } from "../../../shared/helper";
import { DashboardSiloStatsProps } from "../../../shared/types";
import { CardComponent } from "../../card";
import CropIconComponent from "../cropIcon/CropIconComponent";

const SilobagCardComponent = (props: any) => {
  const { dashboardData, marginTop } = props;
  const [sortedList, setSortedList] = useState<any>([]);

  const orderList = (list: DashboardSiloStatsProps[]) => {
    return list.sort(
      (a: DashboardSiloStatsProps, b: DashboardSiloStatsProps) =>
        b.ALERTA_NUMERO - a.ALERTA_NUMERO
    );
  };

  useEffect(() => {
    if (dashboardData && dashboardData.length > 0) {
      setSortedList(orderList(dashboardData));
    }
  }, [dashboardData]);

  return (
    <>
      {sortedList && (
        <CardComponent
          showInMap
          dashboard
          marginTop={marginTop}
          minHeight={sortedList.length > 7 ? 775 : "auto"}
          title={`Tus silobolsas`}
          titleCount={sortedList.length}
        >
          <ListGroup>
            {sortedList.map(
              (elements: DashboardSiloStatsProps, index: number) => {
                return (
                  <ListGroup.Item
                    key={`silo-${index}`}
                    className="px-0 px-md-2"
                  >
                    {/* DESKTOP CARD */}
                    <div
                      style={{ borderColor: elements.ALERTA_COLOR }}
                      className="d-none d-sm-flex silobag-container justify-between align-center"
                    >
                      <div
                        style={{ width: "95%", display: "inline-flex" }}
                        className="justify-between align-center"
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            width: "30%",
                            marginLeft: 10,
                            gap: 5,
                          }}
                        >
                          <CropIconComponent
                            crop={elements.ELEMENTO_PRODUCTO}
                          />
                          <div
                            className="d-flex"
                            style={{ flexDirection: "column" }}
                          >
                            <span className="h-100 d-flex align-items-center cardCustomFirstTextSilo">
                              {elements.CAMPO_NOMBRE} / {elements.LOTE_NOMBRE}
                            </span>
                            <span className="h-100 d-flex align-items-center cardCustomTextSilo">
                              {elements.ELEMENTO_NOMBRE}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "70%",
                            display: "inline-flex",
                            justifyContent: "flex-end",
                            gap: 10,
                          }}
                          className="align-center"
                        >
                          <div
                            className="h-100 d-flex justify-content-end align-center"
                            style={{ width: "40%" }}
                          >
                            <span
                              className="me-1 fs-4 fw-bold-600"
                              style={{ color: "#6BAA00" }}
                            >
                              {elements.PESO || "-"}
                            </span>
                            <span className="fs-08 fw-bold-500">Toneladas</span>
                          </div>
                          <div
                            style={{ width: "40%" }}
                            className="h-100 d-flex justify-content-end align-center"
                          >
                            <span className="text-secondary fs-08">
                              Valor alerta
                            </span>
                            &nbsp;
                            <span className="fs-09 fw-bold-500 text-reset">
                              {elements.Valor_Alerta
                                ? elements.Valor_Alerta.toFixed(1) + "%"
                                : "-"}
                            </span>
                          </div>
                          <Badge
                            pill
                            bg="custom"
                            className="fs-08"
                            style={{
                              backgroundColor: alertColor(
                                elements.ALERTA_COLOR
                              ),
                              maxHeight: 24,
                            }}
                          >
                            {elements.Indicador || "-"}
                          </Badge>
                        </div>
                      </div>
                      <div style={{ width: "5%" }}>
                        <button
                          className="arrow-button"
                          style={{ marginLeft: "0.1rem" }}
                        >
                          <img src={ForwardArrow} alt="fordward-icon" />
                        </button>
                      </div>
                    </div>

                    {/* MOBILE CARD */}
                    <CardComponent
                      withoutBorder
                      withAlertBorder
                      borderAlertColor={elements.ALERTA_COLOR}
                      className="d-flex d-sm-none"
                      minHeight={76}
                      height={"auto"}
                      marginTop={"mt-0"}
                      backgroundColor={"#F3F2F7"}
                    >
                      <div className="d-flex d-sm-none">
                        <Stack className="flex-grow-1">
                          <div className="d-flex">
                            <div className="d-flex flex-grow-1 align-items-center">
                              <div className="cardCustomFirstTextSilo fs-07">
                                {elements.CAMPO_NOMBRE} / {elements.LOTE_NOMBRE}
                              </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <div className="me-2">
                                <span className="text-secondary fs-07 text-nowrap">
                                  V. alerta
                                </span>
                                &nbsp;
                                <span className="fs-07 fw-bold-500 text-reset">
                                  {elements.Valor_Alerta
                                    ? elements.Valor_Alerta + "%"
                                    : "-"}
                                </span>
                              </div>
                              <Badge
                                pill
                                bg="custom"
                                className="fs-07"
                                style={{
                                  backgroundColor: alertColor(
                                    elements.ALERTA_COLOR
                                  ),
                                }}
                              >
                                {elements.Indicador || "-"}
                              </Badge>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="d-flex flex-grow-1">
                              <CropIconComponent
                                crop={elements.ELEMENTO_PRODUCTO}
                                style={{
                                  height: "2rem",
                                }}
                              />
                              <div className={"cardCustomTextSilo ms-2"}>
                                {elements.ELEMENTO_NOMBRE}
                              </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center me-2">
                              <span
                                className="me-1 fs-5 fw-bold-600"
                                style={{ color: "#6BAA00" }}
                              >
                                {elements.PESO || "-"}
                              </span>
                              <span className="fs-08 fw-bold-500">
                                Toneladas
                              </span>
                            </div>
                          </div>
                        </Stack>
                        <div className="h-100 d-flex justify-content-end align-items-center ms-1">
                          <div className="h-100 d-flex justify-content-end align-items-center">
                            <Image
                              style={{
                                height: "1rem",
                              }}
                              src={ForwardArrow}
                            />
                          </div>
                        </div>
                      </div>
                    </CardComponent>
                  </ListGroup.Item>
                );
              }
            )}
          </ListGroup>
        </CardComponent>
      )}
    </>
  );
};

export default SilobagCardComponent;
