import React, { CSSProperties } from "react";
import { Form } from "react-bootstrap";

interface SelectFieldProps {
  name: string;
  id: string;
  values?: any[];
  defaultValue?: any;
  variant?: "filled" | "standard" | "outlined";
  style?: CSSProperties;
  className?: string;
  onChange?: (e: { value: any; name: string }) => void;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export const CustomSelect = (props: SelectFieldProps) => {
  const [localValue, setLocalValue] = React.useState(props.defaultValue || "");
  const [error, setError] = React.useState(false);

  const {
    disabled,
    onChange,
    defaultValue,
    className,
    placeholder,
    required,
    values,
    ...textProps
  } = props;

  React.useEffect(() => {
    if (defaultValue) {
      setLocalValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (e: any) => {
    const val = {
      target: {
        value: e.target.value == "" ? null : e.target.value,
      },
    };
    setLocalValue(val.target.value);
    onChange && onChange({ value: val, name: props.name });
  };

  const handleValidation = (value: any) => {
    if (!required) return;
    if (value === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const onBlur = (e: any) => {
    handleValidation(e.target.value);
  };

  const defaultClassName = "form-select " + className;

  return (
    <>
      <Form.Label>
        {localValue && `${placeholder || ""} ${required ? " *" : ""}`}
      </Form.Label>
      <select
        {...textProps}
        placeholder="Ciudad"
        className={
          error ? `${defaultClassName} form-control-error` : defaultClassName
        }
        disabled={disabled}
        required={required}
        onBlur={onBlur}
        onChange={handleChange}
        value={localValue}
      >
        <option value={""}>
          {placeholder || ""} {required ? " *" : ""}
        </option>

        {values &&
          values.length > 0 &&
          values.map((val, idx) => (
            <option key={idx} value={val.id}>
              {val.nombre}
            </option>
          ))}
      </select>
    </>
  );
};
