import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MainFooterComponent from "../../components/base/footer/MainFooterComponent";
import { CustomButton } from "../../components/customButton/CustomButton";
import { SiloBagListComponent } from "../../components/silobag";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { ReduxType } from "../../shared/types";
import { getAllSiloBagAction } from "../../store/silobag/actions";

const SiloBagPage = () => {
  const [showToast, setShowToast] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orgData, siloBagData } = useSelector((state: ReduxType) => {
    return state;
  });

  const { state }: any = useLocation();

  useEffect(() => {
    if (state && state.message) {
      setErrorMessage(state.message);
      setErrorType(false);
      setShowToast(true);
      window.history.replaceState({}, document.title);
    }
  }, []);

  useEffect(() => {
    if (orgData.currentOrganization && orgData.currentOrganization.id) {
      getSiloBagList(orgData.currentOrganization.id);
    }
  }, [orgData.currentOrganization]);

  const getSiloBagList = async (organization_id: number) => {
    await dispatch(getAllSiloBagAction(organization_id));
  };

  return (
    <AuthenticatedRoute
      withHeader
      withPadding={false}
      showToast={showToast}
      setShowToast={setShowToast}
      errorType={errorType}
      setErrorType={setErrorType}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      path={Paths.SILO}
    >
      <section className="screen-container">
        <div className="screen-container__layout">
          <div className="screen-container__layout-header">
            <h3 style={{ margin: 0 }}>
              Tus silobolsas (
              {siloBagData &&
                siloBagData.silobagList &&
                siloBagData.silobagList.length}
              )
            </h3>
            <CustomButton
              className={"georeferenciarButton"}
              style={{ width: "auto" }}
              onClick={() => {
                navigate(Paths.NEW_SILO, {
                  state: {
                    createMode: true,
                    editionMode: true,
                    from: Paths.SILO,
                  },
                });
              }}
            >
              <span className={"fw-bold-500"}>Alta silobolsa</span>
            </CustomButton>
          </div>
          <SiloBagListComponent
            silobags={siloBagData && siloBagData.silobagList}
          />
        </div>
        <MainFooterComponent />
      </section>
    </AuthenticatedRoute>
  );
};

export default SiloBagPage;
