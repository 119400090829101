import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdministratorCard } from "../../components/administrator/AdministratorCard";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { ReduxType } from "../../shared/types";
import { CustomModal } from "../../components/modal/CustomModal";
import {
  getAdminDataAction,
  getRolesListAction,
} from "../../store/administrator/actions";
import MainFooterComponent from "../../components/base/footer/MainFooterComponent";
import { findCity } from "../../shared/helper";

const companiesColumns = () => [
  { id: "name", label: "Nombre" },
  { id: "tax_id", label: "CUIT" },
  {
    id: "country_l1_name",
    label: "Provincia",
    render: (value: any, record: any) => <>{value || "-"}</>,
  },
  { id: "acciones", label: "Acciones" },
];

const establishmentsColumns = (companiesList: any[], provincesList: any[]) => [
  { id: "name", label: "Nombre" },
  {
    id: "company_id",
    label: "Compañía",
    render: (value: any, record: any) => {
      const organizacion = companiesList.find(
        (val) => val.id === record.company_id
      );
      return <>{(organizacion && organizacion.name) || "-"}</>;
    },
  },
  {
    id: "provinciaId",
    label: "Provincia",
    render: (value: any, record: any) => (
      <>{(record.provincium && record.provincium.nombre) || "-"}</>
    ),
  },
  { id: "acciones", label: "Acciones" },
];

const userColumns = (companiesList: any[]) => [
  {
    id: "name",
    label: "Nombre",
    render: (value: any, record: any) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <b>{record.fullname || "-"}</b>
          <p style={{ margin: 0 }}>{record.email || "-"}</p>
        </div>
      );
    },
  },
  {
    id: "datos",
    label: "Datos",
    render: (value: any, record: any) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <b>DNI: {record.dni || "-"}</b>
          <p style={{ margin: 0 }}>{record.phone || "-"}</p>
        </div>
      );
    },
  },
  {
    id: "compania",
    label: "Compañía",
    render: (value: any, record: any) => {
      const organizacion = companiesList.find(
        (val) => val.id === record.companiaId
      );
      return <>{(organizacion && organizacion.name) || "-"}</>;
    },
  },
  {
    id: "rol",
    label: "Rol",
    render: (value: any, record: any) => {
      return <div>{(record.role && record.role.nombre) || "-"}</div>;
    },
  },
  { id: "acciones", label: "Acciones" },
];

export const Administrator = () => {
  const [modalShown, setModalShown] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editionMode, setEditionMode] = useState(false);
  const [valuesToEditOrView, setValuesToEditOrView] = useState<any>({});
  const [modalTitle, setModalTitle] = useState("");

  const dispatch = useDispatch();

  const { orgData, administratorData } = useSelector((state: ReduxType) => {
    return state;
  });

  const onModalClose = async () => {
    setCreateMode(false);
    setEditionMode(false);
    setModalTitle("");
    setModalShown(false);
    setValuesToEditOrView({});
    if (orgData.currentOrganization && orgData.currentOrganization.id) {
      await getAdminDataRequest();
      await getRolesListRequest();
    }
  };

  useEffect(() => {
    if (orgData.currentOrganization && orgData.currentOrganization.id) {
      getAdminDataRequest();
      getRolesListRequest();
    }
  }, [orgData.currentOrganization]);

  const getRolesListRequest = async () => {
    await dispatch(getRolesListAction());
  };

  const getAdminDataRequest = async () => {
    await dispatch(getAdminDataAction(1));
  };

  const onEditClick = (values?: any) => {
    setModalShown(true);
    setEditionMode(true);
    values && setValuesToEditOrView(values);
  };

  const onActionClick = async (
    action: "VIEW" | "EDIT" | "CREATE" | "DELETE",
    title: string,
    values: any
  ) => {
    switch (action) {
      case "EDIT":
        onEditClick(values);
        break;
      case "CREATE":
        setModalShown(true);
        setEditionMode(true);
        setCreateMode(true);
        break;
      case "VIEW":
        setModalShown(true);
        if (title == "Establecimientos") {
          values = findCity(values, administratorData, "countrysides");
        }

        setValuesToEditOrView(values);
        break;
      default:
        break;
    }
    setModalTitle(title);
  };

  return (
    <AuthenticatedRoute
      withHeader
      withPadding={false}
      path={Paths.ADMINISTRATOR}
    >
      <section className="screen-container">
        <div className="screen-container__layout">
          <div className="screen-container__layout-header">
            <h3 style={{ margin: 0 }}>Administrador</h3>
            <p style={{ margin: 0 }}>
              <b>Organizacion: </b>
              {(orgData.currentOrganization &&
                orgData.currentOrganization.legal_name) ||
                " SIN ORGANIZACION"}
            </p>
          </div>
          <div
            style={{ display: "flex", width: "100%" }}
            className="administrator-column-container"
          >
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              className="administrator-column"
            >
              <AdministratorCard
                buttonLabel="Nueva compañía"
                className="administrator-company-column"
                onActionClick={onActionClick}
                showTable
                title="Compañías"
                actions={{ edit: true, view: true, delete: false }}
                disableButton={
                  !(
                    orgData.currentOrganization &&
                    orgData.currentOrganization.id
                  )
                }
                columns={companiesColumns()}
                values={administratorData.companiesList}
              />

              <AdministratorCard
                buttonLabel="Nuevo establecimiento"
                className="administrator-establishment-column"
                onActionClick={onActionClick}
                showTable
                title="Establecimientos"
                actions={{ edit: true, view: true, delete: false }}
                disableButton={
                  !(
                    orgData.currentOrganization &&
                    orgData.currentOrganization.id
                  )
                }
                columns={establishmentsColumns(
                  administratorData.companiesList,
                  administratorData.provinces
                )}
                values={administratorData.countrysidesList}
              />
            </div>
            <div className="administrator-column">
              <AdministratorCard
                className="administrator-user-column"
                buttonLabel="Nuevo usuario"
                disableButton={
                  !(
                    orgData.currentOrganization &&
                    orgData.currentOrganization.id
                  )
                }
                onActionClick={onActionClick}
                showTable
                title="Usuarios"
                actions={{ edit: true, view: true, delete: false }}
                columns={userColumns(administratorData.companiesList)}
                values={administratorData.usersList}
              />
            </div>
          </div>
        </div>
        <MainFooterComponent />
      </section>
      <CustomModal
        show={modalShown}
        title={modalTitle}
        editionMode={editionMode}
        createMode={createMode}
        valuesToEditOrView={valuesToEditOrView}
        onEditClick={onEditClick}
        onClose={onModalClose}
      />
    </AuthenticatedRoute>
  );
};
