import React, { CSSProperties } from "react";
import { Image } from "react-bootstrap";
import ArrozPuitaIcon from "../../../assets/icons/svg/DashboardPage/ico-especies/arroz-puita.svg";
import ArrozIcon from "../../../assets/icons/svg/DashboardPage/ico-especies/arroz.svg";
import MaizIcon from "../../../assets/icons/svg/DashboardPage/ico-especies/maiz.svg";
import SojaIcon from "../../../assets/icons/svg/DashboardPage/ico-especies/soja.svg";
import TrigoIcon from "../../../assets/icons/svg/DashboardPage/ico-especies/trigo.svg";
import EstablishmentIcon from "../../../assets/icons/svg/DashboardPage/ico-estab.svg";

interface CropIconComponentProps {
  crop: string;
  style?: CSSProperties | undefined;
}

const CropIconComponent = (props: CropIconComponentProps) => {
  const { crop, style } = props;

  const getIconFromProduct = (product: string) => {
    switch (true) {
      case [
        "Maiz",
        "Maíz",
        "Maíz waxy",
        "Maíz dentado",
        "Maíz pisingallo",
        "Maíz blanco",
        "Girasol",
      ].includes(product):
        return MaizIcon;
      case product.includes("Soja"):
        return SojaIcon;
      case product.includes("Arroz") && !product.includes("Puita"):
        return ArrozIcon;
      case product.includes("Trigo") || product.includes("Sorgo"):
        return TrigoIcon;
      case product.includes("Puita"):
        return ArrozPuitaIcon;
    }
    return EstablishmentIcon;
  };

  return <Image style={style} src={getIconFromProduct(crop)} />;
};

export default CropIconComponent;
