import * as types from "./actionTypes";

export const getDashboardDataAction = (dashboardRequest: any) => {
  return {
    type: types.GET_DASHBOARD_DATA,
    ...dashboardRequest,
  };
};

export const getDashboardDataFail = (error: any) => {
  return {
    type: types.GET_DASHBOARD_DATA_FAIL,
    payload: error,
  };
};

export const getDashboardDataSuccess = (dashboard: any) => {
  return {
    type: types.GET_DASHBOARD_DATA_SUCCESS,
    payload: dashboard,
  };
};
