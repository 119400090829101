import React, { CSSProperties, useState } from "react";
import { CustomTable } from "./CustomTable";
import AddIcon from "../../assets/icons/svg/AdministratorPage/add-white-icon.svg";
import { ReduxType } from "../../shared/types";
import { useSelector } from "react-redux";

export interface AdministratorCardProps {
  title: string;
  columns: any[];
  onActionClick: (
    action: "VIEW" | "EDIT" | "CREATE" | "DELETE",
    title: string,
    values: any
  ) => void;
  buttonLabel: string;
  showTable: boolean;
  disableButton?: boolean;
  onClose?: () => void;
  actions?: { view: boolean; edit: boolean; delete: boolean };
  values?: any[];
  className?: string;
  style?: CSSProperties;
}

const getValuesByCard = (title: string, record: any) => {
  const {
    id,
    email,
    tax_id,
    name,
    legal_name,
    phone,
    fullname,
    roles,
    organization_id,
    company_id,
    lon,
    lat,
    municipality_id,
    permissions,
    locality_id,
    floor,
    apartment,
    street_name,
    street_number,
    zip_code,
    active,
    country_l2_id,
  } = record;
  switch (title) {
    case "Establecimientos":
      return {
        id,
        company_id,
        name,
        lon,
        lat,
        municipality_id,
        locality_id,
        country_l2_id,
      };
    case "Compañías":
      return {
        id,
        email,
        tax_id,
        name,
        legal_name,
        phone,
        municipality_id,
        locality_id,
        floor,
        apartment,
        street_name,
        street_number,
        zip_code,
      };
    case "Usuarios":
      return {
        id,
        active,
        fullname,
        roles,
        email,
        phone,
        tax_id,
        organization_id,
        permissions,
      };
    default:
      return {};
  }
};

export const AdministratorCard = (props: AdministratorCardProps) => {
  const {
    style,
    title,
    buttonLabel,
    disableButton,
    onActionClick,
    showTable,
    onClose,
    columns,
    values,
    actions,
    className,
  } = props;

  const { orgData } = useSelector((state: ReduxType) => {
    return state;
  });
  const handleActionClick = (
    action: "EDIT" | "VIEW" | "CREATE" | "DELETE",
    record: any
  ) => {
    onActionClick &&
      onActionClick(
        action,
        title,
        getValuesByCard(title, {
          ...record,
          organization_id: orgData.currentOrganization.id,
        })
      );
  };

  return (
    <>
      <div
        style={{
          ...style,
        }}
        className={`administrator-card ${className}`}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5 style={{ margin: 0 }}>{title}</h5>
          <button
            className="custom-button"
            disabled={disableButton}
            onClick={() => handleActionClick("CREATE", {})}
          >
            <img src={AddIcon} alt="add-icon" style={{ paddingRight: 5 }} />
            {buttonLabel}
          </button>
        </div>
        <div style={{ display: "flex", marginTop: 15, overflow: "auto" }}>
          <CustomTable
            columns={columns}
            values={values}
            actions={actions}
            onActionClick={handleActionClick}
          />
        </div>
      </div>
    </>
  );
};
