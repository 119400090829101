import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { stateSiloBagColorText } from "../../../../shared/helper";
import Separator from "../../../../assets/icons/svg/SiloBagPage/separator.svg";

const Stages = [
  {
    estadoId: 1,
    title: "Incompleto",
    tooltip: "Carga de los datos obligatorios para la creación del silobolsa",
    withSeparator: true,
  },
  {
    estadoId: 2,
    title: "Completo",
    tooltip: "Incluye los datos de geolocalización desde la plataforma",
    withSeparator: true,
  },
  {
    estadoId: 3,
    title: "Certificable",
    tooltip:
      "Carga completa desde la app, contemplando al menos un dispositivo",
    withSeparator: false,
  },
];

const StageComponent = (props: any) => {
  const { estadoId } = props;

  return (
    <>
      <Row className="justify-content-between align-center">
        {Stages &&
          Stages.map((s) => {
            return (
              <>
                <Col xs="auto" className="mb-4 mb-xl-0 fw-bold-500 main-stage">
                  <div
                    style={{
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      gap: 7,
                    }}
                  >
                    <span className={"fw-bold-500 main-stage toooltip"}>
                      <span className="content align-center">
                        <span
                          className={
                            estadoId &&
                            estadoId == s.estadoId &&
                            stateSiloBagColorText &&
                            stateSiloBagColorText[estadoId]
                          }
                        >
                          {s.title}
                        </span>
                        {s.withSeparator && (
                          <Image
                            style={{
                              width: 50,
                              marginLeft: 4,
                              marginRight: 4,
                            }}
                            className={"align-middle"}
                            src={Separator}
                          />
                        )}
                      </span>
                      <span className="toooltiptext tooltip-top">
                        {s.tooltip}
                      </span>
                    </span>
                  </div>
                </Col>{" "}
              </>
            );
          })}
      </Row>
    </>
  );
};

export default StageComponent;
