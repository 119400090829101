import React, { useEffect } from "react";
import { Paths } from "../../config/paths";
import { unauthorizedCallback } from "../../core/auth/AuthService";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

const LogoutPage = () => {
  useEffect(() => {
    try {
      unauthorizedCallback();
    } catch (error: any) {
      console.error(error.message);
    }
  }, []);

  return (
    <AuthenticatedRoute path={Paths.LOGOUT} loading={true}>
      <></>
    </AuthenticatedRoute>
  );
};

export default LogoutPage;
