import _ from "lodash";
import { findIndex } from "./helper";

const validateForm = (values: any, validatorValues: any) => {
  let validate = true;
  let arrayVal = [...validatorValues];
  Object.keys(values).forEach((val: any) => {
    let index = findIndex(arrayVal, "name", val);
    if (index !== -1) {
      if (values[val]) {
        if (arrayVal[index].pattern) {
          validate = arrayVal[index].pattern.test(values[val]);
          if (!validate) {
            throw new Error(
              `El campo ${val.replace("Id", "")} ${
                arrayVal[index].regexMessage && arrayVal[index].regexMessage
              }`
            );
          }
        }
        if (_.isArray(values[val])) {
          if (values[val].length === 0 && arrayVal[index].required) {
            throw new Error(
              `El campo ${val.replace("Id", "")} se encuentra incompleto`
            );
          }
        }
      } else if (arrayVal[index].required) {
        throw new Error(
          `El campo ${
            arrayVal[index].nameToShow
              ? arrayVal[index].nameToShow
              : val.replace("Id", "")
          } es obligatorio`
        );
      }
    }
  });
  return validate;
};

const prepareToastData = async (
  response: any,
  entity: string,
  attr: string,
  determinante: string
) => {
  if ((response && response.id) || (response && response.message)) {
    const message = response.id
      ? `${determinante} ${entity} #${response[attr]} se creó de forma correcta`
      : response.message;
    return message;
  } else {
    throw new Error(response);
  }
};

export { prepareToastData, validateForm };
