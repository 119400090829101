import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import backgrounIOFCompany from "../../assets/img/LoginPage/backgrounIOFCompany.png";
import IOFLogo from "../../assets/icons/svg/LoginPage/logo-white.svg";
import { useNavigate } from "react-router-dom";
import { ReduxType } from "../../shared/types";
import { ToastComponent } from "../../components/toast";
import { Paths } from "../../config/paths";
import { FooterComponent } from "../../components/base";

const LoginLayaout = (props: any) => {
  const [show, setShow] = useState(false);
  const [errorType, setErrorType] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const { children, width } = props;
  const navigate = useNavigate();

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (userData.error && userData.error.message) {
      setAndShowError(userData.error.message, true);
    }
  }, [userData.error]);

  useEffect(() => {
    if (userData.userReset) {
      setAndShowError(userData.userReset, false);
      navigate(Paths.LOGIN);
    }
  }, [userData.userReset]);

  const setAndShowError = (error: any, errorType: boolean) => {
    setErrorType(errorType);
    setErrorMessage(error);
    toggleShow();
  };

  const toggleShow = () => setShow(!show);

  return (
    <>
      <Row className="align-items-center justify-content-center" align="middle">
        <Col
          md={5}
          style={{
            height: width <= 768 ? "50vh" : "100vh",
            backgroundSize: "cover",
            backgroundImage: `url(${backgrounIOFCompany})`,
          }}
        >
          <Image
            style={{
              height: "143.88",
              width: width <= 768 ? "10rem" : 319,
              marginTop: width <= 768 ? "20vh" : "40vh",
            }}
            src={IOFLogo}
          />
        </Col>
        <Col md={7}>
          <ToastComponent
            showToast={show}
            errorType={errorType}
            toggleShow={toggleShow}
            title={"Atencion!"}
            bodyContent={errorMessage}
          />

          {children}
          <FooterComponent width={width} />
        </Col>
      </Row>
    </>
  );
};

export default LoginLayaout;
