import React from "react";
import {
  ArrowButtonProps,
  InfoWindow,
  MapDashboardProps,
} from "../../../shared/types";

import RightArrow from "../../../assets/icons/svg/DashboardPage/chevron-right.svg";
import Lanzas from "../../../assets/icons/svg/EstablishmentsMapPage/lanzas.svg";
import Alert from "../../../assets/icons/svg/EstablishmentsMapPage/alert-bell.svg";
import OpenAlert from "../../../assets/icons/svg/EstablishmentsMapPage/open-alert-bell.svg";
import { getElementIcon } from "./helper";
import { ZoomControls } from "../mapControls";
import { alertColor } from "../../../shared/helper";

const ArrowButton = (props: ArrowButtonProps) => {
  const { className, onClick, disabled } = props;
  return (
    <button onClick={onClick} className="arrow-button" disabled={disabled}>
      <img
        src={RightArrow}
        alt="arrow-icon"
        className={className}
        style={disabled ? { filter: "grayscale(1)" } : {}}
      />
    </button>
  );
};

const LanzasComponent = ({ lanzas, isMobile }: any) => {
  return (
    <div className="lanzas-container">
      {lanzas.map((value: InfoWindow, index: number) => {
        let classname = "";

        if (index === 0) {
          classname += "lanza-left ";
        }
        if (index === lanzas.length - 1) {
          classname += "lanza-right";
        }

        return (
          <div
            style={{
              background: alertColor(value.ALERTA_COLOR),
              width:
                lanzas.length <= 4 && !isMobile ? 40 : `${90 / lanzas.length}%`,
              height: 30,
              outline: "rgb(0 0 0 / 7%) solid 1.5px",
            }}
            className={classname}
            key={index}
          ></div>
        );
      })}
    </div>
  );
};

const getIsMobile = () => window.innerWidth <= 768;

const MapDashboard = (props: MapDashboardProps) => {
  const {
    marker,
    setSelectedEstablishment,
    markersLength,
    selectedEstablishment,
    lanzas,
    setIsAlertsOpen,
    alertsCounter,
    isAlertsOpen,
    map,
    isDashboardOpen,
  } = props;

  const mrkr = marker[selectedEstablishment];

  const handleElementChange = (to: number) => {
    if (!map) return;
    setIsAlertsOpen(false);
    if (to > selectedEstablishment && to <= markersLength - 1) {
      setSelectedEstablishment(to);
      map.panTo({
        lat: parseFloat(marker[to].ELEMENTO_LATITUD),
        lng: parseFloat(marker[to].ELEMENTO_LONGITUD),
      });
    } else if (to < selectedEstablishment && to >= 0) {
      setSelectedEstablishment(to);
      map.panTo({
        lat: parseFloat(marker[to].ELEMENTO_LATITUD),
        lng: parseFloat(marker[to].ELEMENTO_LONGITUD),
      });
    }
  };

  const [isMobile, setIsMobile] = React.useState(getIsMobile());

  React.useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        margin: "5px 15px",
        bottom: 30,
        maxWidth: "100%",
        zIndex: 2,
        minHeight: 77,
      }}
    >
      <ZoomControls
        isDashboardOpen={isDashboardOpen}
        map={map}
        key="zoom-controls-key2"
        style={{
          bottom: "calc(100% + 20px)",
          display: "flex-inline",
          right: 0,
        }}
      />

      <div
        className="map-dashboard-container align-center d-flex justify-between"
        id="map-dashboard"
      >
        <div>
          <ArrowButton
            onClick={() => handleElementChange(selectedEstablishment - 1)}
            className="invert-icon"
            disabled={selectedEstablishment === 0 ? true : false}
          />
        </div>
        <div className="map-dashboard align-center justify-between">
          <div className="map-dashboard__left align-center">
            <img src={getElementIcon(mrkr.ELEMENTO_PRODUCTO)} alt="icon" />
            <span>{mrkr.ELEMENTO_NOMBRE}</span>
          </div>
          <div className="map-dashboard__right align-center justify-between">
            <div className="map-dashboard__right--simple-text">
              <span>Especie</span>
              <b className="fw-bold-500">{mrkr.ELEMENTO_PRODUCTO}</b>
            </div>
            <div className="map-dashboard__right--simple-text">
              <span>Producto</span>
              <b className="fw-bold-500">{mrkr.ELEMENTO_TIPO}</b>
            </div>
            <div className="map-dashboard__right--lanzas align-center">
              <img src={Lanzas} alt="lanzas-icon" />
              <b>{mrkr.ELEMENTO_CANTIDAD_DISPOSITIVO}</b>
              <span>Lanzas</span>
              {!isMobile && lanzas && lanzas.length > 0 && (
                <LanzasComponent lanzas={lanzas} isMobile={isMobile} />
              )}
            </div>
            <button
              onClick={() =>
                alertsCounter > 0 && setIsAlertsOpen(!isAlertsOpen)
              }
              className="map-dashboard__right--alertas align-center"
            >
              <img src={isAlertsOpen ? OpenAlert : Alert} alt="alert-bell" />
              <b>{alertsCounter}</b>
              <span>Alertas</span>
            </button>
            {isMobile && lanzas && lanzas.length > 0 && (
              <div style={{ width: "100%" }}>
                <LanzasComponent lanzas={lanzas} isMobile={isMobile} />
              </div>
            )}
          </div>
        </div>
        <div>
          <ArrowButton
            onClick={() => handleElementChange(selectedEstablishment + 1)}
            disabled={
              selectedEstablishment === markersLength - 1 ? true : false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MapDashboard;
