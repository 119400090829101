import * as types from "./actionTypes";

export const getProvincesListDataAction = (cleanData: boolean) => {
  return {
    type: types.GET_PROVINCE,
    cleanData,
  };
};

export const getProvincesListDataFail = (error: any) => {
  return { type: types.GET_PROVINCE_FAIL, payload: error };
};

export const getProvincesListDataSuccess = (provincesList: any) => {
  return {
    type: types.GET_PROVINCE_SUCCESS,
    payload: provincesList,
  };
};

export const getCitiesListDataAction = (
  provinceId: number | null,
  cleanData: boolean
) => {
  return {
    type: types.GET_CITY,
    provinceId,
    cleanData,
  };
};

export const getCitiesListDataFail = (error: any) => {
  return { type: types.GET_CITY_FAIL, payload: error };
};

export const getCitiesListDataSuccess = (citiesList: any) => {
  return {
    type: types.GET_CITY_SUCCESS,
    payload: citiesList,
  };
};

export const getLocalitiesListDataAction = () => {
  return {
    type: types.GET_LOCALITY,
  };
};

export const getLocalitiesListDataFail = (error: any) => {
  return { type: types.GET_LOCALITY_FAIL, payload: error };
};

export const getLocalitiesListDataSuccess = (localitiesList: any) => {
  return {
    type: types.GET_LOCALITY_SUCCESS,
    payload: localitiesList,
  };
};

export const getRolesListAction = () => {
  return { type: types.GET_ROLES_LIST };
};

export const getRolesListFail = (error: any) => {
  return { type: types.GET_ROLES_LIST_FAIL, payload: error };
};

export const getRolesListSuccess = (payload: any) => {
  return { type: types.GET_ROLES_LIST_SUCCESS, payload };
};

export const getAdminDataAction = (org_id?: any) => {
  return { type: types.GET_ADMIN_DATA, org_id };
};

export const getAdminDataFail = (error: any) => {
  return { type: types.GET_ADMIN_DATA_FAIL, payload: error };
};

export const getAdminDataSuccess = (payload: any) => {
  return { type: types.GET_ADMIN_DATA_SUCCESS, payload };
};

export const getEstablishmentsListAction = (org_id?: any) => {
  return { type: types.GET_ESTABLISHMENTS, org_id };
};

export const getEstablishmentsListFail = (error: any) => {
  return { type: types.GET_ESTABLISHMENTS_FAIL, payload: error };
};

export const getEstablishmentsListSuccess = (payload: any) => {
  return { type: types.GET_ESTABLISHMENTS_SUCCESS, payload };
};

export const getCompaniesListAction = (org_id?: any) => {
  return { type: types.GET_COMPANIES, org_id };
};

export const getCompaniesListFail = (error: any) => {
  return { type: types.GET_COMPANIES_FAIL, payload: error };
};

export const getCompaniesListSuccess = (payload: any) => {
  return { type: types.GET_COMPANIES_SUCCESS, payload };
};

export const getLotsListAction = (establishment_id?: any) => {
  return { type: types.GET_LOTS, establishment_id };
};

export const getLotsListFail = (error: any) => {
  return { type: types.GET_LOTS_FAIL, payload: error };
};

export const getLotsListSuccess = (payload: any) => {
  return { type: types.GET_LOTS_SUCCESS, payload };
};

export const getAllowedEstablishmentAction = (user_id?: any) => {
  return {
    type: types.GET_ALLOWED_ESTABLISHMENT,
    user_id,
  };
};

export const getAllowedEstablishmentFail = (error: any) => {
  return {
    type: types.GET_ALLOWED_ESTABLISHMENT_FAIL,
    payload: error,
  };
};

export const getAllowedEstablishmentSuccess = (payload: any) => {
  return {
    type: types.GET_ALLOWED_ESTABLISHMENT_SUCCESS,
    payload,
  };
};

export const updateAllowedEstablishmentAction = (user_id?: any) => {
  return {
    type: types.UPDATE_ALLOWED_ESTABLISHMENT,
    user_id,
  };
};

export const updateAllowedEstablishmentFail = (error: any) => {
  return {
    type: types.UPDATE_ALLOWED_ESTABLISHMENT_FAIL,
    payload: error,
  };
};

export const updateAllowedEstablishmentSuccess = (payload: any) => {
  return {
    type: types.UPDATE_ALLOWED_ESTABLISHMENT_SUCCESS,
    payload,
  };
};
