import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";
import { diffDates, formatDatetime } from "../../../shared/helper";

import { CardComponent } from "../../card";
import { CropIconComponent } from "../../dashboard/cropIcon";

import EditIcon from "../../../assets/icons/svg/SiloBagPage/view/edit.svg";
import ImgIcon from "../../../assets/icons/svg/SiloBagPage/view/img.svg";
import MapIcon from "../../../assets/icons/svg/SiloBagPage/view/map.svg";
import ReloadIcon from "../../../assets/icons/svg/SiloBagPage/view/reload.svg";
import TasIcon from "../../../assets/icons/svg/SiloBagPage/view/tas.svg";
import {
  SiloBagViewConstBaseProps,
  SiloBagViewConstProps,
} from "../../../shared/types";
import { SilobagImgModalComponent } from "../silobagImgModal";

const MainCardElements = (props: any) => {
  const { colSize, value, title, withCrop } = props;

  const getCropData = () => {
    return value
      .toLowerCase()
      .split(" ")
      .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };

  return (
    <Col xs={colSize} className="mb-4 mb-xl-0">
      <div
        style={{ width: "100%", display: "inline-flex" }}
        className=" align-center"
      >
        {withCrop && <CropIconComponent crop={getCropData()} />}
        <div
          className="d-flex"
          style={{ flexDirection: "column", marginLeft: withCrop ? "4vh" : 0 }}
        >
          <div className="h-100 d-flex align-items-center silo-card-view-title fw-bold-500">
            {value || ""}
          </div>
          <div className="h-100 d-flex align-items-center silo-card-view-second-title fw-bold-400">
            {title}
          </div>
        </div>
      </div>
    </Col>
  );
};

const SiloBagViewComponent = (props: any) => {
  const [showImgModal, setShowImgModal] = useState<boolean>(false);

  const { record } = props;

  const navigate = useNavigate();

  const data: SiloBagViewConstProps[][] = [
    [
      {
        colSize: 3,
        withCrop: true,
        title: "Especie",
        value:
          (record &&
            record.silo_tipo_especy &&
            record.silo_tipo_especy.nombre) ||
          "-",
      },
      {
        colSize: 3,
        title: "Producto",
        value:
          (record &&
            record.silo_tipo_producto &&
            record.silo_tipo_producto.nombre) ||
          "-",
      },
      {
        subData: [
          {
            colSize: 6,
            title: "Cosecha",
            value: (record && record.cosecha && record.cosecha.nombre) || "-",
          },
          {
            colSize: 6,
            title: "Largo silobolsa",
            value: (record && record.tamanio) || "-",
          },
        ],
      },
    ],
    [
      {
        colSize: 3,
        title: (
          <span>
            TAS <Image style={{ height: 14, width: 14 }} src={TasIcon} />
          </span>
        ),
        value: (record && record.tas && record.tas) || "-",
      },
      {
        colSize: 3,
        title: "Humedad de embolsado",
        value: `${record && record.humedadInicial} %` || "-",
      },
      {
        subData: [
          {
            colSize: 6,
            title: "Peso embolsado",
            value: `${record && record.pesoIngresado} Kg` || "-",
          },
          {
            colSize: 6,
            title: "Desde confección",
            value: diffDates(record.fechaArmado) || "-",
          },
        ],
      },
    ],
  ];

  const actions = [
    {
      icon: MapIcon,
      action: "map",
      name: "Ver en mapa",
    },
    { icon: ImgIcon, action: "img", name: "Galería de imágenes" },
    { icon: EditIcon, action: "edit", name: "Editar" },
    { icon: ReloadIcon, action: "reload", name: "Refrescar" },
  ];

  const handleAction = (action: string) => {
    switch (action) {
      case "map":
        break;
      case "img":
        setShowImgModal(true);
        break;
      case "edit":
        navigate(Paths.NEW_SILO, {
          state: {
            from: Paths.SILO,
            createMode: false,
            editionMode: true,
            record: record,
          },
        });
        break;
      case "reload":
        navigate(Paths.VIEW_SILO, {
          state: {
            from: Paths.SILO,
            record: record,
          },
        });
        break;
    }
  };
  return (
    <>
      <SilobagImgModalComponent
        title={"Imágenes"}
        showModal={showImgModal}
        data={record}
        handleClose={() => setShowImgModal(false)}
      />
      <CardComponent title dashboard minHeight={302} marginTop={""}>
        <Row className="justify-content-between align-center">
          <Col xs="10" className="mb-4 mb-xl-0">
            <Row className="justify-content-between align-center">
              <Col xs="3" className="mb-4 mb-xl-0">
                <span className={"card-label fw-bold-400"}>
                  Alta{" "}
                  <span className={"card-label-value fw-bold-500"}>
                    {record &&
                      record.createdAt &&
                      formatDatetime(record.createdAt)}
                  </span>
                </span>
              </Col>
              <Col xs="3" className="mb-4 mb-xl-0">
                <span className={"card-label fw-bold-400"}>
                  ID{" "}
                  <span className={"card-label-value fw-bold-500"}>
                    {record && record.id}
                  </span>
                </span>
              </Col>
              <Col xs="3" className="mb-4 mb-xl-0">
                <span className={"card-label fw-bold-400"}>
                  Establecimiento{" "}
                  <span className={"card-label-value fw-bold-500"}>
                    {(record &&
                      record.lote &&
                      record.lote.establecimiento &&
                      record.lote.establecimiento.nombre) ||
                      "-"}
                  </span>
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs="2" className="mb-4 mb-xl-0">
            <span className={"card-label fw-bold-400"}>
              Lote{" "}
              <span className={"card-label-value fw-bold-500"}>
                {(record && record.lote && record.lote.nombre) || "-"}
              </span>
            </span>
          </Col>
        </Row>
        <Row className="justify-content-between align-center">
          <Col xs="10" className="mb-4 mb-xl-0">
            {data &&
              data.length > 0 &&
              data.map((d, index: number) => {
                return (
                  <Row
                    key={`key-main-element-${index}`}
                    className="justify-content-between align-center mt-2"
                  >
                    {d.map((element, index: number) => {
                      const comp = (
                        <MainCardElements
                          withCrop={element.withCrop}
                          colSize={element.colSize}
                          title={element.title}
                          value={element.value}
                        />
                      );
                      return (
                        <>
                          {element.subData ? (
                            <Col
                              key={`key-element-${index}`}
                              xs="3"
                              className="mb-4 mb-xl-0"
                            >
                              <Row className="justify-content-between align-center mt-4">
                                {element.subData.map(
                                  (
                                    e: SiloBagViewConstBaseProps,
                                    index: number
                                  ) => {
                                    return (
                                      <MainCardElements
                                        key={`key-sub-element-${index}`}
                                        colSize={e.colSize}
                                        title={e.title}
                                        value={e.value}
                                      />
                                    );
                                  }
                                )}
                              </Row>
                            </Col>
                          ) : (
                            <>{comp}</>
                          )}
                        </>
                      );
                    })}
                  </Row>
                );
              })}
          </Col>
          <Col xs="2" className="mb-4 mb-xl-0">
            {actions &&
              actions.map((a, index: number) => {
                return (
                  <div className="h-100 d-flex align-items-center mt-3">
                    <div
                      onClick={() => {
                        handleAction(a.action);
                      }}
                      className={"silobag-view-link"}
                    >
                      <Image
                        style={{
                          width: "12.8px",
                          height: "16px",
                        }}
                        src={a.icon}
                      />{" "}
                      <span className="silogbag-view-actions fw-bold-500">
                        {a.name}
                      </span>
                    </div>
                  </div>
                );
              })}
          </Col>
        </Row>
      </CardComponent>
    </>
  );
};

export default SiloBagViewComponent;
