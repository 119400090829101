import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { getToken } from "../../core/auth/AuthService";
import { genericPostRequest } from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  getEstablishmentFromSiloByLoteIdFail,
  getEstablishmentFromSiloByLoteIdSuccess,
  getEstablishmentsMapDataFail,
  getEstablishmentsMapDataSuccess,
  getLanzasByLoteIdFail,
  getLanzasByLoteIdSuccess,
} from "./actions";
import {
  GET_ESTABLISHMENTS_MAP_DATA,
  GET_ESTABLISHMENT_FROM_SILO_BY_LOTE_ID,
  GET_LANZAS_BY_LOTE_ID,
} from "./actionTypes";

function* onGetEstablishmentsMapData(action: any) {
  try {
    const { user_id, org_id, lote_id } = action.payload;
    const bodyContent = {
      user_id,
      org_id,
      lote_id,
      type: "es",
    };
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericPostRequest(
          "v1/dashboard",
          Endpoints.ESTABLISHMENTS_MAP,
          bodyContent,
          7,
          getToken()
        )
      );
    });
    yield put(getEstablishmentsMapDataSuccess(response.data));
  } catch (error: any) {
    yield put(getEstablishmentsMapDataFail(error.response));
  }
}

function* onGetEstablishmentFromSiloByLoteId(action: any) {
  try {
    const { user_id, org_id, lote_id } = action.payload;
    const bodyContent = {
      user_id,
      org_id,
      lote_id,
      type: "es",
    };
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericPostRequest(
          "v1/dashboard",
          Endpoints.ESTABLISHMENTS_MAP,
          bodyContent,
          7,
          getToken()
        )
      );
    });
    yield put(getEstablishmentFromSiloByLoteIdSuccess(response.data));
  } catch (error: any) {
    yield put(getEstablishmentFromSiloByLoteIdFail(error.response));
  }
}

function* onGetLanzasByLoteId(action: any) {
  try {
    const { user_id, org_id, lote_id } = action.payload;
    const bodyContent = {
      user_id,
      org_id,
      lote_id,
      type: "es",
    };
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericPostRequest(
          "v1/dashboard",
          Endpoints.ESTABLISHMENTS_MAP,
          bodyContent,
          7,
          getToken()
        )
      );
    });
    yield put(getLanzasByLoteIdSuccess(response.data));
  } catch (error: any) {
    yield put(getLanzasByLoteIdFail(error.response));
  }
}

function* EstablishmentsMapSaga() {
  yield takeLatest(GET_ESTABLISHMENTS_MAP_DATA, onGetEstablishmentsMapData);
  yield takeLatest(GET_LANZAS_BY_LOTE_ID, onGetLanzasByLoteId);
  yield takeLatest(
    GET_ESTABLISHMENT_FROM_SILO_BY_LOTE_ID,
    onGetEstablishmentFromSiloByLoteId
  );
}

export default EstablishmentsMapSaga;
