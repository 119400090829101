import React from "react";
import { Card, Image, Nav, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../config/paths";
import { alertColor } from "../../shared/helper";

import DeleteIcon from "../../assets/icons/svg/SiloBagPage/trash.svg";

const CardComponent = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    backgroundColor,
    borderAlertColor,
    children,
    dashboard,
    height,
    marginTop,
    minHeight,
    showInMap,
    title,
    titleCount,
    withoutBorder,
    withDelete,
    withAlertBorder,
    hideOverflow,
    bodyClassName,
    className,
    style,
    deleteAction,
  } = props;

  const cleanAndNavigate = () => {
    navigate(Paths.ESTABLISHMENTS_MAPS);
  };

  return (
    <Card
      style={{
        maxHeight: minHeight,
        backgroundColor: backgroundColor && backgroundColor,
        borderColor: alertColor(borderAlertColor),
        ...props.style,
      }}
      className={
        `cardComponent
      ${dashboard ? `p-2 p-md-4` : ``} ${marginTop ? marginTop : `mt-5`}
      ${
        withoutBorder && !withAlertBorder
          ? `cardWithoutAnyBorder`
          : withAlertBorder
          ? `cardWithAlertBorder`
          : `cardWithoutAnyBorder`
      }
      ` + props.className
      }
    >
      {title && (
        <Card.Header
          className={"cardCustomHead d-flex justify-content-between"}
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          <span className="fw-bold-500 fs-4">
            {title}{" "}
            {titleCount && (
              <span className="cardTitleCount">({titleCount})</span>
            )}
          </span>
          {showInMap && (
            <Nav.Link
              className={"cardCustomLink fw-bold-500 p-1 "}
              onClick={() => cleanAndNavigate()}
            >
              Ver en mapa
            </Nav.Link>
          )}
          {withDelete && (
            <Nav.Link
              className={"cardCustomLink fw-bold-500 p-1 "}
              onClick={() => deleteAction()}
            >
              <Image
                style={{
                  width: 12.25,
                  height: 14,
                }}
                className={"align-middle"}
                src={DeleteIcon}
              />
            </Nav.Link>
          )}
        </Card.Header>
      )}
      <Card.Body
        style={{
          height: height ? height : undefined,
          maxHeight: minHeight ? minHeight : undefined,
          overflow: hideOverflow ? "hidden" : undefined,
          overflowY: title ? "auto" : "unset",
        }}
        className={`p-md-3 ${bodyClassName}`}
      >
        <Row className="justify-content-between">{children}</Row>
      </Card.Body>
    </Card>
  );
};

export default CardComponent;
