import { takeLatest, put, call } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { getToken } from "../../core/auth/AuthService";
import { genericPostRequest, genericRequest } from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import { getDashboardDataFail, getDashboardDataSuccess } from "./actions";

import { GET_DASHBOARD_DATA } from "./actionTypes";

function* onGetDashboardData(payload: any) {
  try {
    const bodyContet = {
      user_id: payload.user_id,
      org_id: payload.org_id,
      type: "es",
    };
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericPostRequest(
          "v1/dashboard",
          Endpoints.DASHBOARD,
          bodyContet,
          7,
          getToken()
        )
      );
    });
    yield put(getDashboardDataSuccess(response.data));
  } catch (error: any) {
    yield put(getDashboardDataFail(error.response));
  }
}

function* DashboardSaga() {
  yield takeLatest(GET_DASHBOARD_DATA, onGetDashboardData);
}

export default DashboardSaga;
