import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import {
  API_URL,
  API_URL_BACKOFFICE,
  API_WOZALABS_URL,
} from "../../config/general-config";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  genericRequest,
} from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  getAdminDataFail,
  getAdminDataSuccess,
  getAllowedEstablishmentFail,
  getAllowedEstablishmentSuccess,
  getCitiesListDataFail,
  getCitiesListDataSuccess,
  getEstablishmentsListFail,
  getEstablishmentsListSuccess,
  getLocalitiesListDataFail,
  getLocalitiesListDataSuccess,
  getLotsListFail,
  getLotsListSuccess,
  getProvincesListDataFail,
  getProvincesListDataSuccess,
  getRolesListFail,
  getRolesListSuccess,
  updateAllowedEstablishmentFail,
  updateAllowedEstablishmentSuccess,
} from "./actions";
import {
  GET_ADMIN_DATA,
  GET_ALLOWED_ESTABLISHMENT,
  GET_CITY,
  GET_ESTABLISHMENTS,
  GET_LOCALITY,
  GET_LOTS,
  GET_PROVINCE,
  GET_ROLES_LIST,
  UPDATE_ALLOWED_ESTABLISHMENT,
} from "./actionTypes";

function* onGetProvincesListData(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/api/${Endpoints.LOCATIONS.GET_PROVINCE}`,
        })
      );
    });
    yield put(
      getProvincesListDataSuccess({
        response: response,
        cleanData: action.cleanData,
      })
    );
  } catch (error) {
    yield put(getProvincesListDataFail(error));
  }
}

function* onGetCities(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.HELPERS.CITY}`,
        })
      );
    });
    yield put(
      getCitiesListDataSuccess({
        response: response,
        cleanData: action.cleanData,
      })
    );
  } catch (error) {
    yield put(getCitiesListDataFail(error));
  }
}

function* onGetLocalities(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.HELPERS.LOCALITY}`,
        })
      );
    });
    yield put(getLocalitiesListDataSuccess(response));
  } catch (error) {
    yield put(getLocalitiesListDataFail(error));
  }
}

function* onGetEstablishmentsList(payload: any) {
  try {
    const { org_id } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/api/${Endpoints.ESTABLISHMENTS}${
            org_id ? `/${org_id}` : ""
          }`,
        })
      );
    });
    yield put(getEstablishmentsListSuccess(response));
  } catch (error) {
    yield put(getEstablishmentsListFail(error));
  }
}

function* onGetRolesList() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({ url: `${API_URL_BACKOFFICE}/api/${Endpoints.ROLES}` })
      );
    });
    yield put(getRolesListSuccess(response));
  } catch (error) {
    yield put(getRolesListFail(error));
  }
}

function* onGetAdminData(payload: any) {
  try {
    const { org_id } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ADMIN_DATA}${org_id}`,
        })
      );
    });
    yield put(getAdminDataSuccess(response));
  } catch (error) {
    yield put(getAdminDataFail(error));
  }
}

function* onGetLotsList(payload: any) {
  try {
    const { establishment_id } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.LOTS.GET_LOTS_BY_STABLISHMENT}${establishment_id}`,
        })
      );
    });
    yield put(getLotsListSuccess(response));
  } catch (error) {
    yield put(getLotsListFail(error));
  }
}

function* onGetAllowedEstablishment(payload: any) {
  try {
    const { user_id } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.ALLOWED_ESTABLISHMENT.GET}/${user_id}`,
        })
      );
    });
    yield put(getAllowedEstablishmentSuccess(response));
  } catch (error) {
    yield put(getAllowedEstablishmentFail(error));
  }
}

function* onUpdateAllowedEstablishment(payload: any) {
  try {
    const { user_id } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.ALLOWED_ESTABLISHMENT.UPDATE}/${user_id}`,
        })
      );
    });
    yield put(updateAllowedEstablishmentSuccess(response));
  } catch (error) {
    yield put(updateAllowedEstablishmentFail(error));
  }
}

function* AdministratorSaga() {
  yield takeLatest(GET_PROVINCE, onGetProvincesListData);
  yield takeLatest(GET_CITY, onGetCities);
  yield takeLatest(GET_LOCALITY, onGetLocalities);
  yield takeLatest(GET_ROLES_LIST, onGetRolesList);
  yield takeLatest(GET_ESTABLISHMENTS, onGetEstablishmentsList);
  yield takeLatest(GET_ADMIN_DATA, onGetAdminData);
  yield takeLatest(GET_LOTS, onGetLotsList);
  yield takeLatest(GET_ALLOWED_ESTABLISHMENT, onGetAllowedEstablishment);
  yield takeLatest(UPDATE_ALLOWED_ESTABLISHMENT, onUpdateAllowedEstablishment);
}

export default AdministratorSaga;
