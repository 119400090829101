import React, { useEffect, useState } from "react";
import { Image, ListGroup } from "react-bootstrap";

import DeviceIconOn from "../../../assets/icons/svg/DashboardPage/ico-device/deviceIconOn.svg";
import DeviceIconOff from "../../../assets/icons/svg/DashboardPage/ico-device/deviceIconOff.svg";
import MeteorologicIcon from "../../../assets/icons/svg/DashboardPage/ico-device/meteorologicIcon.svg";
import ForwardArrow from "../../../assets/icons/svg/DashboardPage/chevron-right.svg";

import { CardComponent } from "../../card";
import { Navigate, useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";

const DeviceCardComponent = (props: any) => {
  const { dashboardData, marginTop } = props;
  const navigate = useNavigate();

  const deviceData = [
    {
      icon: DeviceIconOn,
      count: dashboardData && dashboardData.length,
      name: "Sensor lanza silo bolsa",
      color: "light-yellow",
      withArrow: true,
    },
    {
      icon: MeteorologicIcon,
      count: 0,
      color: "light-violet",
      name: "Estación meteorológica básica",
    },
    {
      icon: DeviceIconOff,
      count: 0,
      color: "light-gray",
      name: "Silo bolsa seguro",
    },
    {
      icon: DeviceIconOff,
      count: 0,
      color: "light-gray",
      name: "Pager",
    },
    {
      icon: DeviceIconOff,
      count: 0,
      color: "light-gray",
      name: "Sensor suelo",
    },
    {
      icon: DeviceIconOff,
      count: 0,
      color: "light-gray",
      name: "Candado",
    },
    {
      icon: DeviceIconOff,
      count: 0,
      color: "light-gray",
      name: "Estación meteorológica LoRAIN",
    },
  ];

  return (
    <>
      {dashboardData && (
        <CardComponent
          dashboard
          minHeight={dashboardData.length > 7 ? 337 : "auto"}
          marginTop={marginTop}
          title={`Tus dispositivos`}
        >
          <ListGroup>
            {deviceData.map((e: any, index: number) => {
              return (
                <ListGroup.Item
                  key={`device-${index}`}
                  className="d-flex justify-content-between"
                >
                  <div>
                    <Image className="deviceIcon" src={e.icon} />
                    <span className={`cardCustomCount ${e.color}`}>
                      {e.count}
                    </span>
                    <span className={"cardCustomText cardCustomDeviceText"}>
                      {e.name}
                    </span>
                  </div>
                  {e.withArrow && (
                    <div>
                      <Image
                        style={{
                          width: "8.31px",
                          height: "15.63px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(Paths.ESTABLISHMENTS_MAPS)}
                        src={ForwardArrow}
                      />{" "}
                    </div>
                  )}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </CardComponent>
      )}
    </>
  );
};

export default DeviceCardComponent;
