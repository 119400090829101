import { Endpoints } from "../../config/endpoints";
import {
  API_URL,
  API_URL_GET_TO_ID,
  API_WOZALABS_URL,
  LAST_ALERT,
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_PROFILE,
} from "../../config/general-config";
import { apiGet, apiPost } from "../../shared/ApiService";
import { asyncLocalStorage } from "../../shared/helper";
import { ApiRequest, ApiResponse } from "../../shared/types";

import * as _ from "lodash";
import { ApiResponseGeoToID, AuthenticateReponse } from "./Type";

const loginWithUsernameAndPassword = async (
  username: string,
  password: string
) => {
  const responseLogin: ApiResponseGeoToID = await genericGeoToIdRequest(
    Endpoints.LOGIN,
    { email: username, pass: password }
  );

  //Token de woza hardcode hasta que se actualice su servicio
  //const access_token = responseLogin.data.token;
  const access_token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiaW9mX3N1cGVyYWRtaW4iLCJzdWIiOiIxNTIiLCJlbWFpbCI6Im9pdmFuaUBpb2Zjb21wYW55LmNvbSIsImlzcyI6ImlvZiJ9.jTCfJzWTJY0lQlNeWq4ow2CiJ_riPD6K3ArRe7SZa1g`;

  await saveUserProfileToLocalStorage({ username: username });
  await saveTokenToLocalStorage(access_token);

  return {
    username,
    access_token,
  };
};

const genericGeoToIdRequest = async (
  method: string,
  body: any,
  token?: string
) => {
  if (token) {
    body.token = token;
  }
  const response = await apiPost({
    url: `${API_URL_GET_TO_ID}/${method}`,
    body: body,
    unauthorizedCallback: () => {
      unauthorizedCallback();
    },
  });

  if (response.code === 403 || response.message) {
    throw new Error(response.message);
  }

  return response;
};

const getProfileUser = async (username: any) => {
  const response = await apiGet({
    url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.USERS.GET_USER_BY_USERNAME}${username}`,
    unauthorizedCallback: () => {
      unauthorizedCallback();
    },
  });
  await saveUserProfileToLocalStorage(response && response[0]);
  return response[0];
};

const resetUser = async (username: string) => {
  const response: ApiResponse = await genericRequest(
    "v1/users",
    Endpoints.RESET,
    username,
    7
  );
  return response.message;
};

const genericRequest = async (
  mod: string,
  fun: string,
  args: any,
  sequence: number,
  token?: string
) => {
  const bodyProfile: ApiRequest = {
    mod: mod,
    fun: fun,
    args: args,
    config: {},
    sequence: sequence,
  };

  if (token) {
    bodyProfile.token = token;
  }

  const response = await apiPost({
    url: API_URL,
    body: bodyProfile,
    unauthorizedCallback: () => {
      unauthorizedCallback();
    },
  });

  if (response.code === 5001 || response.error) {
    throw new Error(response.message);
  }

  return response;
};

const logout = async () => {
  await asyncLocalStorage.removeItem(LOCAL_STORAGE_JWT);
  await asyncLocalStorage.removeItem(LOCAL_STORAGE_PROFILE);
  window.location.reload();
};

const getCurrentUser = () => {
  const jsonProfile = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  if (!jsonProfile) {
    return undefined;
  }
  const user: AuthenticateReponse = JSON.parse(
    jsonProfile
  ) as AuthenticateReponse;
  return user.fullname;
};

const getCurrentAlert = () => {
  return localStorage.getItem(LAST_ALERT) || undefined;
};

const getCurrentProfileUser = () => {
  return localStorage.getItem(LOCAL_STORAGE_PROFILE) || undefined;
};

const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_JWT) || undefined;
};

const isLoggedIn = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_JWT);
  const user = localStorage.getItem(LOCAL_STORAGE_PROFILE);

  return !!token && !!user;
};

const saveUserProfileToLocalStorage = async (profile: any) => {
  await asyncLocalStorage.setItem(
    LOCAL_STORAGE_PROFILE,
    JSON.stringify(profile)
  );
};

const saveUserLastAlarmNotification = async (alertId: any) => {
  const alertIdFromLocalStorage = getCurrentAlert();
  if (alertIdFromLocalStorage !== alertId) {
    await asyncLocalStorage.setItem(LAST_ALERT, alertId.toString());
  }
};

const saveTokenToLocalStorage = async (token: string) => {
  await asyncLocalStorage.setItem(LOCAL_STORAGE_JWT, token);
};

const unauthorizedCallback = async () => {
  logout();
};

export {
  getToken,
  getCurrentUser,
  getCurrentAlert,
  getCurrentProfileUser,
  saveUserProfileToLocalStorage,
  saveUserLastAlarmNotification,
  isLoggedIn,
  logout,
  getProfileUser,
  loginWithUsernameAndPassword,
  unauthorizedCallback,
  resetUser,
};
