import compareDesc from "date-fns/compareDesc";
import React, { useEffect, useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NotIconOn from "../../../assets/icons/DashboardPage/notificationIconOn.png";
import NotIconOff from "../../../assets/icons/svg/DashboardPage/ico-alerts.svg";
import {
  getCurrentAlert,
  saveUserLastAlarmNotification,
} from "../../../core/auth/AuthService";
import { Alert, ReduxType } from "../../../shared/types";
import {
  getAlertsDataAction,
  setAlertsDisplay,
} from "../../../store/alerts/actions";
import { AlertCardsComponent } from "../alertCards";

const AlertsDrawerComponent = (props: any) => {
  const [displayAlerts, setDisplayAlerts] = useState<Alert[][]>([]);
  const dispatch = useDispatch();

  const { style } = props;

  const {
    orgData,
    alertsData: { alerts, display },
  } = useSelector((state: ReduxType) => {
    return state;
  });

  const handleClose = () => dispatch(setAlertsDisplay(false));

  useEffect(() => {
    if (orgData && orgData.currentOrganization) {
      doAlertsRequest();
    }
  }, [orgData.currentOrganization]);

  const groupAlertsAndSort = (alerts: Alert[]): Alert[][] => {
    const alertsBySilos = alerts.reduce((group: any, alert) => {
      const { ID_SILO } = alert;
      group[ID_SILO] = group[ID_SILO] ?? [];
      group[ID_SILO].push(alert);
      return group;
    }, {});

    for (const siloAlerts in alertsBySilos) {
      alertsBySilos[siloAlerts].sort(
        (a: Alert, b: Alert) => b.ALERTA_NUMERO - a.ALERTA_NUMERO
      );
    }

    const groupedAlerts = Object.values(alertsBySilos) as Alert[][];
    groupedAlerts.sort((a: Alert[], b: Alert[]) =>
      compareDesc(a[0].FECHA, b[0].FECHA)
    );

    return groupedAlerts;
  };

  const doAlertsRequest = async () => {
    await dispatch(
      getAlertsDataAction({
        user_id: orgData.currentOrganization.user_id,
        org_id: orgData.currentOrganization.id,
      })
    );
  };

  useEffect(() => {
    if (alerts && alerts.length > 0) {
      const sorted = groupAlertsAndSort(alerts);
      setDisplayAlerts(sorted);
    }
  }, [alerts]);

  const verifyAlertIcon = () => {
    if (displayAlerts && displayAlerts[0] && displayAlerts[0][0]) {
      const firstAlert = displayAlerts[0][0].ID_ALERTA;
      if (getCurrentAlert() !== firstAlert.toString()) {
        return NotIconOn;
      }
    }
    return NotIconOff;
  };
  return (
    <>
      <Nav.Link
        style={style ? { ...style } : {}}
        onClick={() => {
          if (displayAlerts && displayAlerts[0] && displayAlerts[0][0]) {
            saveUserLastAlarmNotification(displayAlerts[0][0].ID_ALERTA);
          }
          dispatch(setAlertsDisplay(true));
        }}
      >
        <img
          src={verifyAlertIcon()}
          className="d-inline-block align-top dashboard-alarm"
          alt="React Bootstrap logo"
        />
      </Nav.Link>
      <Offcanvas
        show={display}
        onHide={handleClose}
        placement="end"
        backdropClassName={"offcanvas-backdrop-custom"}
        className={"dashboard-canvas-alerts"}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className={`fw-bold-600`}>Alertas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-1">
          <div className="overflow-hidden">
            {displayAlerts.map((alerts: Alert[], i: number) => (
              <AlertCardsComponent alerts={alerts} key={i} />
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AlertsDrawerComponent;
