import React, { useEffect, useState } from "react";
import compareDesc from "date-fns/compareDesc";
import { Alert } from "../../../shared/types";
import { AlertCardsComponent } from "../../alerts/alertCards";
import CloseIcon from "../../../assets/icons/svg/EstablishmentsMapPage/close-icon.svg";

const SiloAlerts = (props: any) => {
  const { isAlertsOpen, setIsAlertsOpen, establishment, alertsData } = props;
  const [dashboardRef, setDashboardRef] = useState(
    document.getElementById("map-dashboard")
  );
  const [alertsList, setAlertsList] = useState<any[]>([]);

  const groupAlertsAndSort = (alerts: Alert[]): Alert[][] => {
    const alertsBySilos = alerts.reduce((group: any, alert) => {
      const { ID_SILO } = alert;
      group[ID_SILO] = group[ID_SILO] ?? [];
      group[ID_SILO].push(alert);
      return group;
    }, {});

    for (const siloAlerts in alertsBySilos) {
      alertsBySilos[siloAlerts].sort(
        (a: Alert, b: Alert) => b.ALERTA_NUMERO - a.ALERTA_NUMERO
      );
    }

    const groupedAlerts = Object.values(alertsBySilos) as Alert[][];
    groupedAlerts.sort((a: Alert[], b: Alert[]) =>
      compareDesc(a[0].FECHA, b[0].FECHA)
    );

    return groupedAlerts;
  };

  useEffect(() => {
    if (establishment && alertsData) {
      const filtered = alertsData.filter((value: any) => {
        return value.ID_SILO === establishment.ELEMENTO_ID;
      });
      const sorted = groupAlertsAndSort(filtered);
      setAlertsList(sorted);
    }
  }, [isAlertsOpen, establishment, alertsData]);

  useEffect(() => {
    if (document.getElementById("map-dashboard")) {
      setDashboardRef(document.getElementById("map-dashboard"));
    }
  }, [props]);

  return (
    <>
      {alertsList && alertsList.length > 0 && (
        <section
          style={{
            height: dashboardRef
              ? `calc(100% - (${dashboardRef.clientHeight}px + 55px))`
              : "80%",
          }}
          className="d-flex silo-alerts-container"
        >
          <div
            style={{ display: "inline-flex" }}
            className="justify-between align-center"
          >
            <h5>Alertas {establishment.ELEMENTO_NOMBRE}</h5>
          </div>
          <div
            style={{
              overflowY: "auto",
              height: "100%",
              minWidth: 308,
              overflowX: "hidden",
            }}
          >
            {alertsList.map((alerts: Alert[], i: number) => (
              <AlertCardsComponent alerts={alerts} key={i} />
            ))}
          </div>
        </section>
      )}
    </>
  );
};

export default SiloAlerts;
