import React from "react";
import { Col, Image, Row, Stack } from "react-bootstrap";
import EstablishmentIcon from "../../../assets/icons/svg/DashboardPage/ico-estab.svg";
import {
  MonitoredTonsCrop,
  MonitoredTonsEstablishment,
} from "../../../shared/types";
import { CropIconComponent } from "../cropIcon";

interface MainCardTonsDetailProps {
  crops: MonitoredTonsCrop[];
  establishments: MonitoredTonsEstablishment[];
}

const MainCardTonsDetail = (props: MainCardTonsDetailProps) => {
  const { crops, establishments } = props;

  return (
    <Row>
      <Col xs="12" md="4">
        <Stack>
          <div className="fw-bold-500">Toneladas por especie:</div>
          <Row className="mt-1">
            {crops &&
              crops.length > 0 &&
              crops.map((crop, i) => (
                <Col className="d-flex fs-09 mt-2" key={`crop-${i}`}>
                  <div className="h-100 d-flex justify-content-center align-items-center">
                    <CropIconComponent
                      style={{
                        width: 30,
                        height: 30,
                      }}
                      crop={crop.ELEMENTO_PRODUCTO}
                    />
                  </div>
                  <Stack className="ms-2">
                    <div className="fw-bold-600">{crop.ELEMENTO_PRODUCTO}</div>
                    <div>
                      <span className="fw-bold-600">{crop.PESO}</span>&nbsp;
                      <span>Toneladas</span>
                    </div>
                  </Stack>
                </Col>
              ))}
          </Row>
        </Stack>
      </Col>
      <Col xs="1" className="d-none d-sm-flex justify-content-center">
        <div className="vr"></div>
      </Col>
      <Col className="mt-4 mt-md-0">
        <Stack>
          <div className="fw-bold-500">Toneladas por establecimiento:</div>
          <Row className="mt-1">
            {establishments &&
              establishments.length > 0 &&
              establishments.map((establishment, i) => (
                <Col className="d-flex fs-09 mt-2" key={`establishment-${i}`}>
                  <div className="h-100 d-flex justify-content-center align-items-center">
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                      }}
                      src={EstablishmentIcon}
                    />
                  </div>
                  <Stack className="ms-2">
                    <div className="fw-bold-600">
                      {establishment.CAMPO_NOMBRE}
                    </div>
                    <div>
                      <span className="fw-bold-600">{establishment.PESO}</span>
                      &nbsp;
                      <span>Toneladas</span>
                    </div>
                  </Stack>
                </Col>
              ))}
          </Row>
        </Stack>
      </Col>
    </Row>
  );
};

export default MainCardTonsDetail;
