import { combineReducers } from "redux";
import AdministratorReducer from "./administrator/reducer";
import AlertsReducer from "./alerts/reducer";
import DashboardReducer from "./dashboard/reducer";
import EstablishmentsMapReducer from "./establishmentsMap/reducer";
import OrganizationReducer from "./organization/reducer";
import SiloBagReducer from "./silobag/reducer";
import UserReducer from "./users/reducer";

const rootReducer = combineReducers({
  dashboardData: DashboardReducer,
  orgData: OrganizationReducer,
  userData: UserReducer,
  establishmentsMapData: EstablishmentsMapReducer,
  alertsData: AlertsReducer,
  siloBagData: SiloBagReducer,
  administratorData: AdministratorReducer,
});

export default rootReducer;
