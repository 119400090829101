import React from "react";
import CloseIcon from "../../../../assets/icons/svg/EstablishmentsMapPage/close-icon.svg";

const CloseButton = (props: any) => {
  const { onClick } = props;
  return (
    <div className="close-button-container">
      <button className="zoom-button" onClick={onClick}>
        <img src={CloseIcon} alt="close-icon" />
      </button>
    </div>
  );
};

export default CloseButton;
