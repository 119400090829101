import React, { useEffect, useState } from "react";
import { Image, ListGroup } from "react-bootstrap";

import EstablishmentIcon from "../../../assets/icons/svg/DashboardPage/ico-estab.svg";
import ForwardArrow from "../../../assets/icons/svg/DashboardPage/chevron-right.svg";
import { DashboardEstablishmentStatsProps } from "../../../shared/types";

import { CardComponent } from "../../card";

const EstablishmentCardComponent = (props: any) => {
  const { dashboardData, marginTop } = props;

  const goToEstablishment = (elements: DashboardEstablishmentStatsProps) => {};

  return (
    <>
      {dashboardData && (
        <CardComponent
          showInMap
          dashboard
          minHeight={dashboardData.length > 7 ? 337 : "auto"}
          marginTop={marginTop}
          title={`Tus establecimientos`}
          titleCount={dashboardData.length}
        >
          <ListGroup>
            {dashboardData.map(
              (elements: DashboardEstablishmentStatsProps, index: number) => {
                return (
                  <ListGroup.Item
                    key={`establishment-${index}`}
                    className="d-flex justify-content-between"
                    action
                    onClick={() => goToEstablishment(elements)}
                  >
                    <div>
                      <Image
                        style={{
                          width: 29,
                          height: 29,
                        }}
                        src={EstablishmentIcon}
                      />
                      <span className={"cardCustomText"}>
                        {elements.LOTE_NOMBRE}
                      </span>
                    </div>
                    <div>
                      <Image
                        style={{
                          width: "8.31px",
                          height: "15.63px",
                        }}
                        src={ForwardArrow}
                      />{" "}
                    </div>
                  </ListGroup.Item>
                );
              }
            )}
          </ListGroup>
        </CardComponent>
      )}
    </>
  );
};

export default EstablishmentCardComponent;
