import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Toast from "react-bootstrap/Toast";
import { ToastProps } from "../../shared/types";
import { ProgressBar } from "react-bootstrap";

const ToastComponent = (props: ToastProps) => {
  const { bodyContent, errorType, showToast, title, toggleShow } = props;

  const [value, updateValue] = useState(100);
  const [localShow, setLocalShow] = useState<boolean>(false);

  useEffect(() => {
    if (localShow) {
      const interval = setInterval(() => {
        updateValue((oldValue) => {
          const newValue = oldValue - 2;
          if (newValue === 0) {
            clearInterval(interval);
          }
          return newValue;
        });
      }, 200);
    }
  }, [localShow]);

  useEffect(() => {
    if (value == 0) {
      resetAndClose();
    }
  }, [value]);

  useEffect(() => {
    if (showToast) {
      setLocalShow(showToast);
    }
  }, [showToast]);

  const resetAndClose = async () => {
    await toggleShow();
    await setLocalShow(false);
    await updateValue(100);
  };

  return (
    <Toast
      className={"toastCustomComponent"}
      show={showToast}
      onClose={toggleShow}
      bg={errorType ? "danger" : "success"}
    >
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body className="toastCustomText">{bodyContent}</Toast.Body>
      <ProgressBar variant={errorType ? "danger" : "success"} now={value} />
    </Toast>
  );
};

export default ToastComponent;
