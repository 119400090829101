import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { InputSelectProps } from "../../../shared/types";

const InputSelectComponent = (props: InputSelectProps) => {
  const {
    options,
    errorMessage,
    formName,
    placeholder,
    required,
    value,
    onChange,
  } = props;

  return (
    <>
      <InputGroup hasValidation>
        <Form.Select
          required={required}
          onChange={(value) => onChange(formName, value.target.value)}
          aria-label={"Default select establishment"}
          value={value}
          style={{ fontSize: 14 }}
          className={"silo-bag-form-input"}
        >
          <option value={undefined}>{`${placeholder} ${
            required ? `*` : ``
          }`}</option>
          {options &&
            options.map((op: any, index: number) => {
              return (
                <option key={`op-${index}`} value={op.id}>
                  {op.nombre}
                </option>
              );
            })}
        </Form.Select>
      </InputGroup>
    </>
  );
};

export default InputSelectComponent;
