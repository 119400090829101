import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_ALL_AVAILABLE_DEVICE_ACTION,
  GET_ESTABLISHMENT,
  GET_HARVEST,
  GET_LOT,
  GET_PRODUCTS,
  GET_SILOBAG,
  GET_SPECIES,
} from "./actionTypes";

import { ApiResponse } from "../../shared/types";
import { apiGet } from "../../shared/ApiService";
import { API_URL_BACKOFFICE } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";

import {
  getAllAvailableDeviceForOrganizationFail,
  getAllAvailableDeviceForOrganizationSuccess,
  getAllEstablishmentFail,
  getAllEstablishmentSuccess,
  getAllHarvestFail,
  getAllHarvestSuccess,
  getAllLoteFail,
  getAllLoteSuccess,
  getAllProductsFail,
  getAllProductsSuccess,
  getAllSiloBagFail,
  getAllSiloBagSuccess,
  getAllSpeciesFail,
  getAllSpeciesSuccess,
} from "./actions";

function* onGetEstablishments(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.ESTABLISHMENT.GET}`,
        })
      );
    });
    yield put(getAllEstablishmentSuccess(response));
  } catch (error: any) {
    yield put(getAllEstablishmentFail(error.response));
  }
}

function* onGetLots(payload: any) {
  try {
    const { establishmentId } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.LOTE.GET_LOTS_BY_STABLISHMENT}/${establishmentId}`,
        })
      );
    });
    yield put(getAllLoteSuccess(response));
  } catch (error: any) {
    yield put(getAllLoteFail(error.response));
  }
}

function* onGetHarvests(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.HARVEST.GET}`,
        })
      );
    });
    yield put(getAllHarvestSuccess(response));
  } catch (error: any) {
    yield put(getAllHarvestFail(error.response));
  }
}

function* onGetProducts(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.SILO_PRODUCT.GET}`,
        })
      );
    });
    yield put(getAllProductsSuccess(response));
  } catch (error: any) {
    yield put(getAllProductsFail(error.response));
  }
}

function* onGetSpecies(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.SILO_SPECIES.GET}`,
        })
      );
    });
    yield put(getAllSpeciesSuccess(response));
  } catch (error: any) {
    yield put(getAllSpeciesFail(error.response));
  }
}

function* onGetSilobags(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.SILO.GET}`,
        })
      );
    });
    yield put(getAllSiloBagSuccess(response));
  } catch (error: any) {
    yield put(getAllSiloBagFail(error.response));
  }
}

function* onGetAvailableDevices(payload: any) {
  try {
    const { orgId } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.ORGANIZATION.GET_AVAILABLE_DEVICE}/${orgId}`,
        })
      );
    });
    yield put(getAllAvailableDeviceForOrganizationSuccess(response));
  } catch (error: any) {
    yield put(getAllAvailableDeviceForOrganizationFail(error.response));
  }
}

function* SiloBagSaga() {
  yield takeLatest(GET_ALL_AVAILABLE_DEVICE_ACTION, onGetAvailableDevices);
  yield takeLatest(GET_ESTABLISHMENT, onGetEstablishments);
  yield takeLatest(GET_LOT, onGetLots);
  yield takeLatest(GET_HARVEST, onGetHarvests);
  yield takeLatest(GET_PRODUCTS, onGetProducts);
  yield takeLatest(GET_SPECIES, onGetSpecies);
  yield takeLatest(GET_SILOBAG, onGetSilobags);
}

export default SiloBagSaga;
