import React, { useEffect, useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";

import { ReduxType } from "../../../../shared/types";
import { CardComponent } from "../../../card";
import { InputNumberComponent, InputSelectComponent } from "../../../inputs";

import { useDispatch, useSelector } from "react-redux";
import { getAllAvailableDeviceForOrganizationAction } from "../../../../store/silobag/actions";

import { cloneWithoutReference, findIndex } from "../../../../shared/helper";
import { CustomSelect } from "../../../form/CustomSelect";
import { CustomTextField } from "../../../form/CustomTextField";

const SiloBagDeviceFormComponent = (props: any) => {
  const [validated, setValidated] = useState(false);

  const {
    key,
    index,
    width,
    formDeviceValues,
    removeDeviceElement,
    setFormDeviceValues,
    isEditMode,
    setErrorType,
    setErrorMessage,
    setShowToast,
  } = props;

  const dispatch = useDispatch();

  const { siloBagData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    getAllDevices();
  }, []);

  const getAllDevices = async () => {
    await dispatch(getAllAvailableDeviceForOrganizationAction(1));
  };

  const onChange = (formName: string, value: string, index: number) => {
    let d = cloneWithoutReference(formDeviceValues);
    if (d && d[index]) {
      d[index][formName] = value;
    } else {
      if (formName == "dispositivoId") {
        if (d && d.length > 0) {
          const i = findIndex(d, "dispositivoId", value);
          if (i == -1) {
            d[i] = { [formName]: value };
          } else {
            setErrorType(true);
            setShowToast(true);
            setErrorMessage("El dispositivo ya ha sido seleccionado.");
          }
        } else {
          d[index] = { [formName]: value };
        }
      }
    }
    setFormDeviceValues(d);
  };

  const devicesFormData =
    formDeviceValues &&
    formDeviceValues.length > 0 &&
    formDeviceValues[0].imagenes &&
    formDeviceValues[0].imagenes.length > 0
      ? formDeviceValues[0].imagenes.map((img: any, index: number) => {
          return {
            title: `Imagen ${index + 1}`,
            image:
              img.imagen == null || img.imagen == "" ? (
                "Sin imágen cargada"
              ) : (
                <Image style={{ width: 261, height: 128 }} src={img.imagen} />
              ),
            inputs: [
              {
                placeholder: "Latitud",
                name: "latitud",
                value: img.latitud,
              },
              {
                placeholder: "Longitud",
                name: "longitud",
                value: img.longitud,
              },
            ],
          };
        })
      : [
          {
            title: "Imagen 1",
            image: "Sin imágen cargada",
            inputs: [
              {
                placeholder: "Latitud",
                name: "latitud",
                value:
                  formDeviceValues &&
                  formDeviceValues[index] &&
                  formDeviceValues[index].latitud,
              },
              {
                placeholder: "Longitud",
                name: "longitud",
                value:
                  formDeviceValues &&
                  formDeviceValues[index] &&
                  formDeviceValues[index].longitud,
              },
            ],
          },
          {
            title: "Imagen 2",
            image: "Sin imágen cargada",
            inputs: [
              {
                disabled: true,
                name: "latitud",
                placeholder: "Latitud",
              },
              {
                disabled: true,
                name: "longitud",
                placeholder: "Longitud",
              },
            ],
          },
        ];
  return (
    <CardComponent
      dashboard
      withDelete
      marginTop={"0"}
      title={`Lanza ${index + 1}`}
      deleteAction={() => removeDeviceElement(index)}
    >
      <Form noValidate validated={validated}>
        <Row>
          <Form.Group as={Col} md="12" controlId="dispositivoId">
            {!isEditMode ? (
              <CustomTextField
                id="dispositivoId"
                name="dispositivoId"
                placeholder="N° lanza / Nombre"
                defaultValue={
                  formDeviceValues &&
                  formDeviceValues[index] &&
                  formDeviceValues[index].dispositivoName
                }
                disabled={true}
              />
            ) : (
              <CustomSelect
                id="dispositivoId"
                name="dispositivoId"
                placeholder="N° lanza / Nombre"
                onChange={(e) => onChange(e.name, e.value.target.value, index)}
                defaultValue={
                  formDeviceValues &&
                  formDeviceValues[index] &&
                  formDeviceValues[index].dispositivoId
                }
                values={
                  siloBagData &&
                  siloBagData.availableDevices &&
                  siloBagData.availableDevices.dispositivos
                }
              />
            )}
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un establecimiento.
            </Form.Control.Feedback>
          </Form.Group>

          {devicesFormData &&
            devicesFormData.map((df: any) => {
              return (
                <>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="mt-4"
                    controlId="image"
                  >
                    <Form.Label className={"silo-bag-image-title fw-bold-500"}>
                      {df.title}
                    </Form.Label>
                    <div className={"silo-bag-no-image fw-bold-400 mt-2"}>
                      {df.image}
                    </div>
                  </Form.Group>
                  {df.inputs &&
                    df.inputs.map((di: any) => {
                      return (
                        <Form.Group
                          as={Col}
                          md="6"
                          className="mt-4"
                          controlId={di.name}
                        >
                          <InputNumberComponent
                            disabled={di.disabled}
                            step={"0.1"}
                            formName={di.name}
                            placeholder={di.placeholder}
                            onChange={(formName, value) =>
                              onChange(formName, value, index)
                            }
                            value={di.value}
                            errorMessage={`Por favor, ingrese una ${di.name}.`}
                          />
                        </Form.Group>
                      );
                    })}
                </>
              );
            })}
          {/*
          <Form.Group as={Col} md="12" className="mt-4" controlId="image">
            <Form.Label className={"silo-bag-image-title fw-bold-500"}>
              {`Imagen ${index + 1}`}
            </Form.Label>
            <div className={"silo-bag-no-image fw-bold-400 mt-2"}>
              Sin imágen cargada
            </div>
          </Form.Group>
          <Form.Group as={Col} md="6" className="mt-4" controlId="latitud">
            <InputNumberComponent
              step={"0.1"}
              formName={"latitud"}
              placeholder={"Latitud"}
              onChange={(formName, value) => onChange(formName, value, index)}
              value={
                formDeviceValues &&
                formDeviceValues[index] &&
                formDeviceValues[index].latitud
              }
              errorMessage={"Por favor, ingrese una latitud."}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" className="mt-4" controlId="longitud">
            <InputNumberComponent
              step={"0.1"}
              formName={"longitud"}
              placeholder={"Longitud"}
              onChange={(formName, value) => onChange(formName, value, index)}
              value={
                formDeviceValues &&
                formDeviceValues[index] &&
                formDeviceValues[index].longitud
              }
              errorMessage={"Por favor, ingrese una longitud."}
            />
          </Form.Group>

          <Form.Group as={Col} md="12" className="mt-4" controlId="image">
            <Form.Label className={"silo-bag-image-title fw-bold-500"}>
              {`Imagen 2`}
            </Form.Label>
            <div className={"silo-bag-no-image fw-bold-400 mt-2"}>
              Sin imágen cargada
            </div>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="latitud">
            <CustomTextField
              id="latitud"
              name={"latitud"}
              placeholder={"Latitud"}
              disabled={true}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="longitud">
            <CustomTextField
              id="longitud"
              name={"longitud"}
              placeholder={"Longitud"}
              disabled={true}
            />
          </Form.Group> */}
        </Row>
      </Form>
    </CardComponent>
  );
};

export default SiloBagDeviceFormComponent;
