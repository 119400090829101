import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";
import { CustomTable } from "../../administrator/CustomTable";
import { CardComponent } from "../../card";
import { columns } from "../constants/Constants";

const SiloBagListComponent = (props: any) => {
  const { silobags } = props;

  const navigate = useNavigate();

  const onActionClick = async (
    action: "VIEW" | "EDIT" | "CREATE" | "MAP" | "DELETE",
    values: any
  ) => {
    switch (action) {
      case "EDIT":
        navigate(Paths.NEW_SILO, {
          state: {
            from: Paths.SILO,
            createMode: false,
            editionMode: true,
            record: values,
          },
        });
        break;
      case "CREATE":
        break;
      case "MAP":
        break;
      case "VIEW":
        navigate(Paths.VIEW_SILO, {
          state: {
            from: Paths.SILO,
            record: values,
          },
        });
        break;
    }
  };

  const handleActionClick = (
    action: "EDIT" | "VIEW" | "CREATE" | "MAP" | "DELETE",
    record: any
  ) => {
    onActionClick && onActionClick(action, record);
  };

  return (
    <CardComponent
      title
      dashboard
      bodyClassName="table-padding-top-0"
      minHeight={676}
      marginTop={"0"}
      style={{ width: "100%" }}
    >
      <CustomTable
        columns={columns}
        values={silobags}
        actions={{ edit: true, view: true, map: true, delete: false }}
        onActionClick={handleActionClick}
        pagination
      />
    </CardComponent>
  );
};

export default SiloBagListComponent;
