import * as types from "./actionTypes";

const initialState = {
  alerts: [],
  display: false,
  loading: false,
  error: {
    message: "",
  },
};

const AlertsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case types.GET_ALERTS_DATA:
      state = { 
        ...state, 
        loading: true,
      };
      break;

    case types.GET_ALERTS_DATA_SUCCESS:
      state = { 
        ...state,
        loading: false,
        alerts: actionPayload
      };
      break;

    case types.GET_ALERTS_DATA_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;

    case types.SET_ALERTS_DISPLAY:
      state = {
        ...state,
        display: actionPayload
      };
      break;
  }

  return state;
};

export default AlertsReducer;
