import React, { useEffect, useState } from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  DeviceCardComponent,
  EstablishmentCardComponent,
  MainCardComponent,
  SilobagCardComponent,
} from "../../components/dashboard";
import { Paths } from "../../config/paths";
import { AuthenticateReponse } from "../../core/auth/Type";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { ReduxType } from "../../shared/types";
import { getDashboardDataAction } from "../../store/dashboard/actions";
import { useNavigate } from "react-router-dom";
import { getAdminDataAction } from "../../store/administrator/actions";

const DashboardPage = () => {
  const [currentUser, setCurrentUser] = useState<AuthenticateReponse>();
  const [width, setWidth] = useState<number>(window.innerWidth);

  const { administratorData, dashboardData, userData, orgData } = useSelector(
    (state: ReduxType) => {
      return state;
    }
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (userData.userProfile) {
      const user =
        userData &&
        userData.userProfile &&
        typeof userData.userProfile === "string"
          ? JSON.parse(userData.userProfile)
          : userData.userProfile;
      setCurrentUser(user);
    }
  }, [userData.userProfile]);

  useEffect(() => {
    if (orgData && orgData.currentOrganization) {
      doDashboardRequest();
    }
  }, [orgData.currentOrganization]);

  useEffect(() => {
    getAdminDataRequest();
  }, []);

  const getAdminDataRequest = async () => {
    await dispatch(getAdminDataAction(1));
  };

  const doDashboardRequest = async () => {
    await dispatch(
      getDashboardDataAction({
        user_id: orgData.currentOrganization.user_id,
        org_id: orgData.currentOrganization.id,
      })
    );
  };

  const verifyLoading = () => {
    return userData.loading || orgData.loading || dashboardData.loading;
  };

  const dashboardMainTitles = (currentUser: AuthenticateReponse) => {
    const resState = width <= 768;
    const orgClassName = `dashboard-organization-name ${
      resState ? "mt-3" : "mt-1"
    }`;
    return (
      <Col xs="auto" className="mb-4 mb-xl-0">
        <Stack className="flex-column-reverse flex-sm-column">
          <Row className={"dashboardMainTitle"}>
            <Col xs={12} md="auto">
              Hola <b>{currentUser.fullname}</b>
            </Col>
            <Col xs={12} md="auto" className={orgClassName}>
              {orgData.currentOrganization &&
                orgData.currentOrganization.legal_name}
            </Col>
          </Row>
          <div className={"dashboardSubTitle"}>
            Bienvenido al campo inteligente y conectado
          </div>
        </Stack>
      </Col>
    );
  };

  return (
    <AuthenticatedRoute withHeader path={Paths.ROOT} loading={verifyLoading()}>
      {currentUser && (
        <>
          <Row className="justify-content-between">
            {dashboardMainTitles(currentUser)}
            <Col xs={width <= 768 ? 12 : "auto"}>
              <Stack
                direction="horizontal"
                className={width <= 768 ? "justify-content-between" : ""}
              >
                <div
                  className="me-2"
                  style={width <= 768 ? { width: "100%" } : {}}
                >
                  <Button
                    className="dashboardButtonInvert text-nowrap toooltip"
                    style={width <= 768 ? { width: "100%" } : {}}
                    onClick={() => {
                      navigate(Paths.NEW_SILO, {
                        state: {
                          createMode: true,
                          editionMode: true,
                          from: Paths.ROOT,
                        },
                      });
                    }}
                  >
                    Alta Silobolsa
                  </Button>
                </div>
                <div style={width <= 768 ? { width: "100%" } : {}}>
                  <Link to={Paths.ESTABLISHMENTS_MAPS}>
                    <Button
                      className={"dashboardButton"}
                      style={width <= 768 ? { width: "100%" } : {}}
                    >
                      Ver mapa
                    </Button>
                  </Link>
                </div>
              </Stack>
            </Col>
          </Row>
          {dashboardData && dashboardData.currentDashboardOrg && (
            <MainCardComponent
              silobags={dashboardData.currentDashboardOrg.siloCount}
              monitoredTons={dashboardData.currentDashboardOrg.siloCount}
              alerts={dashboardData.currentDashboardOrg.alertsCount}
              monitoredTonsCropData={
                dashboardData.currentDashboardOrg.monitored_tons_crop_data
              }
              monitoredTonsEstablishmentData={
                dashboardData.currentDashboardOrg
                  .monitored_tons_establishment_data
              }
              mobile={width <= 768}
            />
          )}

          <Row className="flex-column-reverse flex-md-row ">
            <Col md={5}>
              <EstablishmentCardComponent
                marginTop={"mt-3"}
                dashboardData={
                  dashboardData &&
                  dashboardData.currentDashboardOrg &&
                  dashboardData.currentDashboardOrg.establishment_data
                }
              />
              <DeviceCardComponent
                marginTop={"mt-3"}
                dashboardData={
                  administratorData && administratorData.deviceList
                }
              />
            </Col>
            <Col md={7} className={"dashboard-card-data"}>
              <SilobagCardComponent
                marginTop={"mt-3"}
                dashboardData={
                  dashboardData &&
                  dashboardData.currentDashboardOrg &&
                  dashboardData.currentDashboardOrg.silo_data
                }
              />
            </Col>
          </Row>
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default DashboardPage;
