import * as types from "./actionTypes";

export const getAllSiloBagAction = (orgId: number) => {
  return {
    type: types.GET_SILOBAG,
    orgId,
  };
};

export const getAllSiloBagFail = (error: any) => {
  return {
    type: types.GET_SILOBAG_FAIL,
    payload: error,
  };
};

export const getAllSiloBagSuccess = (establishment: any) => {
  return {
    type: types.GET_SILOBAG_SUCCESS,
    payload: establishment,
  };
};

export const getAllEstablishmentAction = () => {
  return {
    type: types.GET_ESTABLISHMENT,
  };
};

export const getAllEstablishmentFail = (error: any) => {
  return {
    type: types.GET_ESTABLISHMENT_FAIL,
    payload: error,
  };
};

export const getAllEstablishmentSuccess = (establishment: any) => {
  return {
    type: types.GET_ESTABLISHMENT_SUCCESS,
    payload: establishment,
  };
};

export const getAllLoteAction = (establishmentId: number) => {
  return {
    type: types.GET_LOT,
    establishmentId,
  };
};

export const getAllLoteFail = (error: any) => {
  return {
    type: types.GET_LOT_FAIL,
    payload: error,
  };
};

export const getAllLoteSuccess = (lot: any) => {
  return {
    type: types.GET_LOT_SUCCESS,
    payload: lot,
  };
};

export const getAllProductsAction = () => {
  return {
    type: types.GET_PRODUCTS,
  };
};

export const getAllProductsFail = (error: any) => {
  return {
    type: types.GET_PRODUCTS_FAIL,
    payload: error,
  };
};

export const getAllProductsSuccess = (prod: any) => {
  return {
    type: types.GET_PRODUCTS_SUCCESS,
    payload: prod,
  };
};

export const getAllSpeciesAction = () => {
  return {
    type: types.GET_SPECIES,
  };
};

export const getAllSpeciesFail = (error: any) => {
  return {
    type: types.GET_SPECIES_FAIL,
    payload: error,
  };
};

export const getAllSpeciesSuccess = (species: any) => {
  return {
    type: types.GET_SPECIES_SUCCESS,
    payload: species,
  };
};

export const getAllHarvestAction = () => {
  return {
    type: types.GET_HARVEST,
  };
};

export const getAllHarvestFail = (error: any) => {
  return {
    type: types.GET_HARVEST_FAIL,
    payload: error,
  };
};

export const getAllHarvestSuccess = (harvest: any) => {
  return {
    type: types.GET_HARVEST_SUCCESS,
    payload: harvest,
  };
};

export const getAllAvailableDeviceForOrganizationAction = (orgId: number) => {
  return {
    type: types.GET_ALL_AVAILABLE_DEVICE_ACTION,
    orgId,
  };
};

export const getAllAvailableDeviceForOrganizationFail = (error: any) => {
  return {
    type: types.GET_ALL_AVAILABLE_DEVICE_FAIL,
    payload: error,
  };
};

export const getAllAvailableDeviceForOrganizationSuccess = (
  establishment: any
) => {
  return {
    type: types.GET_ALL_AVAILABLE_DEVICE_SUCCESS,
    payload: establishment,
  };
};
