import React from "react";
import { Pagination, Table } from "react-bootstrap";
import { ActionButtons } from "../modal/ActionButtons";

export interface Props {
  columns: any[];
  onActionClick: (action: "VIEW" | "EDIT" | "DELETE", record: any) => void;
  pagination?: boolean;
  actions?: {
    edit: boolean;
    map?: boolean;
    view: boolean;
    delete: boolean;
    toggle?: { name: string };
  };
  withoutScroll?: boolean;
  values?: any[];
}

export const CustomTable = (props: Props) => {
  const { columns, values, onActionClick, actions, pagination, withoutScroll } =
    props;
  const [currentPage, setCurrentPage] = React.useState(0);

  const rowsPerPage = 10;
  const pages =
    values && values.length > 0 ? Math.ceil(values.length / rowsPerPage) : 0;

  const handlePageChange = (newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
  };

  const paginationItems = () => {
    if (!values || values.length === 0) return;
    const items = [];
    for (let i = 0; i < pages; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <div
      style={
        withoutScroll
          ? { width: "100%", height: "auto" }
          : { width: "100%", overflow: "auto", maxHeight: 500 }
      }
    >
      <Table className="custom-table" striped bordered hover size="sm">
        <thead style={{ background: "#FFFFFF", position: "sticky", top: 0 }}>
          <tr className="tr-header header">
            {columns &&
              columns.length > 0 &&
              columns.map((val, index) => <th key={val.id}>{val.label}</th>)}
          </tr>
        </thead>
        <tbody>
          {values &&
            values.length > 0 &&
            values
              .slice(
                currentPage * rowsPerPage,
                currentPage * rowsPerPage + rowsPerPage
              )
              .map((obj, index) => (
                <tr key={index}>
                  {columns &&
                    columns.map((val, idx) => {
                      if (val.id === "acciones") {
                        return (
                          <td key={idx}>
                            <ActionButtons
                              onClick={(action: any, value: any) => {
                                if (value !== undefined) {
                                  onActionClick(value, obj);
                                } else {
                                  onActionClick(action, obj);
                                }
                              }}
                              values={obj}
                              actions={actions}
                            />
                          </td>
                        );
                      }
                      if (val.render) {
                        return (
                          <td key={idx}>{val.render(obj[val.id], obj)}</td>
                        );
                      }
                      if (obj[val.id]) {
                        return <td key={idx}>{obj[val.id]}</td>;
                      } else {
                        return <td key={idx}>-</td>;
                      }
                    })}
                </tr>
              ))}
        </tbody>
      </Table>
      {pagination && <Pagination>{paginationItems()}</Pagination>}
    </div>
  );
};
