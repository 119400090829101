import * as _ from "lodash";
import moment from "moment";
import { roles } from "./types";

const asyncLocalStorage = {
  setItem: function (key: string, value: string) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  removeItem: function (key: string) {
    return Promise.resolve().then(function () {
      localStorage.removeItem(key);
    });
  },
};

const findIndex = (
  arrayElements: any[],
  key: string,
  match: string | number
) => {
  return _.findIndex(arrayElements, (i: any) => {
    return i[key] == match;
  });
};

const formatPermission = (rol: roles) => {
  switch (rol) {
    case roles.ADMIN:
      return "Administrador";
    case roles.EDITOR:
      return "Editor";
    case roles.OWNER:
      return "Dueño";
    case roles.READ_ONLY:
      return "Solo Lectura";
    case roles.REPORT_RECEIBABLES:
      return "Reportar cuentas por cobrar";
    case roles.ORGANIZATION_ADMIN:
      return "Administrador de la Organizacion";
    case roles.ORGANIZATION_COMMERCIAL:
      return rol;
    case roles.ORGANIZATION_COUNTRYSIDE:
      return rol;
    case roles.ORGANIZATION_GENERAL:
      return rol;
    case roles.ORGANIZATION_SUBZONE:
      return rol;
    case roles.ORGANIZATION_TECHNICAL:
      return rol;
    case roles.ORGANIZATION_ZONE:
      return rol;
    default:
      return rol;
  }
};

const alertColor = (alertColor: string) => {
  switch (alertColor) {
    case "yellow":
      return "#FFDD00";
    case "orange":
      return "#FD7E14";
    case "red":
      return "#FF0000";
    case "green":
      return "#6BAA00";
  }
  return "";
};

const getTextColor = (color: "orange" | "red" | "green" | "yellow" | null) =>
  color === "orange" || color === "green" || color === "red"
    ? "white"
    : "black";

const formatDate = (date: any) => {
  if (!date || date === "") return;
  return moment(date).format("DD/MM/YYYY");
};

const diffDates = (fechaArmado: any) => {
  let diffDays = 0;
  if (fechaArmado) {
    const date1 = moment(fechaArmado);
    const date2 = moment(new Date());
    diffDays = date2.diff(date1, "days");
  }
  return `${diffDays} días`;
};

const formatDatetime = (date: any) => {
  return moment(date).format("DD/MM/YYYY - HH:mm");
};

const formatDatetimeToSave = (date: any) => {
  return moment(date).format("YYYY/MM/DD HH:mm:ss");
};

const cloneWithoutReference = (elements: any) => {
  return JSON.parse(JSON.stringify(elements));
};

const stateSiloBagName: any = {
  1: "Datos incompletos",
  2: "Datos completos",
  3: "Certificable",
};

const stateSiloBagColorText: any = {
  1: "light-orange",
  2: "light-yellow",
  3: "light-green",
};

const stateSiloBagColorBackground: any = {
  1: "background-orange",
  2: "background-yellow",
  3: "background-green",
};

const findCity = (formValues: any, orgData: any, name: string) => {
  if (
    orgData.cities &&
    orgData.cities.length > 0 &&
    orgData.localities &&
    orgData.localities.length > 0
  ) {
    const firstIndex =
      name === "countrysides"
        ? findIndex(
            orgData.localities,
            "country_l2_id",
            formValues.country_l2_id
          )
        : findIndex(orgData.localities, "id", formValues.locality_id);
    if (firstIndex !== -1) {
      const secondIndex = findIndex(
        orgData.cities,
        "id",
        orgData.localities[firstIndex].municipality_id
      );
      if (secondIndex !== -1) {
        if (name === "countrysides") {
          formValues.locality_id = orgData.localities[firstIndex].id;
        }
        formValues.municipality_id = orgData.cities[secondIndex].id;
      }
    }
  }

  return formValues;
};

export {
  asyncLocalStorage,
  alertColor,
  stateSiloBagColorBackground,
  stateSiloBagColorText,
  stateSiloBagName,
  cloneWithoutReference,
  diffDates,
  findIndex,
  findCity,
  formatDate,
  formatDatetime,
  formatDatetimeToSave,
  formatPermission,
  getTextColor,
};
