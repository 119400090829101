import * as types from "./actionTypes";

export const clearEstablishmentDataAction = () => {
  return {
    type: types.CLEAR_DATA,
  };
};

export const getEstablishmentsMapDataAction = (payload: any) => {
  return {
    type: types.GET_ESTABLISHMENTS_MAP_DATA,
    payload,
  };
};

export const getEstablishmentsMapDataFail = (error: any) => {
  return {
    type: types.GET_ESTABLISHMENTS_MAP_DATA_FAIL,
    payload: error,
  };
};

export const getEstablishmentsMapDataSuccess = (establishmentsMapData: any) => {
  return {
    type: types.GET_ESTABLISHMENTS_MAP_DATA_SUCCESS,
    payload: establishmentsMapData,
  };
};

export const getEstablishmentFromSiloByLoteIdAction = (payload: any) => {
  return {
    type: types.GET_ESTABLISHMENT_FROM_SILO_BY_LOTE_ID,
    payload,
  };
};

export const getEstablishmentFromSiloByLoteIdFail = (error: any) => {
  return {
    type: types.GET_ESTABLISHMENT_FROM_SILO_BY_LOTE_ID_FAIL,
    payload: error,
  };
};

export const getEstablishmentFromSiloByLoteIdSuccess = (fromSilo: any) => {
  return {
    type: types.GET_ESTABLISHMENT_FROM_SILO_BY_LOTE_ID_SUCCESS,
    payload: fromSilo,
  };
};

export const getLanzasByLoteIdAction = (payload: any) => {
  return {
    type: types.GET_LANZAS_BY_LOTE_ID,
    payload,
  };
};

export const getLanzasByLoteIdFail = (error: any) => {
  return {
    type: types.GET_LANZAS_BY_LOTE_ID_FAIL,
    payload: error,
  };
};

export const getLanzasByLoteIdSuccess = (fromSilo: any) => {
  return {
    type: types.GET_LANZAS_BY_LOTE_ID_SUCCESS,
    payload: fromSilo,
  };
};
