import { UserLoginRequest } from "../../shared/types";
import * as types from "./actionTypes";

export const loginUserAction = (user: UserLoginRequest) => {
  return {
    type: types.LOGIN_USER,
    ...user,
  };
};

export const loginUserFail = (error: any) => {
  return {
    type: types.LOGIN_USER_FAIL,
    payload: error,
  };
};

export const loginUserSuccess = (user: any) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload: user,
  };
};

export const loginUserProfileAction = (username: any) => {
  return {
    type: types.LOGIN_PROFILE_USER,
    username,
  };
};

export const loginUserProfileFail = (error: any) => {
  return {
    type: types.LOGIN_PROFILE_FAIL,
    payload: error,
  };
};

export const loginUserProfileSuccess = (user: any) => {
  return {
    type: types.LOGIN_PROFILE_SUCCESS,
    payload: user,
  };
};

export const resetUserAction = (user: UserLoginRequest) => {
  return {
    type: types.RESET_USER,
    ...user,
  };
};

export const resetUserFail = (error: any) => {
  return {
    type: types.RESET_USER_FAIL,
    payload: error,
  };
};

export const resetUserSuccess = (user: any) => {
  return {
    type: types.RESET_USER_SUCCESS,
    payload: user,
  };
};

export const cleanStateAction = () => {
  return {
    type: types.CLEAN_USER,
  };
};

export const cleanStateActionFail = (error: any) => {
  return {
    type: types.CLEAN_USER_FAIL,
    payload: error,
  };
};

export const cleanStateActionSuccess = () => {
  return {
    type: types.CLEAN_USER_SUCCESS,
  };
};
