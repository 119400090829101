import React, { CSSProperties, useState } from "react";
import { Form, Placeholder } from "react-bootstrap";

interface TextFieldProps {
  name: string;
  id: string;
  type?: "text" | "number";
  defaultValue?: any;
  variant?: "filled" | "standard" | "outlined";
  style?: CSSProperties;
  className?: string;
  onChange?: (e: { value: any; name: string }) => void;
  required?: boolean;
  label?: string;
  placeholder?: string;
  pattern?: string;
  disabled?: boolean;
}

export const CustomTextField = (props: TextFieldProps) => {
  const [localValue, setLocalValue] = useState(props.defaultValue || "");
  const [error, setError] = React.useState(false);

  const {
    disabled,
    pattern,
    onChange,
    defaultValue,
    className,
    placeholder,
    required,
    ...textProps
  } = props;

  const handleValidation = (value: string) => {
    if (value === "" && !required) return setError(false);
    if (pattern) {
      setError(!new RegExp(pattern).test(value));
    }
  };

  React.useEffect(() => {
    if (defaultValue) {
      setLocalValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (e: any) => {
    setLocalValue(e.target.value);
    onChange && onChange({ value: e.target.value, name: props.name });
  };

  const onBlur = (e: any) => {
    handleValidation(e.target.value);
  };

  const defaultClassName = "form-control " + className;

  return (
    <>
      <Form.Label>
        {(!!localValue || localValue.length > 0) &&
          `${placeholder || ""} ${required ? " *" : ""}`}
      </Form.Label>
      <input
        {...textProps}
        required={required}
        placeholder={`${placeholder || ""} ${
          !disabled && required ? " *" : ""
        }`}
        className={
          error ? `${defaultClassName} form-control-error` : defaultClassName
        }
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleChange}
        value={localValue}
        pattern={pattern}
      />
    </>
  );
};
