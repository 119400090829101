import { Alert } from "../../shared/types";
import * as types from "./actionTypes";

export const getAlertsDataAction = (payload: any) => {
  return {
    type: types.GET_ALERTS_DATA,
    payload
  };
};

export const getAlertsDataFail = (error: any) => {
  return {
    type: types.GET_ALERTS_DATA_FAIL,
    payload: error,
  };
};

export const getAlertsDataSuccess = (alerts: Alert[]) => {
  return {
    type: types.GET_ALERTS_DATA_SUCCESS,
    payload: alerts,
  };
};

export const setAlertsDisplay = (display: boolean) => {
  return {
    type: types.SET_ALERTS_DISPLAY,
    payload: display
  };
};
