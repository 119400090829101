export const Endpoints = {
  LOGIN: "login",
  PROFILE: "start",
  DASHBOARD: "dashboard_react_data",
  ESTABLISHMENTS_MAP: "dashboard_react_establishments_map_data",
  RESET: "recover_step_1",
  ALERTS: "dashboard_react_alerts_data",
  BACKOFFICE: {
    ESTABLISHMENT: {
      GET: "api/establishments",
    },
    LOTE: {
      GET: "api/lots",
      GET_LOTS_BY_STABLISHMENT: "api/lots/get-lots-by-establishment",
    },
    ORGANIZATION: {
      GET_AVAILABLE_DEVICE: "api/organization/available-devices",
      GET_LOTS: "api/organization/get-lots",
    },
    SILO: {
      GET: "api/silos",
      CREATE: "api/silos/appWeb",
      UPDATE: "api/silos",
    },
    SILO_PRODUCT: {
      GET: "api/siloProductTypes",
    },
    SILO_SPECIES: {
      GET: "api/siloSpeciesTypes",
    },
    HARVEST: {
      GET: "api/harvests",
    },
    ALLOWED_ESTABLISHMENT: {
      GET: "api/user-permission-denied/allowed",
      UPDATE: "api/user-permission-denied",
    },
  },
  LOCATIONS: {
    GET_PROVINCE: "province",
    GET_CITY: "city",
    GET_LOCALITY: "locality",
  },
  GET_USERS: "users",
  ROLES: "roles",
  ESTABLISHMENTS: "establishments",
  COMPANIES: "companies",
  ADMIN_DATA: "organization/getOrganizationData",
  PUT_PERMISSIONS: "api/users/userPermission",
  WOZALABS: {
    ADMIN_DATA:
      "organizations?select=*,companies(*,countrysides(*,lots(*))),devices!organization_id(id,name,device_types(id,name),device_statuses(id,name),device_net_types(id,name)),users(*,roles(id,name),user_permissions(*,permissions(*)))&id=eq.",

    COMPANIES: {
      GET: "companies",
      GET_BY_ORG_ID:
        "companies?select=*,organizations(*),countrysides(*)&&organization_id=eq.",
      CREATE: "companies",
      UPDATE: "companies?id=eq.",
    },
    COUNTRYSIDES: {
      GET: "countrysides",
      GET_BY_ORG_ID:
        "countrysides?select=*,companies(id,name,organization_id,organizations(*)),countries_l2:country_l2_id(id,name,country_l1_id,country_l1_name,country_l0_id,country_l0_name)",
      CREATE: "countrysides",
      UPDATE: "countrysides?id=eq.",
    },
    HELPERS: {
      LOCALITY: "localities",
      CITY: "municipalities",
    },
    LOTS: {
      GET: "lots",
      GET_LOTS_BY_STABLISHMENT: "lots?countryside_id=eq.",
      CREATE: "lots",
      UPDATE: "lots?id=eq.",
      DELETE: "lots?id=in.",
    },
    USERS: {
      GET: "users",
      GET_USER_BY_USERNAME:
        "users?select=id,username,fullname,email,phone,organizations(*),roles(*)&&email=eq.",
      GET_BY_ORG_ID:
        "users?select=id,username,fullname,email,phone,organizations(*),roles(*)&&organization_id=eq.",
      CREATE: "users",
      UPDATE: "users?id=eq.",
    },
  },
};
