import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Image,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import ProfileIcon from "../../../assets/icons/DashboardPage/profileIcon.png";
import SearchIcon from "../../../assets/icons/DashboardPage/searchIcon.png";
import DashboardLogo from "../../../assets/img/Dashboard/dashboardLogo.svg";
import ToggleIcon from "../../../assets/img/Dashboard/toggle.svg";
import { Paths } from "../../../config/paths";
import { findIndex } from "../../../shared/helper";
import {
  NavBarProps,
  OrganizationProps,
  ReduxType,
} from "../../../shared/types";
import { setOrganizationAction } from "../../../store/organization/actions";
import { getAllSiloBagAction } from "../../../store/silobag/actions";
import { AlertsDrawerComponent } from "../../alerts/alertsDrawer";

const DropdownIndicator = () => {
  return <></>;
};

const Control = ({ children, ...props }: any) => (
  <components.Control {...props} className="cursor-text ellipsis">
    {children}
  </components.Control>
);

const NavBarComponent = (props: NavBarProps) => {
  const [organization, setOrganization] = useState<any>();
  const [userEmail, setUserEmail] = useState<any>();
  const [displayResponsiveMenu, setDisplayResponsiveMenu] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData, orgData, siloBagData } = useSelector((state: ReduxType) => {
    return state;
  });

  const { path, mobile } = props;

  useEffect(() => {
    if (userData.userProfile) {
      const user = getUserProfile();
      if (!userEmail) {
        setUserEmail(user.email);
      }
      setOrganization(user.organizations);
      if (
        user.organizations &&
        user.organizations.length > 0 &&
        !organization &&
        !orgData.currentOrganization
      ) {
        setCurrentOrg(user.organizations[0]);
      } else if (
        user.organizations &&
        !organization &&
        !orgData.currentOrganization
      ) {
        setCurrentOrg({ ...user.organizations, user_id: user.id });
      }
    }
  }, [userData.userProfile]);

  useEffect(() => {
    if (orgData.currentOrganization && orgData.currentOrganization.id) {
      getSiloBagList(orgData.currentOrganization.id);
    }
  }, [orgData.currentOrganization]);

  const getSiloBagList = async (organization_id: number) => {
    await dispatch(getAllSiloBagAction(organization_id));
  };

  const setCurrentOrg = async (org: OrganizationProps) => {
    await dispatch(setOrganizationAction(org));
  };

  const getUserProfile = () => {
    return userData &&
      userData.userProfile &&
      typeof userData.userProfile === "string"
      ? JSON.parse(userData.userProfile)
      : userData.userProfile;
  };

  const getUserFirstLetter = () => {
    const user = getUserProfile();
    if (user) {
      return user.fullname.charAt(0);
    }
    return "";
  };

  const onChangeOrg = async (org: any) => {
    const user = getUserProfile();

    if (user.organizations && user.organizations.length > 0) {
      const index = findIndex(user.organizations, "organization_id", org);
      if (index !== -1) {
        setCurrentOrg(user.organizations[index]);
      }
    }
  };

  const menu = [
    {
      name: "Dashboard",
      className: path == Paths.ROOT ? "navBarLinkSelected" : "",
      onClick: () => navigate(Paths.ROOT),
    },
    {
      name: "Mapa",
      className: path == Paths.ESTABLISHMENTS_MAPS ? "navBarLinkSelected" : "",
      onClick: () => navigate(Paths.ESTABLISHMENTS_MAPS),
    },
    {
      name: "Silobolsas",
      className:
        path == Paths.SILO || path == Paths.NEW_SILO || path == Paths.VIEW_SILO
          ? "navBarLinkSelected"
          : "",
      onClick: () => navigate(Paths.SILO),
    },
    {
      name: "Administrador",
      className: path == Paths.ADMINISTRATOR ? "navBarLinkSelected" : "",
      onClick: () => navigate(Paths.ADMINISTRATOR),
    },
  ];

  const mobileMenu = [
    {
      name: "Mi perfil",
      className: path == Paths.SILO ? "navBarLinkSelected" : "",
      onClick: () => navigate(Paths.SILO),
    },
    {
      name: "Soporte técnico",
      className: path == Paths.SILO ? "navBarLinkSelected" : "",
      onClick: () => navigate(Paths.SILO),
    },
  ];

  const FormInput = (
    <Form className={!mobile ? "mb-4" : "mb-2"}>
      <div className="d-md-flex">
        <div className="navBarInputSize navBarInputSelect">
          <Form.Group as={Col} controlId="form-org">
            <InputGroup
              hasValidation
              className={mobile ? "custom-input-group" : ""}
            >
              <Form.Select
                onChange={(value) => onChangeOrg(value.target.value)}
                aria-label="Default select example"
                value={orgData?.currentOrganization?.id}
                style={{ fontSize: 14 }}
              >
                {organization &&
                  [organization].map(
                    (org: OrganizationProps, index: number) => {
                      return (
                        <option key={`org-${index}`} value={org.id}>
                          {org.legal_name}
                        </option>
                      );
                    }
                  )}
              </Form.Select>
            </InputGroup>
          </Form.Group>
        </div>
      </div>
    </Form>
  );

  return (
    <>
      <Navbar bg="light" expand="md">
        <Container fluid>
          <Navbar.Brand className={"brand-image"}>
            <Link to={Paths.ROOT}>
              <img
                src={DashboardLogo}
                className="dashboard-logo d-inline-block align-top "
                alt="React Bootstrap logo"
              />
            </Link>
          </Navbar.Brand>

          <Nav
            className="pt-4 my-lg-0 d-flex"
            style={
              mobile
                ? { maxHeight: "100px", width: "62%" }
                : { maxHeight: "100px" }
            }
          >
            <div className={"d-flex"}>
              <Nav.Link className={"d-flex"}>
                <Form className="mb-4">
                  <div className="navBarInputSize">
                    <Form.Group controlId="form-sil" className="input-search">
                      <InputGroup hasValidation className="no-wrap">
                        <Select
                          className="search-input-navbar"
                          placeholder="Buscar silo o lanza"
                          components={{ DropdownIndicator, Control }}
                          options={
                            siloBagData.silobagList &&
                            siloBagData.silobagList.length > 0
                              ? siloBagData.silobagList.map((value: any) => ({
                                  value,
                                  label: value.nombre,
                                }))
                              : []
                          }
                          onChange={(e: any) =>
                            navigate(Paths.VIEW_SILO, {
                              state: {
                                from: path,
                                record: e.value,
                              },
                            })
                          }
                        />
                        <InputGroup.Text
                          id="inputGroupPrepend"
                          className={"navBarInputSearch"}
                        >
                          <Image
                            style={{ height: "12.28", width: 13 }}
                            src={SearchIcon}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Form>
              </Nav.Link>

              {mobile && (
                <>
                  <AlertsDrawerComponent
                    style={{
                      marginTop: "0.5vh",
                      marginLeft: "1vh",
                      marginRight: "1vh",
                    }}
                  />
                  <Nav.Link
                    className={"d-flex"}
                    onClick={() =>
                      setDisplayResponsiveMenu(!displayResponsiveMenu)
                    }
                  >
                    <Image
                      className={"dashboard-toggle-icon"}
                      src={ToggleIcon}
                    />
                    <div className={"navBarWithImage"}>
                      <img
                        src={ProfileIcon}
                        alt="user pic"
                        className="navBarUserCircle"
                      />
                      <span className="navBarUserLetter">
                        {getUserFirstLetter()}
                      </span>
                    </div>
                  </Nav.Link>
                </>
              )}
            </div>
          </Nav>

          {!mobile && (
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="me-auto pt-4 my-lg-0"
                style={{ maxHeight: "100px" }}
              >
                {FormInput}
              </Nav>

              {menu &&
                menu.map((m, index: number) => {
                  // CHANGE ADMIN VALIDATION
                  if (!userData.userProfile) return;
                  const validateUser =
                    typeof userData.userProfile === "string"
                      ? JSON.parse(userData.userProfile).email !==
                        "sreynoso@mobeats.io"
                      : userData.userProfile.email !== "sreynoso@mobeats.io";
                  if (m.name === "Administrador" && validateUser) return;
                  return (
                    <Nav.Link
                      key={`main-menu-${index}`}
                      className={m.className}
                      onClick={m.onClick}
                    >
                      {m.name}
                    </Nav.Link>
                  );
                })}

              <Nav.Link href="http://clima.iofcompany.com" target={"_blank"}>
                Clima
              </Nav.Link>

              {!mobile && <AlertsDrawerComponent />}

              <NavDropdown
                align="end"
                title={
                  <div className={"navBarWithImage"}>
                    <img src={ProfileIcon} alt="user pic" />
                    <span className="navBarUserLetter">
                      {getUserFirstLetter()}
                    </span>
                  </div>
                }
              >
                <NavDropdown.Item
                  eventKey={1.3}
                  onClick={() => navigate(Paths.LOGOUT)}
                >
                  <i className="fa fa-sign-out"></i> Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
      <Offcanvas
        show={displayResponsiveMenu}
        onHide={setDisplayResponsiveMenu}
        placement="end"
        className={"dashboard-canvas-main"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fw-bold-500 canvas-main-dashboard-title">
            {userEmail}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={"custom-canvas-body"}>
          {FormInput}
          {[...menu, ...mobileMenu].map((m, index: number) => {
            return (
              <Nav.Link
                key={`mobile-menu-${index}`}
                className={m.className}
                onClick={m.onClick}
              >
                {m.name}
              </Nav.Link>
            );
          })}

          <Nav.Link
            style={{ position: "absolute", bottom: 0, marginBottom: 15 }}
            onClick={() => navigate(Paths.LOGOUT)}
          >
            <div className={"divider"} />
            Cerrar sesión
          </Nav.Link>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NavBarComponent;
