import React, { CSSProperties } from "react";
import { ButtonProps } from "react-bootstrap";

interface CustomButtonProps extends ButtonProps {
  label?: string;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  children?: JSX.Element;
}

export const CustomButton = (props: CustomButtonProps) => {
  const {
    label,
    children,
    className,
    disabled,
    onClick,
    style,
    ...extraProps
  } = props;
  return (
    <button
      {...extraProps}
      style={style}
      className={`${className} custom-button`}
      disabled={disabled}
      onClick={onClick}
    >
      {children || label}
    </button>
  );
};
