export const GET_PROVINCE = "GET_PROVINCES";
export const GET_PROVINCE_FAIL = "GET_PROVINCES_FAIL";
export const GET_PROVINCE_SUCCESS = "GET_PROVINCES_SUCCESS";

export const GET_CITY = "GET_CITY";
export const GET_CITY_FAIL = "GET_CITY_FAIL";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";

export const GET_LOCALITY = "GET_LOCALITY";
export const GET_LOCALITY_FAIL = "GET_LOCALITY_FAIL";
export const GET_LOCALITY_SUCCESS = "GET_LOCALITY_SUCCESS";

export const GET_ADMIN_DATA = "GET_COMPANIES";
export const GET_ADMIN_DATA_FAIL = "GET_COMPANIES_FAIL";
export const GET_ADMIN_DATA_SUCCESS = "GET_COMPANIES_SUCCESS";

export const GET_USERS = "GET_USERS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";

export const GET_ESTABLISHMENTS = "GET_ESTABLISHMENTS";
export const GET_ESTABLISHMENTS_FAIL = "GET_ESTABLISHMENTS_FAIL";
export const GET_ESTABLISHMENTS_SUCCESS = "GET_ESTABLISHMENTS_SUCCESS";

export const GET_LOTS = "GET_LOTS";
export const GET_LOTS_FAIL = "GET_LOTS_FAIL";
export const GET_LOTS_SUCCESS = "GET_LOTS_SUCCESS";

export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const GET_ROLES_LIST_FAIL = "GET_ROLES_LIST_FAIL";
export const GET_ROLES_LIST_SUCCESS = "GET_ROLES_LIST_SUCCESS";

export const GET_ALLOWED_ESTABLISHMENT = "GET_ALLOWED_ESTABLISHMENT";
export const GET_ALLOWED_ESTABLISHMENT_FAIL = "GET_ALLOWED_ESTABLISHMENT_FAIL";
export const GET_ALLOWED_ESTABLISHMENT_SUCCESS =
  "GET_ALLOWED_ESTABLISHMENT_SUCCESS";

export const UPDATE_ALLOWED_ESTABLISHMENT = "UPDATE_ALLOWED_ESTABLISHMENT";
export const UPDATE_ALLOWED_ESTABLISHMENT_FAIL =
  "UPDATE_ALLOWED_ESTABLISHMENT_FAIL";
export const UPDATE_ALLOWED_ESTABLISHMENT_SUCCESS =
  "UPDATE_ALLOWED_ESTABLISHMENT_SUCCESS";
