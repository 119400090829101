import React, { useState } from "react";
import { Carousel, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackArrowSlideIcon from "../../assets/icons/svg/SiloBagPage/edit/BackArrowSlide.svg";
import NextArrowSlideIcon from "../../assets/icons/svg/SiloBagPage/edit/NextArrowSlide.svg";

const CarouselComponent = (props: any) => {
  const [index, setIndex] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { record, viewPage } = props;

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  const imgs = [
    {
      img: record && record.imagen_inicio,
    },
    {
      img: record && record.imagenGeneral,
    },
    {
      img: record && record.imagen_fin,
    },
  ];

  const nextIcon = (
    <span>
      {" "}
      <Image
        src={NextArrowSlideIcon}
        style={{
          width: 130,
          height: 130,
          marginLeft: "2vh",
        }}
      />
    </span>
  );
  const prevIcon = (
    <span>
      {" "}
      <Image
        src={BackArrowSlideIcon}
        style={{
          width: 130,
          height: 130,
          marginRight: "2vh",
        }}
      />
    </span>
  );
  return (
    <Carousel
      nextIcon={nextIcon}
      prevIcon={prevIcon}
      activeIndex={index}
      onSelect={handleSelect}
    >
      {imgs &&
        imgs.map((i) => {
          return (
            <Carousel.Item>
              <Image
                src={i.img}
                style={{
                  width: "100%",
                  height: viewPage ? 639 : 478,
                  objectFit: "contain",
                }}
              />
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

export default CarouselComponent;
