import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { alertColor, getTextColor } from "../../../shared/helper";
import { InfoWindowTooltipProps } from "../../../shared/types";

const LanzasComponent = ({ lanzas }: any) => {
  return (
    <div style={{ display: "inline-flex", gap: 5, flexWrap: "wrap" }}>
      {lanzas.map((value: any, index: number) => {
        return (
          <div
            key={index}
            className={"d-flex"}
            style={{
              background: alertColor(value.ALERTA_COLOR),
              alignItems: "center",
              justifyContent: "center",
              width: 20,
              height: 20,
              borderRadius: 50,
              margin: 0,
            }}
          >
            <span
              className="fw-bold-700"
              style={{
                marginTop: 1,
                color: getTextColor(value.ALERTA_COLOR),
              }}
            >
              {index + 1}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const InfoWindowTooltip = (props: InfoWindowTooltipProps) => {
  const { marker, mapInfo, lanzas, alertsCounter } = props;

  return (
    <div
      className="info-map d-flex align-center justify-between"
      style={{ maxWidth: 255, minWidth: 255, flexDirection: "column" }}
    >
      <div
        className="info-map__top align-center justify-between"
        style={{
          display: "inline-flex",
          width: "100%",
        }}
      >
        <Container>
          <Row>
            <Col xs={4} className="info-map__top--item d-flex">
              <h5
                className="fw-bold-700"
                style={{ fontSize: 16, lineHeight: "24px" }}
              >
                {marker.ELEMENTO_NOMBRE || "-"}
              </h5>
              <span>Silo</span>
            </Col>
            <Col xs={4} className="info-map__top--item d-flex">
              <h5 style={{ fontSize: 14, lineHeight: "21px" }}>
                {marker.ELEMENTO_PRODUCTO || "-"}
              </h5>
              <span>Especie</span>
            </Col>
            <Col xs={4} className="info-map__top--item d-flex">
              <h5 style={{ fontSize: 14, lineHeight: "21px" }}>
                {marker.ELEMENTO_TIPO || "-"}
              </h5>
              <span>Producto</span>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="info-map__bottom align-center justify-between">
        <Container>
          <Row>
            <Col xs={6} className="info-map__bottom-item align-center">
              <h5 className="fw-bold-700">{marker.ELEMENTO_COSECHA}</h5>
              <span>Cosecha</span>
            </Col>
            <Col xs={6} className="info-map__bottom-item align-center">
              <h5 className="fw-bold-700">{alertsCounter}</h5>
              <span>Alertas</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="info-map__bottom-item align-center">
              <h5 className="fw-bold-700">-</h5>
              <span>Metros</span>
            </Col>
            <Col xs={6} className="info-map__bottom-item align-center">
              <h5 className="fw-bold-700">
                {marker.ELEMENTO_CANTIDAD_DISPOSITIVO || 0}
              </h5>
              <span>Lanzas</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="info-map__bottom-item align-center">
              <h5 className="fw-bold-700">{marker.LOTE_ID}</h5>
              <span>Lote</span>
            </Col>
            <Col xs={6} className="info-map__bottom-item align-center">
              {lanzas && lanzas.length > 0 && (
                <LanzasComponent lanzas={lanzas} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default InfoWindowTooltip;
