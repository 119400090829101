import React from "react";
import { Provider } from "react-redux";
import { RouterConfig } from "./core/routes/RouterConfig";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";

function App() {
  return (
    <React.Suspense fallback="Cargando">
      <Provider store={store}>
        <RouterConfig />
      </Provider>
    </React.Suspense>
  );
}

export default App;
