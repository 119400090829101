import format from "date-fns/format";
import React, { ReactNode } from "react";
import { Badge, Image, Stack } from "react-bootstrap";
import EstablishmentIcon from "../../../assets/icons/svg/DashboardPage/ico-estab.svg";
import { alertColor, getTextColor } from "../../../shared/helper";
import { Alert } from "../../../shared/types";
import { CardComponent } from "../../card";

interface AlertCardComponentProps {
  alert: Alert;
  action?: ReactNode;
}

const AlertCardComponent = (props: AlertCardComponentProps) => {
  const { alert, action } = props;
  return (
    <CardComponent
      withoutBorder
      withAlertBorder
      borderAlertColor={alert.ALERTA_COLOR}
      minHeight={86}
      height={"auto"}
      marginTop={"mt-3"}
      backgroundColor={"#F3F2F7"}
      className="card-shadow"
      style={{
        zIndex: "100",
      }}
    >
      <Stack>
        <div className="h-100 d-flex align-items-center fs-07">
          <div className="flex-grow-1 text-secondary">
            {format(alert.FECHA, "dd/MM/yyyy, HH:mm")}
          </div>
          <div className="me-2">
            <span className="text-secondary me-1">Valor alerta</span>
            <span className="fs-08 fw-bold-500 text-reset">
              {alert.Valor_Alerta ? alert.Valor_Alerta.toFixed(1) + "%" : "-"}
            </span>
          </div>
          <Badge
            pill
            bg="custom"
            className="fs-08"
            style={{
              backgroundColor: alertColor(alert.ALERTA_COLOR),
              color: getTextColor(alert.ALERTA_COLOR as any),
            }}
          >
            {alert.Indicador || "-"}
          </Badge>
        </div>
        <div className="d-flex flex-grow-1 mt-1 fs-09">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <Image
              style={{
                width: 14,
                height: 14,
              }}
              src={EstablishmentIcon}
            />
          </div>
          <div className="ms-2 fs-07">{alert.Establecimiento}</div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <Stack>
              <span className="fs-07">
                Dispositivo{" "}
                <span className="fs-10 fw-bold-500">{alert.DISPOSITIVO}</span>
              </span>
            </Stack>
          </div>
        </div>
        {action}
      </Stack>
    </CardComponent>
  );
};

export default AlertCardComponent;
