import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import { NewSiloBagFormValues } from "../../../../shared/formValues";
import { ReduxType, SiloBagFormValues } from "../../../../shared/types";
import { CardComponent } from "../../../card";
import {
  InputNumberComponent,
  InputTextComponent,
  InputSelectComponent,
  InputTextAreaComponent,
} from "../../../inputs";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllEstablishmentAction,
  getAllHarvestAction,
  getAllLoteAction,
  getAllProductsAction,
  getAllSpeciesAction,
} from "../../../../store/silobag/actions";
import { validatorValues } from "../../constants/Constants";
import { validateForm } from "../../../../shared/validations";
import { apiPost, apiPut } from "../../../../shared/ApiService";
import { API_URL_BACKOFFICE } from "../../../../config/general-config";
import { Endpoints } from "../../../../config/endpoints";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import { Paths } from "../../../../config/paths";
import { CustomSelect } from "../../../form/CustomSelect";
import { CustomTextField } from "../../../form/CustomTextField";
import {
  floatPositiveAndNegativeRegex,
  floatPositiveRegex,
} from "../../../../shared/regexPatterns";
import { CustomButton } from "../../../customButton/CustomButton";
import { formatDatetimeToSave } from "../../../../shared/helper";

const SiloBagFormComponent = (props: any) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siloBagFormValues, setSiloBagFormValues] =
    useState<any>(NewSiloBagFormValues);

  const {
    createMode,
    style,
    editionMode,
    formDeviceValues,
    record,
    className,
    setErrorMessage,
    setShowToast,
    setErrorType,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { siloBagData, orgData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (!createMode && record) {
      initialUpdate(record, false);
    }
    if (createMode && editionMode) {
      initialUpdate(NewSiloBagFormValues, false);
    }
  }, [createMode, editionMode, record]);

  useEffect(() => {
    getAllSiloBag();
  }, []);

  const getAllSiloBag = async () => {
    await dispatch(getAllEstablishmentAction());
    await dispatch(getAllHarvestAction());
    await dispatch(getAllProductsAction());
    await dispatch(getAllSpeciesAction());
  };

  const initialUpdate = async (initialize: any, loading: boolean) => {
    await setSiloBagFormValues({
      id: initialize.id,
      establecimientoId:
        initialize.establecimientoId || initialize.lote.establecimiento.id,
      loteId: initialize.loteId || initialize.lote.id,
      nombre: initialize.nombre,
      tipoProductoId: initialize.tipoProductoId,
      tipoEspecieId: initialize.tipoEspecieId,
      cosechaId: initialize.cosechaId,
      tamanio: initialize.tamanio,
      humedadInicial: initialize.humedadInicial,
      pesoIngresado: initialize.pesoIngresado,
      latitudGeneral: initialize.latitudGeneral,
      longitudGeneral: initialize.longitudGeneral,
      latitudInicio: initialize.latitudInicio,
      latitudFin: initialize.latitudFin,
      longitudInicio: initialize.longitudInicio,
      longitudFin: initialize.longitudFin,
      observaciones: initialize.observaciones,
      cosechado:
        initialize.cosechado &&
        initialize.cosechado.data &&
        initialize.cosechado.data[0] !== null
          ? initialize.cosechado.data[0]
          : initialize.cosechado,
      dispositivos: initialize.dispositivos || initialize.silos_dispositivos,
      fechaArmado: initialize.fechaArmado,
      organization_id: initialize.organization_id,
      tipoEstadoDatosId: initialize.tipoEstadoDatosId,
    });
    if (initialize.establecimientoId || initialize.lote.establecimiento.id) {
      const establishmentId =
        initialize.establecimientoId || initialize.lote.establecimiento.id;
      onChangeRequestLote(establishmentId);
    }
    await setLoading(loading);
  };

  const prepareToastData = async (response: any, close = true) => {
    let message;
    if (response && response.createSilo && response.createSilo.id) {
      message = `El Silo ${response.createSilo.nombre} fue creado de forma correcta.`;
    } else if (response.message) {
      message = response.message;
    } else {
      message = response;
    }

    if (message !== response) {
      navigate(Paths.SILO, {
        state: {
          message: message,
        },
      });
    } else {
      await setErrorMessage(message);
      setErrorType(false);
      setShowToast(true);
    }
  };

  const handleSubmit = async (e: any) => {
    try {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        e.preventDefault();
        e.stopPropagation();
        if (!validateForm(siloBagFormValues, validatorValues)) {
          throw new Error(
            "Complete todos los datos obligatorios antes de continuar."
          );
        }
        if (!siloBagFormValues.fechaArmado) {
          siloBagFormValues.fechaArmado = moment();
        }
        if (!siloBagFormValues.organization_id) {
          siloBagFormValues.organization_id = 1;
        }
        siloBagFormValues.tipoEstadoDatosId =
          siloBagFormValues.latitudGeneral &&
          siloBagFormValues.latitudGeneral !== "" &&
          siloBagFormValues.longitudGeneral &&
          siloBagFormValues.longitudGeneral !== ""
            ? 2
            : 1;

        let response;
        if (createMode) {
          siloBagFormValues.dispositivos = formDeviceValues
            .filter((d: any) => !d.delete)
            .map(({ dispositivoName, ...rest }: any) => {
              if (rest.dispositivoId) {
                return { ...rest };
              } else {
                throw new Error(
                  "Complete o elimine los dipositivos incompletos"
                );
              }
            });
          response = await apiPost({
            url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.SILO.CREATE}`,
            body: siloBagFormValues,
          });
        } else {
          siloBagFormValues.silos_dispositivos =
            formDeviceValues &&
            formDeviceValues.length > 0 &&
            formDeviceValues
              .filter((d: any) => !d.delete)
              .map((d: any) => {
                if (d.dispositivoName) {
                  return {
                    id: d.dispositivoId,
                    latitud: d.latitud,
                    longitud: d.longitud,
                  };
                }
                return {
                  ...d,
                  siloId: siloBagFormValues.id,
                  fechaDesde: formatDatetimeToSave(moment()),
                  fechaHasta: formatDatetimeToSave(moment()),
                };
              });

          siloBagFormValues.delete_silos_dispositivos = formDeviceValues
            .filter((d: any) => d.delete)
            .map((d: any) => {
              return d.dispositivoId;
            });

          response = await apiPut({
            url: `${API_URL_BACKOFFICE}/${Endpoints.BACKOFFICE.SILO.UPDATE}/${siloBagFormValues.id}`,
            body: siloBagFormValues,
          });
        }
        prepareToastData(response);
      }
      setValidated(true);
    } catch (error: any) {
      await setErrorMessage(error.message);
      setShowToast(true);
      setErrorType(true);
    }
  };

  const onChange = (e: any) => {
    setSiloBagFormValues({
      ...siloBagFormValues,
      [e.name]: e.value,
    });
  };

  const onChangeRequestLote = async (establishmentId: number) => {
    await dispatch(getAllLoteAction(establishmentId));
  };

  return (
    <CardComponent
      dashboard
      style={style}
      className={className}
      marginTop={"0"}
    >
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id="silobag-main-form"
      >
        <Row>
          {record && record.id && (
            <>
              <Form.Group as={Col} md="6" controlId="id">
                <CustomTextField
                  required
                  id="id"
                  name="id"
                  defaultValue={siloBagFormValues.id}
                  disabled={true}
                  type="text"
                  placeholder="ID"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                {" "}
              </Form.Group>
            </>
          )}

          <Form.Group
            as={Col}
            md={6}
            className=" mt-2"
            controlId="establecimientoId"
          >
            <CustomSelect
              required
              id="establecimientoId"
              name="establecimientoId"
              placeholder="Establecimiento"
              onChange={(e) => {
                onChange({ name: "loteId", value: null });
                onChange({ name: e.name, value: e.value.target.value });
                onChangeRequestLote(e.value.target.value);
              }}
              defaultValue={
                siloBagFormValues && siloBagFormValues.establecimientoId
              }
              disabled={!editionMode}
              values={siloBagData && siloBagData.establishments}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un establecimiento.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" className=" mt-2" controlId="loteId">
            <CustomSelect
              required
              disabled={
                siloBagFormValues && siloBagFormValues.establecimientoId
                  ? false
                  : true
              }
              id={"loteId"}
              name={"loteId"}
              placeholder={"Lote"}
              onChange={(e) =>
                onChange({ name: e.name, value: e.value.target.value })
              }
              defaultValue={siloBagFormValues.loteId}
              values={siloBagData && siloBagData.lots}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un lote.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="12" className="mt-2" controlId="nombre">
            <CustomTextField
              required
              id="nombre"
              name="nombre"
              defaultValue={siloBagFormValues.nombre}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              placeholder="Nombre"
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un nombre.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mt-2"
            controlId="tipoProductoId"
          >
            <CustomSelect
              id={"tipoProductoId"}
              name={"tipoProductoId"}
              placeholder={"Producto"}
              onChange={(e) =>
                onChange({ name: e.name, value: e.value.target.value })
              }
              defaultValue={siloBagFormValues.tipoProductoId}
              values={siloBagData && siloBagData.products}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un producto.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mt-2"
            controlId="tipoEspecieId"
          >
            <CustomSelect
              required
              id={"tipoEspecieId"}
              name={"tipoEspecieId"}
              placeholder={"Especie"}
              onChange={(e) =>
                onChange({ name: e.name, value: e.value.target.value })
              }
              defaultValue={siloBagFormValues.tipoEspecieId}
              values={siloBagData && siloBagData.species}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una especie.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" className="mt-2" controlId="cosechaId">
            <CustomSelect
              id={"cosechaId"}
              name={"cosechaId"}
              placeholder={"Cosecha"}
              onChange={(e) =>
                onChange({ name: e.name, value: e.value.target.value })
              }
              defaultValue={siloBagFormValues.cosechaId}
              values={siloBagData && siloBagData.harvests}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una cosecha.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" className="mt-2" controlId="tamanio">
            <CustomTextField
              id="tamanio"
              name="tamanio"
              placeholder={"Largo siloBolsa"}
              defaultValue={siloBagFormValues.tamanio}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un largo de silo bolsa correcto (Solo valores
              numéricos positivos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mt-2"
            controlId="humedadInicial"
          >
            <CustomTextField
              id="humedadInicial"
              name="humedadInicial"
              placeholder={"Humedad de embolsado"}
              defaultValue={siloBagFormValues.humedadInicial}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una humedad de embolsado correcto (Solo valores
              numéricos positivos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mt-2"
            controlId="pesoIngresado"
          >
            <CustomTextField
              id="pesoIngresado"
              name="pesoIngresado"
              placeholder={"Peso de embolsado"}
              defaultValue={siloBagFormValues.pesoIngresado}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un peso de embolsado correcto (Solo valores
              numéricos positivos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mt-2"
            controlId="latitudGeneral"
          >
            <CustomTextField
              id="latitudGeneral"
              name="latitudGeneral"
              placeholder={"Latitud"}
              defaultValue={siloBagFormValues.latitudGeneral}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveAndNegativeRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una latitud correcta (Solo valores numéricos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mt-2"
            controlId="longitudGeneral"
          >
            <CustomTextField
              id="longitudGeneral"
              name="longitudGeneral"
              placeholder={"Longitud"}
              defaultValue={siloBagFormValues.longitudGeneral}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveAndNegativeRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una longitud correcta (Solo valores numéricos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mt-2"
            style={{ display: "flex", alignItems: "end", minHeight: 62 }}
          >
            <div
              style={{ display: "inline-flex", gap: 10 }}
              className="align-center"
            >
              <p className="muted" style={{ margin: 0 }}>
                Ubicación
              </p>
              <CustomButton
                className="custom-button"
                disabled={
                  !siloBagFormValues.latitud || !siloBagFormValues.longitud
                }
                onClick={(e: any) => {
                  e.preventDefault();
                }}
                label="GEORREFERENCIAR"
              />
            </div>
          </Form.Group>

          <Form.Group
            as={Col}
            md="3"
            className="mt-2"
            controlId="latitudInicio"
          >
            <CustomTextField
              id="latitudInicio"
              name="latitudInicio"
              placeholder={"Latitud inicio"}
              defaultValue={siloBagFormValues.latitudInicio}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveAndNegativeRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una latitud de inicio correcta (Solo valores
              numéricos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="3"
            className="mt-2"
            controlId="longitudInicio"
          >
            <CustomTextField
              id="longitudInicio"
              name="longitudInicio"
              placeholder={"Longitud inicio"}
              defaultValue={siloBagFormValues.longitudInicio}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveAndNegativeRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una longitud inicio correcta (Solo valores
              numéricos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="3" className="mt-2" controlId="latitudFin">
            <CustomTextField
              id="latitudFin"
              name="latitudFin"
              placeholder={"Latitud fin"}
              defaultValue={siloBagFormValues.latitudFin}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveAndNegativeRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una latitud de fin correcta (Solo valores
              numéricos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="3" className="mt-2" controlId="longitudFin">
            <CustomTextField
              id="longitudFin"
              name="longitudFin"
              placeholder={"Longitud fin"}
              defaultValue={siloBagFormValues.longitudFin}
              onChange={onChange}
              disabled={!editionMode}
              type="text"
              pattern={floatPositiveAndNegativeRegex.source}
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una longitud de fin correcta (Solo valores
              numéricos).
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="12"
            className="mt-2"
            controlId="observaciones"
          >
            <InputTextAreaComponent
              placeholder={"Observaciones"}
              formName={"observaciones"}
              onChange={onChange}
              rows={5}
              value={siloBagFormValues.observaciones}
              errorMessage={"Por favor, ingrese una observacion."}
            />
          </Form.Group>
        </Row>
      </Form>
    </CardComponent>
  );
};

export default SiloBagFormComponent;
