import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths } from "../../config/paths";
import DashboardPage from "../../pages/dashboard/DashboardPage";
import ForgotPasswordPage from "../../pages/forgot/ForgotPasswordPage";
import LoginPage from "../../pages/login/LoginPage";
import LogoutPage from "../../pages/logout/LogoutPage";
import EstablishmentsMapPage from "../../pages/establishmentsMap/EstablishmentsMap";
import NewSilobagPage from "../../pages/silobag/NewSilobagPage";
import { Administrator } from "../../pages/administrator/Administrator";
import SiloBagPage from "../../pages/silobag/SiloBagPage";
import ViewSiloBagPage from "../../pages/silobag/ViewSiloBagPage";

export const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={Paths.FORGOTPASS} element={<ForgotPasswordPage />} />
        <Route path={Paths.LOGIN} element={<LoginPage />} />
        <Route path={Paths.LOGOUT} element={<LogoutPage />} />
        <Route
          path={Paths.ESTABLISHMENTS_MAPS}
          element={<EstablishmentsMapPage />}
        />
        <Route path={Paths.ADMINISTRATOR} element={<Administrator />} />
        <Route path={Paths.ROOT} element={<DashboardPage />} />

        <Route path={Paths.SILO} element={<SiloBagPage />} />
        <Route path={Paths.NEW_SILO} element={<NewSilobagPage />} />
        <Route path={Paths.VIEW_SILO} element={<ViewSiloBagPage />} />
      </Routes>
    </BrowserRouter>
  );
};
