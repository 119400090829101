import * as types from "./actionTypes";

const initialState = {
  currentDashboardOrg: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const DashboardReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_DASHBOARD_DATA:
      state = { ...state, loading: true };
      break;

    case types.GET_DASHBOARD_DATA_SUCCESS:
      state = { ...state, currentDashboardOrg: actionPayload, loading: false };
      break;

    case types.GET_DASHBOARD_DATA_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default DashboardReducer;
