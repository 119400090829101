import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { InputNumberProps } from "../../../shared/types";

const InputNumberComponent = (props: InputNumberProps) => {
  const {
    errorMessage,
    formName,
    placeholder,
    required,
    step,
    value,
    onChange,
    disabled,
  } = props;

  return (
    <>
      <InputGroup hasValidation>
        <Form.Control
          disabled={disabled}
          required={required}
          type="number"
          step={step}
          placeholder={`${placeholder} ${required ? `*` : ``}`}
          defaultValue={value}
          onChange={(e) => onChange(formName, e.target.value)}
          className={"silo-bag-form-input"}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </>
  );
};

export default InputNumberComponent;
