import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CarouselComponent } from "../../carousel";

const SilobagImgModalComponent = (props: any) => {
  const { data, showModal, title, handleClose } = props;

  return (
    <Modal
      onHide={handleClose}
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        {data && data.imagen_inicio ? (
          <CarouselComponent viewPage record={data} />
        ) : (
          "Sin imágenes cargadas"
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SilobagImgModalComponent;
