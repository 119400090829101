const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const alphanumericRegex = /^[A-zÀ-ú0-9\s]+$/i;

const alphanumericWithPointRegex = /[\w.]+/;

const alphanumericWithSpecialCharsRegex =
  /^[\w\$\&\+\,\:\;\=\?\@\#\|\'\"\<\>\.\^\*\(\)\%\!\-\s]+$/;

const cuitRegex = /\b(20|23|24|27|30|33|34)[0-9]{8}?[0-9]/g;

const numericRegex = /[0-9]+/;

const floatPositiveAndNegativeRegex = /[+-]?([0-9]*[.])?[0-9]+/;
const floatPositiveRegex = /[+]?([0-9]*[.])?[0-9]+/;

const dniRegex = /^(\d{2}\d{3}\d{3})$/;

const negativPositiveNumericRegex = "^-?d*.{0,1}d+$";

const customDNI = "[0-9]{8,8}";

export {
  alphanumericRegex,
  alphanumericWithPointRegex,
  alphanumericWithSpecialCharsRegex,
  cuitRegex,
  customDNI,
  dniRegex,
  emailRegex,
  floatPositiveRegex,
  floatPositiveAndNegativeRegex,
  negativPositiveNumericRegex,
  numericRegex,
};
