import { takeLatest, put, call } from "redux-saga/effects";

import { SET_ORGANIZATION } from "./actionTypes";

import { setOrganizationFail, setOrganizationSuccess } from "./actions";

function* onSetOrganization(payload: any) {
  try {
    const { org } = payload;
    yield call(() => {
      return Promise.resolve();
    });
    yield put(setOrganizationSuccess(org));
  } catch (error: any) {
    yield put(setOrganizationFail(error.response));
  }
}

function* OrganizationSaga() {
  yield takeLatest(SET_ORGANIZATION, onSetOrganization);
}

export default OrganizationSaga;
