import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ModalFormProps, ReduxType } from "../../../shared/types";
import AddIcon from "../../../assets/icons/svg/AdministratorPage/add-green-icon.svg";
import TrashCanIcon from "../../../assets/icons/svg/AdministratorPage/trashcan.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminDataAction,
  getCitiesListDataAction,
  getLocalitiesListDataAction,
  getLotsListAction,
} from "../../../store/administrator/actions";
import { CustomTextField } from "../../form/CustomTextField";
import { alphanumericRegex } from "../../../shared/regexPatterns";
import { CustomSelect } from "../../form/CustomSelect";
import { CustomButton } from "../../customButton/CustomButton";

interface EstablishmentModalProps extends ModalFormProps {
  handleLotsChange: (
    method: "ADD" | "DELETE" | "UPDATE",
    values: any,
    initialValues: any
  ) => void;
}

export const EstablishmentModalForm = (props: EstablishmentModalProps) => {
  const {
    handleLotsChange,
    editionMode,
    onValueChange,
    valuesToEditOrView,
    onSubmit,
    formValues,
  } = props;

  const [lotsList, setLotsList] = useState<any[]>([]);
  const [validated, setValidated] = useState(false);
  const [loteValues, setLoteValues] = useState({ name: "", geom: "" });

  const dispatch = useDispatch();

  const { administratorData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    getAllNewOrganizationData();
  }, []);

  useEffect(() => {
    if (administratorData.lotsList) {
      setLotsList([...administratorData.lotsList]);
    }
  }, [administratorData.lotsList]);

  const getAllNewOrganizationData = async () => {
    await dispatch(getLotsListAction(valuesToEditOrView.id));
    if (!administratorData.companiesList) await dispatch(getAdminDataAction(1));
    await dispatch(getCitiesListDataAction(null, false));
    await dispatch(getLocalitiesListDataAction());
  };

  const getOnChange = async (name: string, id: any) => {
    switch (name) {
      case "municipality_id":
        onValueChange({ name: "municipality_id", value: id });
        break;
      case "locality_id":
        onValueChange({ name: "locality_id", value: id });
        break;
      default:
        break;
    }
  };

  const onDomicilioChange = ({ value, name }: { value: any; name: string }) => {
    if (typeof value === "number") {
      getOnChange(name, value);
    } else {
      const finalValue =
        typeof value.target.value === "string"
          ? parseInt(value.target.value)
          : value.target.value;
      getOnChange(name, finalValue);
    }
  };

  const onCompanyChange = ({ value, name }: any) => {
    if (typeof value === "number") {
      getOnChange(name, value);
    } else {
      const finalValue =
        typeof value.target.value === "string"
          ? parseInt(value.target.value)
          : value.target.value;
      onValueChange({ name, value: finalValue });
    }
  };

  const onChangeAddedLot = (values: any, initialValue?: boolean) => {
    handleLotsChange("UPDATE", values, initialValue);
  };

  const addLotToList = (values: any) => {
    setLotsList([...lotsList, { ...values }]);
    handleLotsChange("ADD", values, administratorData.lotsList);
    setLoteValues({ name: "", geom: "" });
  };

  const removeLotFromList = (id: any, initialValue?: boolean) => {
    const filterList = [...lotsList].filter((val) => val.id !== id);
    setLotsList(filterList);
    handleLotsChange("DELETE", id, initialValue);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onSubmit();
    }
    setValidated(true);
  };

  return (
    <Form
      noValidate
      className="modal-form"
      validated={validated}
      onSubmit={handleSubmit}
      id="modal-form"
    >
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="company_id">
          <CustomSelect
            id="company_id"
            name="company_id"
            placeholder="Compañia"
            onChange={onCompanyChange}
            defaultValue={valuesToEditOrView.company_id || ""}
            disabled={!editionMode}
            values={administratorData.companiesList}
          />
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} sm="12" controlId="name">
          <CustomTextField
            id="name"
            name="name"
            defaultValue={valuesToEditOrView.name}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            required
            placeholder="Nombre"
            pattern={alphanumericRegex.source}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un nombre correcto.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-2">
        <Form.Group as={Col} sm={6} controlId="municipality_id">
          <CustomSelect
            id="municipality_id"
            name="municipality_id"
            required
            placeholder="Ciudad"
            onChange={onDomicilioChange}
            defaultValue={valuesToEditOrView.municipality_id || ""}
            disabled={!editionMode}
            values={administratorData.cities}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese una ciudad.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm={6} controlId="locality_id">
          <CustomSelect
            id="locality_id"
            name="locality_id"
            required
            placeholder="Localidad"
            onChange={onDomicilioChange}
            defaultValue={valuesToEditOrView.locality_id || ""}
            disabled={!editionMode}
            values={administratorData.localities.filter(
              (l) => l.municipality_id === formValues.municipality_id
            )}
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese una localidad.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2 align-center">
        <Form.Group as={Col} sm="3" controlId="lon">
          <CustomTextField
            id="lon"
            name="lon"
            defaultValue={valuesToEditOrView.lon}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            placeholder="Longitud"
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese una longitud correcta.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm="3" controlId="lat">
          <CustomTextField
            id="lat"
            name="lat"
            defaultValue={valuesToEditOrView.lat}
            onChange={onValueChange}
            disabled={!editionMode}
            type="text"
            placeholder="Latitud"
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese una latitud correcta.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm="6" controlId="posicionMapa">
          <div
            style={{ display: "inline-flex", gap: 10 }}
            className="align-center"
          >
            <p className="muted" style={{ margin: 0 }}>
              Posición geográfica
            </p>
            <CustomButton
              className="custom-button"
              disabled={true}
              onClick={(e) => {
                e.preventDefault();
                if (
                  !formValues.lon ||
                  !formValues.lat ||
                  formValues.lon.length === 0 ||
                  formValues.lat.length === 0
                )
                  return;
                window.open(
                  `https://maps.google.com/?q=${formValues.lon},${formValues.lat}`,
                  "_blank"
                );
              }}
              label="Abrir Mapa"
            />
          </div>
        </Form.Group>
      </Row>
      <Row className="mt-2">
        <Form.Label as={Col} sm="12" style={{ paddingLeft: "1.2rem" }}>
          Lotes
        </Form.Label>
      </Row>
      {lotsList &&
        lotsList.length > 0 &&
        lotsList.map((value, index) => {
          const { name, geom, id, createdAt } = value;
          return (
            <Row className="mb-2" key={index}>
              <Form.Group as={Col} sm="4" controlId="name">
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  disabled={!editionMode}
                  defaultValue={name}
                  onChange={(e) =>
                    onChangeAddedLot(
                      { id, value: e.target.value, name: "name" },
                      !!createdAt
                    )
                  }
                />
              </Form.Group>
              <Form.Group as={Col} sm="4" controlId="geom">
                <Form.Control
                  type="text"
                  placeholder="Polígono"
                  disabled={!editionMode}
                  defaultValue={geom}
                  onChange={(e) =>
                    onChangeAddedLot(
                      { id, value: e.target.value, name: "geom" },
                      !!createdAt
                    )
                  }
                />
              </Form.Group>
              <Form.Group as={Col} sm="4" controlId="addLote">
                <button
                  className="custom-button-invert"
                  type="button"
                  onClick={(e) => {
                    removeLotFromList(id, !!createdAt);
                  }}
                >
                  <img
                    src={TrashCanIcon}
                    alt="trash-can-icon"
                    style={{ paddingRight: 5 }}
                  />
                </button>
              </Form.Group>
            </Row>
          );
        })}
      <Row className="mb-2">
        <Form.Group as={Col} sm="4" controlId="name">
          <Form.Control
            type="text"
            placeholder="Nombre"
            disabled={!editionMode}
            value={loteValues.name}
            onChange={(e) =>
              setLoteValues({ ...loteValues, name: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group as={Col} sm="4" controlId="geom">
          <Form.Control
            type="text"
            placeholder="Polígono"
            disabled={!editionMode}
            value={loteValues.geom}
            onChange={(e) =>
              setLoteValues({ ...loteValues, geom: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group as={Col} sm="4" controlId="addLote">
          <button
            className="custom-button-invert"
            type="button"
            onClick={(e) =>
              addLotToList({
                id: lotsList.length + 2,
                countryside_id: valuesToEditOrView.countryside_id,
                name: loteValues.name,
                geom: loteValues.geom,
              })
            }
          >
            <img src={AddIcon} alt="add-icon" style={{ paddingRight: 5 }} />
            Agregar Lote
          </button>
        </Form.Group>
      </Row>
    </Form>
  );
};
