import * as types from "./actionTypes";

const initialState = {
  allowedEstablishments: undefined,
  provinces: [],
  localities: [],
  cities: [],
  rolesList: undefined,
  usersList: undefined,
  deviceList: undefined,
  countrysidesList: undefined,
  companiesList: undefined,
  fullCompaniesList: undefined,
  lotsList: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const AdministratorReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_ALLOWED_ESTABLISHMENT:
    case types.GET_CITY:
    case types.GET_LOCALITY:
    case types.GET_PROVINCE:
    case types.GET_LOTS:
    case types.GET_LOTS_FAIL:
    case types.UPDATE_ALLOWED_ESTABLISHMENT:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_LOTS_SUCCESS:
      state = { ...state, loading: false, lotsList: actionPayload };
      break;
    case types.GET_CITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        cities: actionPayload.response,
        localities: actionPayload.cleanData ? [] : state.localities,
      };
      break;
    case types.GET_ADMIN_DATA:
      state = { ...state, loading: true };
      break;

    case types.GET_ADMIN_DATA_SUCCESS:
      const countrysidesLocalList: any = [];
      const companiesLocalList: any = [];
      let devicesLocalList: any = [];
      let users: any = [];

      if (actionPayload && actionPayload.length > 0) {
        actionPayload.forEach((value: any) => {
          users = value.users;
          devicesLocalList = value.devices;
          value.companies.forEach((val: any) => {
            const { countrysides, address, ...rest } = val;
            const addressValues = {
              locality_id: address.locality_id,
              municipality_id: address.municipality_id,
              country_l1_name: address.country_l1_name,
              street_name: address.street_name,
              street_number: address.street_number,
              floor: address.floor,
              apartment: address.apartment,
              zip_code: address.zip_code,
            };
            companiesLocalList.push({ ...rest, ...addressValues });
            countrysides.forEach((value: any) => {
              countrysidesLocalList.push(value);
            });
          });
        });
      }
      state = {
        ...state,
        loading: false,
        usersList: users,
        deviceList: devicesLocalList,
        countrysidesList: countrysidesLocalList,
        companiesList: companiesLocalList,
      };
      break;
    case types.GET_PROVINCE_SUCCESS:
      state = {
        ...state,
        loading: false,
        provinces: actionPayload.response,
        localities: actionPayload.cleanData ? [] : state.localities,
      };
      break;

    case types.GET_LOCALITY_SUCCESS:
      state = { ...state, loading: false, localities: actionPayload };
      break;
    case types.GET_ROLES_LIST:
      state = { ...state, loading: true };
      break;
    case types.GET_ROLES_LIST_SUCCESS:
      state = { ...state, loading: false, rolesList: actionPayload };
      break;
    case types.GET_ESTABLISHMENTS:
      state = { ...state, loading: true };
      break;

    case types.GET_ESTABLISHMENTS_SUCCESS:
      state = { ...state, loading: false, countrysidesList: actionPayload };
      break;

    case types.GET_ALLOWED_ESTABLISHMENT_SUCCESS:
      const localEstablishment: any = [];
      actionPayload &&
        actionPayload.length > 0 &&
        actionPayload.forEach((userOrg: any) => {
          userOrg.organizacione.companias &&
            userOrg.organizacione.companias.length > 0 &&
            userOrg.organizacione.companias.forEach((val: any) => {
              val.establecimientos.forEach((value: any) => {
                value["companyName"] = val.nombre;
                localEstablishment.push(value);
              });
            });
        });
      state = {
        ...state,
        loading: false,
        allowedEstablishments: localEstablishment,
      };
      break;

    case types.UPDATE_ALLOWED_ESTABLISHMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case types.GET_ADMIN_DATA_FAIL:
    case types.GET_ALLOWED_ESTABLISHMENT_FAIL:
    case types.GET_CITY_FAIL:
    case types.GET_ESTABLISHMENTS_FAIL:
    case types.GET_LOCALITY_FAIL:
    case types.GET_PROVINCE_FAIL:
    case types.GET_ROLES_LIST_FAIL:
    case types.UPDATE_ALLOWED_ESTABLISHMENT_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
  }

  return state;
};

export default AdministratorReducer;
