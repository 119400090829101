import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../config/paths";
import { AuthenticatedRouteProps } from "../../shared/types";
import { isLoggedIn } from "../auth/AuthService";
import LoginLayaout from "../layaout/LoginLayaout";
import MainLayaout from "../layaout/MainLayaout";
import { Spinner } from "react-bootstrap";

const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const { children, loading, path } = props;

  const [localLoading, setLocalLoading] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !isLoggedIn() &&
      path !== Paths.LOGIN &&
      path !== Paths.RESET &&
      path !== Paths.FORGOTPASS
    ) {
      return navigate(Paths.LOGIN);
    }
    setLocalLoading(false);
  }, [path]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const getChildrenOrSpinner = () => {
    return (
      <>
        {!localLoading && !loading ? (
          <>{children}</>
        ) : (
          <>
            <Spinner
              className="centered-spinner"
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          height: "100%",
          width: "100%",
        }}
      >
        {path === Paths.LOGIN || path === Paths.FORGOTPASS ? (
          <LoginLayaout {...props} width={width}>
            {getChildrenOrSpinner()}
          </LoginLayaout>
        ) : (
          <MainLayaout {...props} width={width}>
            {getChildrenOrSpinner()}
          </MainLayaout>
        )}
      </div>
    </>
  );
};

export default AuthenticatedRoute;
