import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Col, Image } from "react-bootstrap";
import ChevronDown from "../../../assets/icons/svg/DashboardPage/chevron-down.svg";

interface MainCardElementProps {
  count: number;
  icon: any;
  title: string;
  style?: CSSProperties;
  detail?: boolean;
  mobile: boolean;
  onClick?: () => void | undefined;
}

const MainCardElement = (props: MainCardElementProps) => {
  const { count, mobile, icon, title, style, detail = false, onClick } = props;

  return (
    <div
      onClick={onClick}
      style={style}
      className="main-card-item d-flex flex-column flex-md-row justify-content-center align-items-center mt-4 mb-4"
    >
      <div>
        <Image className="cardItemIcon" src={icon} />
      </div>
      <div className="ms-0 ms-md-2 cardItemCount">{count}</div>
      <div className="ms-0 ms-md-2 cartItemTitle">{title}</div>
      {detail && (
        <div className="ms-0 ms-md-2">
          <Image src={ChevronDown} />
        </div>
      )}
    </div>
  );
};

export default MainCardElement;
