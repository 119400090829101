export const NewSiloBagFormValues = {
  id: null,
  organization_id: null,
  establecimientoId: null,
  loteId: null,
  nombre: "",
  tipoProductoId: null,
  tipoEspecieId: null,
  cosechaId: null,
  tamanio: "",
  humedadInicial: "",
  pesoIngresado: "",
  latitudGeneral: "",
  longitudGeneral: "",
  latitudInicio: "",
  latitudFin: "",
  longitudInicio: "",
  longitudFin: "",
  observaciones: "",
  cosechado: 0,
  dispositivos: [],
  fechaArmado: "",
  tipoEstadoDatosId: 1,
};
