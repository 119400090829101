import React, { useEffect, useState } from "react";
import { CustomTable } from "../../administrator/CustomTable";
import { CardComponent } from "../../card";
import { deviceViewColumns } from "../constants/Constants";

const SiloBagDeviceComponent = (props: any) => {
  const { devices } = props;

  const handleActionClick = () => {};

  return (
    <CardComponent
      title={"Dispositivos"}
      dashboard
      minHeight={devices && devices.length > 16 ? 574 : "auto"}
      marginTop={""}
      style={{ width: "100%" }}
    >
      <CustomTable
        columns={deviceViewColumns}
        values={devices}
        onActionClick={handleActionClick}
      />
    </CardComponent>
  );
};

export default SiloBagDeviceComponent;
