import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";
import {
  cleanStateAction,
  loginUserAction,
  loginUserProfileAction,
} from "../../store/users/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReduxType, UserLoginRequest } from "../../shared/types";
import { isLoggedIn } from "../../core/auth/AuthService";
import { Image, InputGroup, Nav } from "react-bootstrap";

import UserIcon from "../../assets/icons/LoginPage/userIcon.png";
import PassIcon from "../../assets/icons/LoginPage/passIcon.png";
import EyeIcon from "../../assets/icons/LoginPage/eyeIcon.png";

const LoginPage = (props: any) => {
  const [isTriggerProfile, setIsTriggerProfile] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [validated, setValidated] = useState(false);

  const [userFormLogin, setUserFormLogin] = useState<UserLoginRequest>({
    username: undefined,
    password: undefined,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (isLoggedIn()) {
      getProfileAndRedirect();
    }
  }, [userData.user]);

  useEffect(() => {
    if (userData.userProfile) {
      navigate(Paths.ROOT);
    }
  }, [userData.userProfile]);

  const getProfileAndRedirect = async () => {
    if (!isTriggerProfile) {
      setIsTriggerProfile(true);
      await dispatch(loginUserProfileAction(userFormLogin.username));
    }
  };

  const handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      doLoginRequest();
    }
    setValidated(true);
  };

  const doLoginRequest = async () => {
    await dispatch(loginUserAction(userFormLogin));
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const cleanAndNavigate = async () => {
    await dispatch(cleanStateAction());
    navigate(Paths.FORGOTPASS);
  };

  return (
    <AuthenticatedRoute withHeader path={Paths.LOGIN}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Col} md="7" className="mt-4" controlId="username">
          <InputGroup>
            <span className={"login-form-title"}>Iniciar sesión</span>
          </InputGroup>
        </Form.Group>

        <Form.Group
          as={Col}
          md="7"
          className="mt-md-5 mt-3"
          controlId="username"
        >
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">
              <Image style={{ height: "12.28", width: 13 }} src={UserIcon} />
            </InputGroup.Text>
            <Form.Control
              type="email"
              placeholder={"Correo electrónico"}
              className={"form-control-withoutBorders"}
              required
              onChange={(e) =>
                setUserFormLogin({
                  ...userFormLogin,
                  username: e.target.value,
                })
              }
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un correo electrónico.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md="7"
          className="mt-md-4 mt-3"
          controlId="password"
        >
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">
              <Image style={{ height: "12.28", width: 13 }} src={PassIcon} />
            </InputGroup.Text>
            <Form.Control
              type={passwordShown ? "text" : "password"}
              placeholder="Contraseña"
              className={"form-control-withoutBorders"}
              required
              onChange={(e) =>
                setUserFormLogin({
                  ...userFormLogin,
                  password: e.target.value,
                })
              }
            />
            <InputGroup.Text>
              <Image
                onClick={togglePassword}
                style={{ height: "12.28", width: 13 }}
                src={EyeIcon}
              />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese una contraseña.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="7" className="mt-4">
          <InputGroup className="justify-content-between">
            <Nav.Link
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                color: "#333333",
                marginLeft: -15,
              }}
              onClick={() => cleanAndNavigate()}
            >
              ¿Olvidaste la contraseña?
            </Nav.Link>
            <Button className={"loginButton"} type="submit">
              Ingresar
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="7" className="mt-4"></Form.Group>
      </Form>
    </AuthenticatedRoute>
  );
};

export default LoginPage;
