import React, { CSSProperties } from "react";

export interface BaseApiParams {
  url: string;
  unauthorizedCallback?: any;
  showSuccesMessage?: boolean;
}

export interface GetParams extends BaseApiParams {}

export interface DeleteParams extends BaseApiParams {
  body?: any;
}

export interface PostParams extends BaseApiParams {
  body: any;
}

export interface PutParams extends BaseApiParams {
  body: any;
}

export interface PostDownloadParams extends PostParams {
  fileName: string;
  extension: string;
}

export enum roles {
  OWNER = "owner",
  ADMIN = "admin",
  EDITOR = "editor",
  READ_ONLY = "readonly",
  ORGANIZATION_ADMIN = "organization.admin",
  ORGANIZATION_COMMERCIAL = "organization.comercial",
  ORGANIZATION_TECHNICAL = "organization.technical",
  ORGANIZATION_ZONE = "organization.zone",
  ORGANIZATION_SUBZONE = "organization.subzone",
  ORGANIZATION_COUNTRYSIDE = "organization.countryside",
  ORGANIZATION_GENERAL = "organization.general",
  REPORT_RECEIBABLES = "report.receibables",
}

export enum ConfirmDialogTypeEnum {
  Info = "Info",
  Exito = "Exito",
  Advertencia = "Advertencia",
  Eliminar = "Eliminar",
  Error = "Error",
}

export type AuthenticatedRouteProps = {
  path: string;
  children: any;
  login?: boolean;
  withHeader?: boolean;
  dashboard?: boolean;
  withLogo?: boolean;
  loading?: boolean;
  withDashboardLogo?: boolean;
  headerNavigationName?: string;
  goBack?: () => void;
  withPadding?: boolean;
  showToast?: boolean;
  errorType?: boolean;
  errorMessage?: string | undefined;
  setShowToast?: any;
  setErrorType?: any;
  setErrorMessage?: any;
};

export interface ReduxBase {
  loading: boolean;
  error: {
    message: string;
  };
}

export interface ReduxUserData extends ReduxBase {
  user: any;
  userProfile: any;
  userReset: any;
}

export interface ReduxOrgData extends ReduxBase {
  currentOrganization: OrganizationProps;
}

export interface ReduxDashboardData extends ReduxBase {
  currentDashboardOrg: DashboardStatsProps;
}

export interface ReduxEstablishmentsMapData extends ReduxBase {
  establishments: Establishment[];
  establishment: CurrentEstablishment[];
  lanzas: any[];
  infoWindow: InfoWindow[];
}

export interface ReduxAlertsMapData extends ReduxBase {
  alerts: Alert[];
  display: boolean;
}

export interface ReduxSiloBagData extends ReduxBase {
  availableDevices: any;
  establishments: any;
  harvests: any;
  lots: any;
  products: any;
  species: any;
  silobagList: any;
}

export interface ReduxAdministratorData extends ReduxBase {
  provinces: any[];
  localities: any[];
  cities: any[];
  rolesList: any[];
  countrysidesList: any[];
  usersList: any[];
  companiesList: any[];
  fullCompaniesList: any[];
  lotsList: any[];
  deviceList: any[];
  allowedEstablishments: any[];
}

export type ReduxType = {
  dashboardData: ReduxDashboardData;
  orgData: ReduxOrgData;
  userData: ReduxUserData;
  establishmentsMapData: ReduxEstablishmentsMapData;
  alertsData: ReduxAlertsMapData;
  siloBagData: ReduxSiloBagData;
  administratorData: ReduxAdministratorData;
};

export interface NavBarProps {
  path: string;
  mobile: boolean;
}

export interface HeaderProps {
  width: number;
  withLogo?: boolean;
  withDashboardLogo?: boolean;
  dashboard?: boolean;
  login?: boolean;
  collapsed: boolean;
  headerNavigationName?: string;
  toggleCollapsed: () => void;
  goBack?: () => void;
}

export interface UserLoginRequest {
  username: string | undefined;
  password: string | undefined;
}

export interface ToastProps {
  bodyContent: string | undefined;
  showToast: boolean;
  title: string;
  errorType: boolean;
  toggleShow: () => void;
}

export interface ApiRequest {
  mod: string;
  fun: string;
  args: any;
  config: any;
  sequence: number;
  token?: string;
}

export interface ApiResponse {
  error: boolean;
  code: number;
  message: string;
  data: any;
  info: {
    time: number;
    sequence: number;
  };
}

export interface OrganizationProps {
  id: number;
  user_id: number;
  name: string;
  legal_name: string;
  tax_id: string;
  address: any;
  phone: number;
  email: string;
  blockchain_enabled: boolean;
  prosegur_monitoring: boolean;
  active: boolean;
}

export interface DashboardStatsProps {
  establishment_data: DashboardEstablishmentStatsProps[];
  device_data: DashboardDeviceStatsProps[];
  silo_data: DashboardSiloStatsProps[];
  siloCount: number;
  tonsCount: number;
  alertsCount: number;
  monitored_tons_crop_data: MonitoredTonsCrop[];
  monitored_tons_establishment_data: MonitoredTonsEstablishment[];
}

export interface DashboardEstablishmentStatsProps {
  LOTE_NOMBRE: string;
  CANTIDAD: number;
  PRIMER_SILO_LATITUD: string;
  PRIMER_SILO_LONGITUD: string;
}

export interface DashboardDeviceStatsProps {
  dispositivo_estado: { id: number; nombre: string };
  dispositivo_tipo: { id: number; nombre: string };
  id: number;
  identificador: string;
  nombre: string;
  numero: string;
  orden: number;
  version: string;
}

export interface DashboardSiloStatsProps {
  ELEMENTO_CANTIDAD_DISPOSITIVO: number;
  ELEMENTO_NOMBRE: string;
  ELEMENTO_PRODUCTO: string;
  CAMPO_NOMBRE: string;
  LOTE_NOMBRE: string;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
  Indicador: string;
  Valor_Alerta: number;
  PESO: number;
}
export interface Establishment {
  CAMPO_ID: number;
  CAMPO_NOMBRE: string;
  LOTE_ID: number;
  LOTE_NOMBRE: string;
  CANTIDAD: number;
  PRIMER_SILO_LATITUD: string;
  PRIMER_SILO_LONGITUD: string;
}

export interface EstablishmentPerimeter {
  ELEMENTO_ID: number;
  ELEMENTO_NOMBRE: string;
  ELEMENTO_PRODUCTO: string;
  ELEMENTO_TIPO: string;
  ELEMENTO_COSECHA: string;
  ELEMENTO_CANTIDAD_DISPOSITIVO: number;
  LOTE_ID: number;
  LOTE_NOMBRE: string;
  LOTE_PERIMETRO: string;
  CAMPO_NOMBRE: string;
  ALERTA_NUMERO: number;
  ALERTA_COLOR: string;
  ALERTAS_CANTIDAD: number;
}

export interface MapDashboardProps {
  marker: CurrentEstablishment[];
  markersLength: number;
  selectedEstablishment: number;
  setSelectedEstablishment: (value: number) => void;
  setIsAlertsOpen: (value: boolean) => void;
  alertsCounter: number;
  isAlertsOpen: boolean;
  lanzas: any[];
  map?: google.maps.Map;
  isDashboardOpen?: boolean;
}

export interface CurrentEstablishment {
  ALERTA_COLOR: string;
  ORGANIZACION_ID: number;
  ID_SILO: number;
  LOTE_ID: number;
  CAMPO_NOMBRE: string;
  LOTE_NOMBRE: string;
  LOTE_PERIMETRO: any;
  ELEMENTO_ID: number;
  ELEMENTO_NOMBRE: string;
  ELEMENTO_PRODUCTO: string;
  ELEMENTO_COSECHA: string;
  ELEMENTO_TIPO: string;
  ELEMENTO_CANTIDAD_DISPOSITIVO: number;
  ELEMENTO_LATITUD: string;
  ELEMENTO_LONGITUD: string;
  PESO: any;
}

export interface InfoWindow {
  SILO_ID: number;
  DISPOSITIVO_ID: number;
  DISPOSITIVO_NOMBRE: string;
  DISPOSITIVO_TIPO: string;
  DISPOSITIVO_ESTADO: string;
  "MAX(FECHA)": Date;
  ID_DISPOSITIVO: number;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
  ID_ALERTA: number;
}

export interface InfoWindowTooltipProps {
  marker: CurrentEstablishment;
  alertsCounter: number;
  lanzas: any[];
  mapInfo?: InfoWindow;
}

export interface MonitoredTonsCrop {
  ELEMENTO_PRODUCTO: string;
  PESO: number;
}

export interface MonitoredTonsEstablishment {
  CAMPO_NOMBRE: string;
  PESO: number;
}

export interface ModalFormProps {
  editionMode: boolean;
  createMode: boolean;
  onValueChange: (e: any, replaceWholeValue?: boolean) => void;
  onSubmit: any;
  formValues: any;
  valuesToEditOrView?: any;
}

export interface Alert {
  ORGANIZACION: number;
  ID_ALERTA: number;
  ALERTA_COLOR: string;
  ALERTA_NUMERO: number;
  FECHA: Date;
  TipoAlerta: string;
  Establecimiento: string;
  Lote: string;
  ID_SILO: number;
  Silobolsa: string;
  Valor_Alerta: number;
  DISPOSITIVO: string;
  Indicador: string;
}

export interface ArrowButtonProps {
  onClick: () => void;
  disabled: boolean;
  className?: string;
}

interface InputProps {
  className?: string;
  disabled?: boolean;
  required?: boolean;
  errorMessage: string;
  formName: string;
  placeholder: string;
  value?: string;
  onChange: (name: string, value: any) => void;
}

export interface InputTextAreaProps extends InputProps {
  rows: number;
}

export interface InputNumberProps extends InputProps {
  step: string;
}

export interface InputSelectProps extends InputProps {
  options: any[];
}

export interface InputTextProps extends InputProps {}

export interface SiloBagFormValues {
  ID: string;
}

export interface SiloBagViewConstBaseProps {
  colSize?: number;
  title?: any;
  value?: any;
}

export interface SiloBagViewConstProps extends SiloBagViewConstBaseProps {
  withCrop?: boolean;
  subData?: SiloBagViewConstBaseProps[];
}
