import React from "react";
import EditIcon from "../../assets/icons/GridActions/edit.svg";
import MapOn from "../../assets/icons/GridActions/mapOn.svg";
import MapOff from "../../assets/icons/GridActions/mapOff.svg";
import ViewIcon from "../../assets/icons/GridActions/view.svg";
import DeleteIcon from "../../assets/icons/GridActions/delete.svg";
import { InputSwitchComponent } from "../inputs/inputSwitch";
import { isArray } from "lodash";

export const ActionButtons = (props: any) => {
  const { actions, onClick, values } = props;

  let val;
  if (actions && actions.toggle && actions.toggle.name) {
    val =
      values &&
      values[actions.toggle.name] &&
      isArray(values[actions.toggle.name])
        ? values[actions.toggle.name][0]
        : values[actions.toggle.name];
  }

  return (
    <div>
      {actions.view && (
        <button
          className="clean-button"
          type="button"
          onClick={() => onClick("VIEW")}
        >
          <img src={ViewIcon} />
        </button>
      )}
      {actions.map && (
        <button
          className="clean-button"
          type="button"
          onClick={() => onClick("MAP")}
        >
          <img src={MapOn} />
        </button>
      )}
      {actions.edit && (
        <button
          className="clean-button"
          type="button"
          onClick={() => onClick("EDIT")}
        >
          <img src={EditIcon} />
        </button>
      )}
      {!!actions.toggle && (
        <InputSwitchComponent
          onChange={(e: any) => {
            onClick("TOGGLE", e);
          }}
          initial={!val}
        />
      )}
      {actions.delete && (
        <button
          className="clean-button"
          type="button"
          onClick={() => onClick("DELETE")}
        >
          <img src={DeleteIcon} />
        </button>
      )}
    </div>
  );
};
