const viewColumnsForUserPermissions = [
  {
    id: "name",
    label: "Permisos",
    render: (value: any, record: any) => {
      return <>{(record.permiso && record.permiso.nombre) || "-"}</>;
    },
  },
];

const defaultColumnsForUserPermissions = [
  ...viewColumnsForUserPermissions,
  { id: "acciones", label: "Habilitado" },
];

const viewColumnsForUserEstablishment = [
  {
    id: "nombre",
    label: "Establecimientos",
    render: (value: any, record: any) => {
      return <>{value || "-"}</>;
    },
  },
  {
    id: "companiaId",
    label: "Compañia",
    render: (value: any, record: any) => {
      return <>{record.companyName || "-"}</>;
    },
  },
  {
    id: "provincia",
    label: "Provincia",
    render: (value: any, record: any) => {
      return <>{(record && record.provincium.nombre) || "-"}</>;
    },
  },
];

export {
  defaultColumnsForUserPermissions,
  viewColumnsForUserEstablishment,
  viewColumnsForUserPermissions,
};
