import React, { useState } from "react";
import { Button, Col, Form, Image, InputGroup, Nav } from "react-bootstrap";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserLoginRequest } from "../../shared/types";
import { cleanStateAction, resetUserAction } from "../../store/users/actions";

import UserIcon from "../../assets/icons/LoginPage/userIcon.png";

const ForgotPasswordPage = () => {
  const [validated, setValidated] = useState(false);

  const [userFormLogin, setUserFormLogin] = useState<UserLoginRequest>({
    username: undefined,
    password: undefined,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      doResetRequest();
    }
    setValidated(true);
  };

  const doResetRequest = async () => {
    await dispatch(resetUserAction(userFormLogin));
  };

  const cleanAndNavigate = async () => {
    await dispatch(cleanStateAction());
    navigate(Paths.FORGOTPASS);
  };

  return (
    <AuthenticatedRoute withHeader path={Paths.FORGOTPASS}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Col} md="6" className="mt-4" controlId="username">
          <InputGroup>
            <span
              style={{
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 36,
                lineHeight: "79.5%",
              }}
            >
              Recuperar contraseña
            </span>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="6" className="mt-5" controlId="username">
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">
              <Image style={{ height: "12.28", width: 13 }} src={UserIcon} />
            </InputGroup.Text>
            <Form.Control
              type="email"
              placeholder={"Correo electrónico"}
              required
              onChange={(e) =>
                setUserFormLogin({
                  ...userFormLogin,
                  username: e.target.value,
                })
              }
            />
            <Form.Control.Feedback type="invalid">
              Por favor, ingrese un correo electrónico.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="6" className="mt-4">
          <InputGroup className="justify-content-end">
            <Button
              style={{
                width: 155,
                height: 41,
                background: "#36620D",
                borderColor: "#36620D",
                borderRadius: 5,
              }}
              type="submit"
            >
              Enviar
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="6" className="mt-4"></Form.Group>
      </Form>
    </AuthenticatedRoute>
  );
};

export default ForgotPasswordPage;
