import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { CardComponent } from "../../components/card";
import { SiloBagFormComponent, StageComponent } from "../../components/silobag";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import PlusIcon from "../../assets/icons/svg/SiloBagPage/plus.svg";
import BackArrowIcon from "../../assets/icons/svg/SiloBagPage/backArrow.svg";
import { Link, useLocation } from "react-router-dom";
import { SiloBagDeviceFormComponent } from "../../components/silobag/siloBagForm/deviceForm";
import {
  cloneWithoutReference,
  stateSiloBagColorBackground,
  stateSiloBagColorText,
  stateSiloBagName,
} from "../../shared/helper";
import MainFooterComponent from "../../components/base/footer/MainFooterComponent";
import { CarouselComponent } from "../../components/carousel";
import { CustomButton } from "../../components/customButton/CustomButton";

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Agregar dispositivo
  </Tooltip>
);

const NewSilobagPage = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [showToast, setShowToast] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [deviceForms, setDeviceForms] = useState<any>([]);
  const [formDeviceValues, setFormDeviceValues] = useState<any>([]);

  const { state }: any = useLocation();
  const { createMode, editionMode, from, record, viewMode } = state;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleSubmit = () => {
    setSubmitTrigger(true);
  };

  const removeDeviceElement = (index: number) => {
    let d = cloneWithoutReference(deviceForms);
    let df = cloneWithoutReference(formDeviceValues);
    if (d && d[index]) {
      d[index] = { showDevice: false };
    }
    if (df && df[index]) {
      df[index].delete = true;
    }
    setFormDeviceValues(df);
    setDeviceForms(d);
  };

  const initialUpdateDevices = (devices: any[]) => {
    setDeviceForms([
      ...devices.map((e) => ({
        showDevice: true,
        isEditMode: false,
      })),
    ]);
    setFormDeviceValues([
      ...devices.map(
        ({ dispositivo, silos_dispositivos_acciones, latitud, longitud, id }) =>
          dispositivo !== null
            ? {
                id,
                dispositivoName: dispositivo.nombre,
                dispositivoId: dispositivo.id,
                imagenes: silos_dispositivos_acciones.map((sda: any) => {
                  return {
                    imagen: sda.imagen,
                    latitud: sda.latitud,
                    longitud: sda.longitud,
                    observacion: sda.observacion,
                  };
                }),
                latitud,
                longitud,
              }
            : { latitud, longitud }
      ),
    ]);
  };

  useEffect(() => {
    if (
      record &&
      record.silos_dispositivos &&
      record.silos_dispositivos.length > 0
    ) {
      initialUpdateDevices(record.silos_dispositivos);
    }
  }, []);

  return (
    <AuthenticatedRoute
      withHeader
      withPadding={false}
      showToast={showToast}
      setShowToast={setShowToast}
      errorType={errorType}
      setErrorType={setErrorType}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      path={Paths.NEW_SILO}
    >
      <section className="screen-container">
        <div className="screen-container__layout">
          <div className="screen-container__layout-header">
            <div className="mb-4 mb-xl-0">
              <Row className="justify-content-between d-flex">
                <Col className="d-flex">
                  <Link
                    to={from ? from : Paths.ROOT}
                    className={"link-decorator-none"}
                  >
                    <Image className={"align-middle"} src={BackArrowIcon} />
                    <span
                      className={"silo-bag-back fs-10 light-green fw-bold-500"}
                    >
                      Volver
                    </span>
                  </Link>
                </Col>
              </Row>

              <span className={"silo-bag-title fw-bold-500"}>
                {record ? `Editar Silobolsa ` : `Nuevo silobolsa `}
                {record && (
                  <span className="silo-bag-state-subtitle-form light-gray fw-bold-400">
                    <span>
                      <div
                        className={`h-100 badge silobag-badge rounded-pill .w-500 ${
                          stateSiloBagColorText &&
                          stateSiloBagColorText[record.tipoEstadoDatosId]
                        } ${
                          stateSiloBagColorBackground &&
                          stateSiloBagColorBackground[record.tipoEstadoDatosId]
                        }`}
                      >
                        {stateSiloBagName &&
                          stateSiloBagName[record.tipoEstadoDatosId]}
                      </div>
                    </span>
                  </span>
                )}
              </span>
            </div>
            {width >= 768 && (
              <div>
                <StageComponent estadoId={record && record.tipoEstadoDatosId} />
              </div>
            )}
            <CustomButton
              className={"georeferenciarButton"}
              style={{ width: "auto" }}
              form="silobag-main-form"
              type="submit"
            >
              <span className={"fw-bold-500"}>Guardar</span>
            </CustomButton>
          </div>
          <div
            className="screen-container"
            style={{ flexDirection: "row", flexWrap: "wrap", rowGap: 13 }}
          >
            <SiloBagFormComponent
              className="new-silo-bag-form-column"
              createMode={createMode}
              editionMode={editionMode}
              viewMode={viewMode}
              record={record}
              formDeviceValues={formDeviceValues}
              submitTrigger={submitTrigger}
              setSubmitTrigger={setSubmitTrigger}
              setErrorType={setErrorType}
              setErrorMessage={setErrorMessage}
              setShowToast={setShowToast}
            />
            <CardComponent
              dashboard
              hideOverflow
              className="new-silo-bag-img-column"
              bodyClassName="silo-bag-h-100"
              minHeight={560}
              marginTop={"0"}
              title={`Imágenes`}
            >
              {record && record.imagen_inicio ? (
                <CarouselComponent record={record} />
              ) : (
                "Sin imágenes cargadas"
              )}
            </CardComponent>
            <Row className="align-center" style={{ rowGap: 13 }}>
              {deviceForms &&
                deviceForms.map((df: any, index: number) => {
                  if (df.showDevice) {
                    return (
                      <Col md="3">
                        <SiloBagDeviceFormComponent
                          key={`form-device-index-${index}`}
                          index={index}
                          isEditMode={df.isEditMode ? true : false}
                          width={width}
                          formDeviceValues={formDeviceValues}
                          removeDeviceElement={removeDeviceElement}
                          setFormDeviceValues={setFormDeviceValues}
                          setErrorType={setErrorType}
                          setErrorMessage={setErrorMessage}
                          setShowToast={setShowToast}
                        />
                      </Col>
                    );
                  }
                })}

              <Col md="1">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 0 }}
                  overlay={renderTooltip}
                >
                  <button
                    className="clean-button"
                    onClick={() => {
                      let d = cloneWithoutReference(deviceForms);
                      d.push({ showDevice: true, isEditMode: true });
                      setDeviceForms(d);
                    }}
                  >
                    <Image
                      style={{
                        width: 32,
                        height: 32,
                      }}
                      className={"align-middle"}
                      src={PlusIcon}
                    />
                  </button>
                </OverlayTrigger>
              </Col>
            </Row>
          </div>
        </div>
        <MainFooterComponent />
      </section>
    </AuthenticatedRoute>
  );
};

export default NewSilobagPage;
