import React, { useEffect } from "react";
import ZoomIn from "../../../../assets/icons/svg/DashboardPage/add-icon.svg";
import ZoomOut from "../../../../assets/icons/svg/DashboardPage/substract-icon.svg";

const ZoomControls = (props: any) => {
  const { isDashboardOpen, map } = props;

  useEffect(() => {
    if (!isDashboardOpen && map) {
      map.setZoom(6);
    }
  }, [isDashboardOpen]);

  if (!props) return <></>;

  const ZoomInButton = () => (
    <button
      className="zoom-button"
      onClick={() => props.map.setZoom(props.map.getZoom()! + 1)}
    >
      <img src={ZoomIn} alt="zoom-in-icon" />
    </button>
  );
  const ZoomOutButton = () => (
    <button
      className="zoom-button"
      onClick={() => props.map.setZoom(props.map.getZoom()! - 1)}
    >
      <img src={ZoomOut} alt="zoom-out-icon" />
    </button>
  );

  return (
    <div
      className="zoom-controls-container d-flex justify-between"
      style={props.style}
    >
      <ZoomInButton />
      <ZoomOutButton />
    </div>
  );
};

export default ZoomControls;
