import { Col, Row } from "react-bootstrap";
import {
  diffDates,
  formatDate,
  formatDatetime,
  stateSiloBagColorBackground,
  stateSiloBagColorText,
  stateSiloBagName,
} from "../../../shared/helper";
import {
  alphanumericRegex,
  alphanumericWithSpecialCharsRegex,
  floatPositiveAndNegativeRegex,
  floatPositiveRegex,
} from "../../../shared/regexPatterns";
import { CropIconComponent } from "../../dashboard/cropIcon";
import ChevronRight from "../../../assets/icons/svg/DashboardPage/chevron-right.svg";
import { CustomButton } from "../../customButton/CustomButton";

const validatorValues = [
  {
    name: "establecimientoId",
    nameToShow: "establecimiento",
    required: true,
  },
  {
    name: "loteId",
    nameToShow: "lote",
    required: true,
  },
  {
    name: "nombre",
    pattern: alphanumericWithSpecialCharsRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "productoId",
    nameToShow: "producto",
    required: false,
  },
  {
    name: "tipoEspecieId",
    nameToShow: "tipo de especie",
    required: true,
  },
  {
    name: "cosechaId",
    nameToShow: "cosecha",
    required: false,
  },
  {
    name: "tamanio",
    nameToShow: "largo de silobolsa",
    required: false,
    pattern: floatPositiveRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "humedadInicial",
    nameToShow: "humedad de embolsado",
    required: false,
    pattern: floatPositiveRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "pesoIngresado",
    nameToShow: "peso de embolsado",
    required: false,
    pattern: floatPositiveRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "latitud",
    required: false,
    pattern: floatPositiveAndNegativeRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "longitud",
    required: false,
    pattern: floatPositiveAndNegativeRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "latitudInicio",
    nameToShow: "latitud de inicio",
    required: false,
    pattern: floatPositiveAndNegativeRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "longitudInicio",
    nameToShow: "longitud de inicio",
    required: false,
    pattern: floatPositiveAndNegativeRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "latitudFin",
    nameToShow: "latitud de fin",
    required: false,
    pattern: floatPositiveAndNegativeRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "longitudFin",
    nameToShow: "longitud de fin",
    required: false,
    pattern: floatPositiveAndNegativeRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "longitudFin",
    nameToShow: "longitud de fin",
    required: false,
    pattern: floatPositiveAndNegativeRegex,
    regexMessage: "solo acepta formato numerico",
  },
  {
    name: "observaciones",
    required: false,
    pattern: alphanumericWithSpecialCharsRegex,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
];

const columns = [
  {
    id: "",
    label: "",
    render: (value: any, record: any) => {
      return <>{(record.permiso && record.permiso.nombre) || "-"}</>;
    },
  },
  {
    id: "nombre",
    label: "Silo",
    render: (value: any, record: any) => {
      return (
        <button
          className="silo-bag-link"
          style={{ display: "inline-flex", alignItems: "center", gap: 5 }}
        >
          {value}
          <img
            src={ChevronRight}
            alt="chrevronright"
            style={{ width: 10, height: 10 }}
          />
        </button>
      );
    },
  },
  {
    id: "createdAt",
    label: "Alta",
    render: (value: any, record: any) => {
      return <>{formatDate(value) || "-"}</>;
    },
  },
  {
    id: "confeccion",
    label: "Confección",
    render: (value: any, record: any) => {
      return <>{diffDates(record.fechaArmado) || "-"}</>;
    },
  },
  {
    id: "silo_tipo_especy.nombre",
    label: "Especie",
    render: (value: any, record: any) => {
      let specie =
        record && record.silo_tipo_especy
          ? record.silo_tipo_especy.nombre
          : "-";
      specie = specie
        .toLowerCase()
        .split(" ")
        .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      return (
        <>
          <Row className="align-center">
            <Col xs="2" className="mb-4 mb-xl-0">
              {specie !== "-" && (
                <CropIconComponent
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  crop={specie}
                />
              )}{" "}
            </Col>
            <Col xs="auto" className="mb-4 mb-xl-0">
              {specie}
            </Col>
          </Row>
        </>
      );
    },
  },
  { id: "pesoIngresado", label: "Peso" },
  {
    id: "cosecha",
    label: "Cosecha",
    render: (value: any, record: any) => {
      return <>{(record && record.cosecha && record.cosecha.nombre) || "-"}</>;
    },
  },
  {
    id: "establecimiento",
    label: "Establecimiento",
    render: (value: any, record: any) => {
      return (
        <>
          {(record &&
            record.lote &&
            record.lote.establecimiento &&
            record.lote.establecimiento.nombre) ||
            "-"}
        </>
      );
    },
  },
  {
    id: "lote",
    label: "Lote",
    render: (value: any, record: any) => {
      return <>{(record && record.lote && record.lote.nombre) || "-"}</>;
    },
  },
  {
    id: "tipoEstadoDatosId",
    label: "Estado carga",
    render: (value: any, record: any) => {
      return (
        <>
          <div className="h-100 align-center">
            <div
              className={`h-100 badge silobag-badge rounded-pill w-500 ${
                stateSiloBagColorText && stateSiloBagColorText[value]
              } ${
                stateSiloBagColorBackground &&
                stateSiloBagColorBackground[value]
              }`}
            >
              {stateSiloBagName && stateSiloBagName[value]}
            </div>
          </div>
        </>
      );
    },
  },
  { id: "acciones", label: "Acciones" },
];

const deviceViewColumns = [
  {
    id: "orden",
    label: "Posición",
  },
  {
    id: "updatedAt",
    label: "Última actualización",
    render: (value: any, record: any) => {
      return (
        <>
          {formatDatetime(
            record && record.dispositivo && record.dispositivo.updatedAt
          ) || "-"}
        </>
      );
    },
  },
  {
    id: "nombre",
    label: "Dispositivo",
    render: (value: any, record: any) => {
      return (
        <>
          {(record && record.dispositivo && record.dispositivo.nombre) || "-"}
        </>
      );
    },
  },
  {
    id: "co2",
    label: "CO2",
    render: (value: any, record: any) => {
      return <>{value || "-"}</>;
    },
  },
  {
    id: "hum",
    label: "HUM",
    render: (value: any, record: any) => {
      return <>{value || "-"}</>;
    },
  },
  {
    id: "bateria",
    label: "Batería",
    render: (value: any, record: any) => {
      return <>{value || "-"}</>;
    },
  },
  {
    id: "mantenimiento",
    label: "Próximo mantenimiento",
    render: (value: any, record: any) => {
      return <>{value || "-"}</>;
    },
  },
];

export { columns, deviceViewColumns, validatorValues };
