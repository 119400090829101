import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { InputTextAreaProps } from "../../../shared/types";

const InputTextAreaComponent = (props: InputTextAreaProps) => {
  const {
    errorMessage,
    formName,
    placeholder,
    required,
    rows,
    value,
    onChange,
  } = props;
  const [localValue, setLocalValue] = React.useState(value);

  return (
    <>
      <Form.Label>
        {(!!localValue || (localValue && localValue.length > 0)) && placeholder}
      </Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          required={required}
          as="textarea"
          rows={rows}
          placeholder={`${placeholder} ${required ? `*` : ``}`}
          defaultValue={value}
          onChange={(e) => {
            onChange(formName, e.target.value);
            setLocalValue(e.target.value);
          }}
          className={"silo-bag-form-input"}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </>
  );
};

export default InputTextAreaComponent;
